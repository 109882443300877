import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { NumericFormat } from "react-number-format";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Calendar } from "primereact/calendar";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import {
  locale,
  addLocale,
  updateLocaleOption,
  updateLocaleOptions,
  localeOption,
  localeOptions,
} from "primereact/api";
//import { selectedRowsCountSelector } from "@material-ui/data-grid";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../../assets/css/DialogDemo.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
  latLngBound,
} from "react-leaflet";
import "./Map.css";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";

const PREFIX = "MyCard";
const classes = {
  root: `${PREFIX}-root`,
  textField: `${PREFIX}-textField`,
  iconButton: `${PREFIX}-iconButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },
  [`&.${classes.textField}`]: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  [`&.${classes.iconButton}`]: {
    padding: 10,
  },
}));

export default function TrasladosServicios(props) {
  //const classes = useStyles();

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
  });

  locale("es");
  let minDate = new Date();

  const serviciosContext = useContext(ServiciosContext);
  const {
    afiliado,
    direcciones,
    servicioSeleccionado,
    trasladosServicios,
    trasladosServiciosPami,
    tomaAzul,
    guardarTomaAzul,
    guardarTrasladosServicios,
    obtenerDirecciones,
    obtenerTrasladosServicios,
    obtenerDestinosPami,
  } = serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, tiposMoviles, diagnosticos, obtenerTiposMoviles } =
    configuracionContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    //obtenerDiagnosticos({ todos: false });
    obtenerTiposMoviles();
  }, []);

  useEffect(() => {
    if (configuracion.pami === servicioSeleccionado.titularId) {
      obtenerDestinosPami({ idPami: servicioSeleccionado.indicativoEmisor });

      guardarTrasladosServicios({
        salida: new Date(),
        destino: "",
        descripcion: "",
        lat: "",
        lon: "",
        provincia: "Buenos Aires",
      });
    } else {
      if (
        servicioSeleccionado.traslados !== undefined &&
        servicioSeleccionado.traslados !== null
      ) {
        guardarTrasladosServicios({
          salida: new Date(),
          destino: servicioSeleccionado.traslados.destino,
          descripcion: "",
          lat: "",
          lon: "",
          provincia: "Buenos Aires",
        });
      } else {
        guardarTrasladosServicios({
          salida: new Date(),
          destino: "",
          descripcion: "",
          lat: "",
          lon: "",
          provincia: "Buenos Aires",
        });
      }
    }
    let campo = document.getElementById("provincia");
    campo.focus();
  }, []);

  //Dialog

  //const theme = useTheme();
  //const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Leer tecla y foco

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "llegada":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "provincia":
          if (configuracion.pami === servicioSeleccionado.titularId) {
            campo = document.getElementById("destinoPami");
            campo.focus();
            break;
          } else {
            campo = document.getElementById("destino");
            campo.focus();
            break;
          }
        case "destino":
          campo = document.getElementById("destinoGeo");
          campo.focus();
          break;
        case "destinoPami":
          campo = document.getElementById("destinoGeo");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "destinoGeo":
          if (configuracion.pami === servicioSeleccionado.titularId) {
            campo = document.getElementById("destinoPami");
            campo.focus();
            break;
          } else {
            campo = document.getElementById("destino");
            campo.focus();
            break;
          }
        case "destino":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "destinoPami":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "provincia":
          campo = document.getElementById("llegada");
          campo.focus();
          break;
      }
    }
  }

  const renderHeaderMapa = () => {
    return (
      <div>
        <Tag value="Ubicación en el Mapa"></Tag>
      </div>
    );
  };

  const renderFooterMapa = () => {
    return (
      <div>
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleClose()}
          className="p-button-text"
        />
      </div>
    );
  };

  function convertDate(today) {
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var hh = String(today.getHours()).padStart(2, "0");
    var mi = String(today.getMinutes()).padStart(2, "0");

    return dd + "/" + mm + "/" + yyyy + " " + hh + ":" + mi;
  }

  function PopupExample(e) {
    if (
      trasladosServicios === undefined ||
      trasladosServicios === null ||
      trasladosServicios.lat === undefined ||
      trasladosServicios.lat === null ||
      trasladosServicios.lon === undefined ||
      trasladosServicios.lon === null
    ) {
      return;
    }

    let position = [];
    position.push(trasladosServicios.lat);
    position.push(trasladosServicios.lon);

    return (
      <MapContainer
        center={position}
        zoom={15}
        style={{ height: "60vh", width: "60vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}></Marker>
      </MapContainer>
    );
  }

  function clearLocalidad() {
    guardarTrasladosServicios({
      ...trasladosServicios,
      localidad: "",
    });
  }

  const handleInputBlur = (event) => {
    clearLocalidad();
    obtenerDirecciones({
      domicilio: trasladosServicios.destino.toLowerCase(),
      provincia: trasladosServicios.provincia.toLowerCase(),
    });
  };

  //console.log(trasladoServicioPami);

  return (
    <Root className={classes.root}>
      <Fragment>
        <Grid container spacing={3} style={{ padding: "0", marginTop: "1vw" }}>
          <Grid item xs={12} md={6}>
            <TextField
              id="salida"
              label="Salida"
              noOptionsText={"No hay opciones"}
              defaultValue={convertDate(new Date())}
              className={classes.textField}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
          </Grid>

          {configuracion && configuracion.pami === servicioSeleccionado.titularId &&
          trasladosServiciosPami &&
          (servicioSeleccionado.color === "VERDE" ||
            servicioSeleccionado.color === "AMARILLO" ||
            servicioSeleccionado.color === "ROJO") ? (
            <Fragment>
              <Grid item xs={12} md={6}>
                <TextField
                  id="provincia"
                  label="Provincia"
                  value={
                    trasladosServicios ? trasladosServicios.provincia : null
                  }
                  required
                  fullWidth
                  variant="standard"
                  size="small"
                  className={classes.textField}
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarTrasladosServicios({
                      ...trasladosServicios,
                      provincia: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="destinoPami"
                  fullWidth
                  disablePortal
                  noOptionsText={"No hay opciones"}
                  options={trasladosServiciosPami}
                  getOptionLabel={(option) => option.destino}
                  openOnFocus={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Destinos PAMI"
                      variant="standard"
                    />
                  )}
                  onKeyDown={handleEnter}
                  onBlur={(e) => handleInputBlur(e)}
                  onChange={(event, value) => {
                    if (value) {
                      guardarTrasladosServicios({
                        ...trasladosServicios,
                        destino: value.destino,
                        descripcion: value.descripcion,
                        lat: null,
                        lon: null,
                      });
                    } else {
                      guardarTrasladosServicios({
                        ...trasladosServicios,
                        destino: null,
                        descripcion: "",
                        lat: null,
                        lon: null,
                      });
                    }
                  }}
                />
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid item xs={12} md={6}>
                <TextField
                  id="provincia"
                  label="Provincia"
                  value={
                    trasladosServicios ? trasladosServicios.provincia : null
                  }
                  required
                  fullWidth
                  variant="standard"
                  size="small"
                  className={classes.textField}
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarTrasladosServicios({
                      ...trasladosServicios,
                      provincia: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  id="destino"
                  label="Destino"
                  value={trasladosServicios ? trasladosServicios.destino : null}
                  fullWidth
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textField}
                  onKeyDown={handleEnter}
                  onBlur={(e) => handleInputBlur(e)}
                  onChange={(e) =>
                    guardarTrasladosServicios({
                      ...trasladosServicios,
                      destino: e.target.value,
                    })
                  }
                />
              </Grid>
            </Fragment>
          )}

          <Grid item xs={12} md={11}>
            <Autocomplete
              id="destinoGeo"
              fullWidth
              disablePortal
              noOptionsText={"No hay opciones"}
              options={direcciones}
              getOptionLabel={(option) => option.label}
              openOnFocus={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Destino Geolocalizado"
                  variant="standard"
                />
              )}
              onKeyDown={handleEnter}
              onChange={(event, value) => {
                if (value) {
                  guardarTrasladosServicios({
                    ...trasladosServicios,
                    destinoGeo: value.label,
                    lat: value.lat,
                    lon: value.lon,
                  });
                } else {
                  guardarTrasladosServicios({
                    ...trasladosServicios,
                    destinoGeo: null,
                    lat: null,
                    lon: null,
                  });
                }
              }}
            />
          </Grid>

          <Grid item xs={4} md={1}>
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
              onClick={(e) => handleClickOpen()}
            >
              <SearchIcon />
            </IconButton>
          </Grid>

          <div className="dialog-demo">
            <Dialog
              header={renderHeaderMapa()}
              visible={open}
              footer={renderFooterMapa()}
              closable={false}
            >
              {PopupExample()}
            </Dialog>
          </div>

          <Grid item xs={12} md={12}></Grid>
        </Grid>
      </Fragment>
    </Root>
  );
}
