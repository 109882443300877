import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
//import "./example-styles.css";
//import "./styles.css";
//import { Responsive, WidthProvider } from "react-grid-layout";
import { AlertContext } from "../../../context/AlertContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
  latLngBound,
} from "react-leaflet";
import "./Map.css";
import {
  iconR,
  iconRp,
  iconRs,
  iconRl,
  iconRm,
  iconRt,
  iconRblinck,
  iconRpblinck,
  iconRsblinck,
  iconRlblinck,
  iconRtblinck,
  iconG,
  iconGp,
  iconGs,
  iconGl,
  iconGm,
  iconGt,
  iconGblinck,
  iconGpblinck,
  iconGsblinck,
  iconGlblinck,
  iconGtblinck,
  iconY,
  iconYp,
  iconYs,
  iconYl,
  iconYm,
  iconYt,
  iconYblinck,
  iconYpblinck,
  iconYsblinck,
  iconYlblinck,
  iconYtblinck,
  iconF,
  iconFp,
  iconFs,
  iconFl,
  iconFm,
  iconFt,
  iconFblinck,
  iconFpblinck,
  iconFsblinck,
  iconFlblinck,
  iconFtblinck,
  iconB,
  iconBp,
  iconBs,
  iconBl,
  iconBm,
  iconBt,
  iconBblinck,
  iconBpblinck,
  iconBsblinck,
  iconBlblinck,
  iconBtblinck,
  iconO,
  iconOp,
  iconOs,
  iconOl,
  iconOm,
  iconOt,
  iconOblinck,
  iconOpblinck,
  iconOsblinck,
  iconOlblinck,
  iconOtblinck,
  iconC,
  iconCp,
  iconCs,
  iconCl,
  iconCm,
  iconCt,
  iconCblinck,
  iconCpblinck,
  iconCsblinck,
  iconClblinck,
  iconCtblinck,
  iconN,
  iconNp,
  iconNs,
  iconNl,
  iconNm,
  iconNt,
  iconNblinck,
  iconNpblinck,
  iconNsblinck,
  iconNlblinck,
  iconNtblinck,
  iconM,
  iconT,
} from "./constants";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { AuthContext } from "../../../context/AuthContext";
import { MapsContext } from "../../../context/MapsContext";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";
import ConsultaServicio from "../servicios/ConsultaServicio";
import NotasServicio from "../servicios/NotasServicio";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ResumenServicio from "../servicios/ResumenServicio";
import ResumenMoviles from "../servicios/ResumenMoviles";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { Toast } from "primereact/toast";
import SpeedDialIcon from "../SpeedDialIcon";
import "../../../assets/css/MultiSelectDemo.css";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";


export default function Mapa(props) {
  //const theme = useTheme();
  const position = [-34.665007825872934, -58.511680443282735];
  const [data, setData] = useState([]);
  const [bounds, setBounds] = useState([]);
  const [loop, setLoop] = useState(null);
  const [band, setBand] = useState(false);
  const [visible, setVisible] = useState(false);
  const [opcionesMapa, setOpcionesMapa] = useState(null);
  const [coloresMapa, setColoresMapa] = useState(null);
  const [estadosMapa, setEstadosMapa] = useState(null);
  const [first, setFirst] = useState(true);

  const [opciones, setOpciones] = useState([
    { label: "DOMICILIOS", value: "DO" },
    { label: "MOVILES", value: "MO" },
    { label: "TRASLADOS", value: "TR" },
  ]);
  const [colors, setColors] = useState([
    { label: "ROJO", value: "RO" },
    { label: "AMARILLO", value: "AM" },
    { label: "VERDE", value: "VE" },
    { label: "AZUL", value: "AZ" },
    { label: "CELESTE", value: "CE" },
    { label: "NARANJA", value: "NA" },
    { label: "FUCCIA", value: "FU" },
    { label: "BLANCO", value: "BL" },
    { label: "NEGRO", value: "NE" },
  ]);
  const [estados, setEstados] = useState([
    { label: "TOMADO", value: "TO" },
    { label: "PASADO", value: "PA" },
    { label: "SALIDA", value: "SA" },
    { label: "LLEGADA", value: "LL" },
  ]);

  const [hideHeader, setHideHeader] = useState(null);
  const [tripula, setTripula] = useState([]);
  const [bandStart, setBandStart] = useState(false);
  const [timer, setTimer] = useState(null);
  const [confirmarApp, setConfirmarApp] = useState(false);
  const [tripulacionApp, setTripulacionApp] = useState(null);

  var bound = [];
  const toast = useRef(null);

  const alertContext = useContext(AlertContext);
  const { mensajeAlertaTR, mensajeAlertaTL } = alertContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const mapsContext = useContext(MapsContext);
  const { open, obtenerMovilesConTelefono, setOpen, setSelected } = mapsContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    despachos,
    tripulaciones,
    trasladosServicios,
    drawer,
    refrescar,
    openDrawer,
    estadoTomado,
    servicioSeleccionado,
    movilMarcado,
    obtenerServiciosPendientes,
    obtenerTrasladosServiciosPendientes,
    guardarServicio,
    obtenerTripulaciones,
    setDrawer,
    setOpenDrawer,
    guardarServicioSeleccionado,
    setRefrescar,
    guardarDemorados,
    setEstadoTomado,
    confirmarMovilLiberado,
    confirmarNuevoServicioPrestador,
  } = serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { colores, configuracion, obtenerColores } = configuracionContext;

  
  useEffect(() => {
    obtenerServiciosPendientes();
    obtenerTrasladosServiciosPendientes();
    obtenerTripulaciones({ estado: false });

    setTimeout(() => {
      //let elem = document.getElementById("inicioControl");
      //elem.click();
      setBandStart(true)
    }, 1000);

    if (localStorage.getItem("opcionesMapa") === null) {
      setOpcionesMapa(["MO", "DO", "TR"]);
    } else {
      setOpcionesMapa(JSON.parse(localStorage.getItem("opcionesMapa")));
    }

    if (localStorage.getItem("coloresMapa") === null) {
      setColoresMapa(["RO", "AM", "VE", "CE", "BL", "NE", "AZ", "NA", "FU"]);
    } else {
      setColoresMapa(JSON.parse(localStorage.getItem("coloresMapa")));
    }

    if (localStorage.getItem("estadosMapa") === null) {
      setEstadosMapa(["TO", "PA", "SA", "LL", "TR"]);
    } else {
      setEstadosMapa(JSON.parse(localStorage.getItem("estadosMapa")));
    }

    /*
    let lS = localStorage.getItem("headerMapa");
    var element = document.getElementsByClassName("MuiToolbar-root");
    if (lS === null || lS === "false") {
      element[0].style.display = "";
      setHideHeader(false);
    } else {
      element[0].style.display = "none";
      setHideHeader(true);
    }
    */
    
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, [refrescar]);

  

  useEffect(() => {
    //obtenerMovilesConTelefono();
    let interval = setInterval(() => {
      obtenerServiciosPendientes();
      obtenerTrasladosServiciosPendientes();
      obtenerTripulaciones({ estado: false });

      setTimeout(() => {
        //let elem = document.getElementById("inicioControl");
        //elem.click();
        setBandStart(true)
      }, 1000);

      setBand(false)
      
      if (localStorage.getItem("opcionesMapa") === null) {
        setOpcionesMapa(["MO", "DO", "TR"]);
      } else {
        setOpcionesMapa(JSON.parse(localStorage.getItem("opcionesMapa")));
      }

      if (localStorage.getItem("coloresMapa") === null) {
        setColoresMapa(["RO", "AM", "VE", "CE", "BL", "NE", "AZ", "NA", "FU"]);
      } else {
        setColoresMapa(JSON.parse(localStorage.getItem("coloresMapa")));
      }

      if (localStorage.getItem("estadosMapa") === null) {
        setEstadosMapa(["TO", "PA", "SA", "LL", "TR"]);
      } else {
        setEstadosMapa(JSON.parse(localStorage.getItem("estadosMapa")));
      }
    }, 30000);
    return () => clearTimeout(interval);
  }, []);

  

  //console.log(servicioSeleccionado);

  useEffect(() => {
    obtenerColores();
  }, []);


  /*
  if (!bandStart) {
    setTimeout(() => {
      handleStart();
    }, 60000);
    setBandStart(true);
  }
  */

  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === "refrescar") {
      setTimeout(() => {
        setRefrescar(newValue);
      }, 2000);
    }
  };


  const reload = () => {
    window.location.reload();
  };

  const handleDrawerOpenMoviles = () => {
    setDrawer("moviles");
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  /*
  if (!band && location.length > 0) {
    location.map((loc, index) => {
      bound.push([Number(loc.lat), Number(loc.lon)]);
    });
    setBand(true);
    setBounds(bound);
  }
  */


  function diff_minutes(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  function MarkersDomicilios({ data }) {
    const map = useMap();
    let icon;

    if (opcionesMapa.length === 0) {
      return true;
    }

    let bandDomicilios = false;
    for (var i = 0; i < opcionesMapa.length; i++) {
      if (opcionesMapa[i] === "DO") {
        bandDomicilios = true;
        break;
      }
    }

    if (!bandDomicilios) {
      return true;
    }

    return (
      data.length > 0 &&
      data.map((marker, index) => {
        //let d = new Date(marker.tomado);
        //d.setHours(d.getHours() + 3);
        let d2 = new Date();

        //console.log(marker);

        if (coloresMapa.indexOf(marker.color.substring(0, 2)) !== -1) {
          if (estadosMapa.indexOf(marker.estado.substring(0, 2)) !== -1) {
            if (marker.color === "ROJO") {
              switch (marker.estado.substring(0, 6)) {
                case "TOMADO":
                  icon = iconR;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconRblinck;
                  }
                  break;
                case "PASADO":
                  icon = iconRp;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconRpblinck;
                  }
                  break;
                case "SALIDA":
                  icon = iconRs;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconRsblinck;
                  }
                  break;
                case "LLEGAD":
                  icon = iconRl;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconRlblinck;
                  }
                  break;
                case "TRASLA":
                  icon = iconRt;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconRtblinck;
                  }
                  break;
              }
            }
            if (marker.color === "VERDE") {
              switch (marker.estado.substring(0, 6)) {
                case "TOMADO":
                  icon = iconG;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconGblinck;
                  }
                  break;
                case "PASADO":
                  icon = iconGp;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconGpblinck;
                  }
                  break;
                case "SALIDA":
                  icon = iconGs;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconGsblinck;
                  }
                  break;
                case "LLEGAD":
                  icon = iconGl;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconGlblinck;
                  }
                  break;
                case "TRASLA":
                  icon = iconGt;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconGtblinck;
                  }
                  break;
              }
            }
            if (marker.color === "CELESTE") {
              switch (marker.estado.substring(0, 6)) {
                case "TOMADO":
                  icon = iconC;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconCblinck;
                  }
                  break;
                case "PASADO":
                  icon = iconCp;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconCpblinck;
                  }
                  break;
                case "SALIDA":
                  icon = iconCs;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconCsblinck;
                  }
                  break;
                case "LLECAD":
                  icon = iconCl;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconClblinck;
                  }
                  break;
                case "TRASLA":
                  icon = iconCt;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconCtblinck;
                  }
                  break;
              }
            }
            if (marker.color === "AMARILLO") {
              switch (marker.estado.substring(0, 6)) {
                case "TOMADO":
                  icon = iconY;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconYblinck;
                  }
                  break;
                case "PASADO":
                  icon = iconYp;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconYpblinck;
                  }
                  break;
                case "SALIDA":
                  icon = iconYs;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconYsblinck;
                  }
                  break;
                case "LLEGAD":
                  icon = iconYl;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconYlblinck;
                  }
                  break;
                case "TRASLA":
                  icon = iconYt;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconYtblinck;
                  }
                  break;
              }
            }
            if (marker.color === "FUCCIA") {
              switch (marker.estado.substring(0, 6)) {
                case "TOMADO":
                  icon = iconF;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconFblinck;
                  }
                  break;
                case "PASADO":
                  icon = iconFp;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconFpblinck;
                  }
                  break;
                case "SALIDA":
                  icon = iconFs;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconFsblinck;
                  }
                  break;
                case "LLEGAD":
                  icon = iconFl;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconFlblinck;
                  }
                  break;
                case "TRASLA":
                  icon = iconFt;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconFtblinck;
                  }
                  break;
              }
            }
            if (marker.color === "AZUL") {
              switch (marker.estado.substring(0, 6)) {
                case "TOMADO":
                  icon = iconB;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconBblinck;
                  }
                  break;
                case "PASADO":
                  icon = iconBp;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconBpblinck;
                  }
                  break;
                case "SALIDA":
                  icon = iconBs;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconBsblinck;
                  }
                  break;
                case "LLEGAD":
                  icon = iconBl;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconBlblinck;
                  }
                  break;
                case "TRASLA":
                  icon = iconBt;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconBtblinck;
                  }
                  break;
              }
            }
            if (marker.color === "NARANJA") {
              switch (marker.estado.substring(0, 6)) {
                case "TOMADO":
                  icon = iconO;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconOblinck;
                  }
                  break;
                case "PASADO":
                  icon = iconOp;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconOpblinck;
                  }
                  break;
                case "SALIDA":
                  icon = iconOs;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconOsblinck;
                  }
                  break;
                case "LLEGAD":
                  icon = iconOl;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconOlblinck;
                  }
                  break;
                case "TRASLA":
                  icon = iconOt;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconOtblinck;
                  }
                  break;
              }
            }

            if (marker.color === "NEGRO") {
              switch (marker.estado.substring(0, 6)) {
                case "TOMADO":
                  icon = iconN;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconNblinck;
                  }
                  break;
                case "PASADO":
                  icon = iconNp;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconNpblinck;
                  }
                  break;
                case "SALIDA":
                  icon = iconNs;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconNsblinck;
                  }
                  break;
                case "LLEGAD":
                  icon = iconNl;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconNlblinck;
                  }
                  break;
                case "TRASLA":
                  icon = iconNt;
                  if (
                    servicioSeleccionado &&
                    servicioSeleccionado.id === marker.id
                  ) {
                    icon = iconNtblinck;
                  }
                  break;
              }
            }

            //console.log(icon)

            var tooltipPopup;
            //console.log(icon)
            return (
              <Marker
                eventHandlers={{
                  contextmenu: () => {
                    guardarServicio(marker);
                    setVisible(true);
                  },
                  click: () => {
                    guardarServicioSeleccionado(marker);
                    setVisible(false);
                    //handleDrawerOpenMoviles();
                    //map.setView([marker.lat, marker.lon], 14);
                  },
                  mouseover: () => {
                    map.openPopup(
                      `<b><u>Domicilio:</b></u> ${
                        marker.domicilio
                      } - <b><u>Nombre:</b></u> ${
                        marker.nombre
                      } - <b><u>Titular:</b></u> ${marker.nombreTitular}
                - <b><u>Teléfono:</b></u> ${marker.telefono}
                - (<b><u>Ind.:</b></u> ${marker.id}) 
                - <b><u>Móvil:</b></u> ${
                  marker.movil
                } - <b><u>Consulta:</b></u> ${
                        marker.motivoConsulta
                      } - <b><u>Grupo:</b></u> ${marker.grupo}
                - <b><u>Plan:</b></u> ${
                  marker.plan
                } - <b><u>Tomado:</b></u> ${marker.tomado.substring(0, 19)}`,
                      [marker.lat, marker.lon]
                    );
                  },
                  mouseout: () => {
                    map.closePopup();
                  },
                }}
                key={index}
                position={{
                  lat: marker.lat,
                  lng: marker.lon,
                }}
                icon={icon}
              >
                <Popup>
                  <span>
                    {marker.id + " - " + marker.tomado.substring(0, 19)}
                  </span>
                </Popup>
                <Tooltip direction="top" offset={[3, -40]} permanent>
                  {marker.id}
                </Tooltip>
              </Marker>
            );
          }
        }
      })
    );
  }

  function MarkersMoviles({ data }) {

    //console.log(data)

    const map = useMap();
    let icon;
    let movilBand;

    if (opcionesMapa.length === 0) {
      return true;
    }

    let bandMoviles = false;
    for (var i = 0; i < opcionesMapa.length; i++) {
      if (opcionesMapa[i] === "MO") {
        bandMoviles = true;
        break;
      }
    }

    if (!bandMoviles) {
      return true;
    }

    //console.log(data)

    return (
      data &&
      data.length > 0 &&
      data.map((marker, index) => {
        if (movilMarcado !== null) {
          movilBand = marker.movilId === movilMarcado;
        } else {
          movilBand = true;
        }
        if (marker.lat !== null && marker.lon !== null && movilBand) {
          let d = new Date(marker.fechaGps);
          d.setHours(d.getHours() + 3);
          let d2 = new Date();


          if (marker.color === "") {
            icon = iconM;
          }
          if (marker.color === "ROJO") {
            icon = iconRm;
          }
          if (marker.color === "VERDE") {
            icon = iconGm;
          }
          if (marker.color === "CELESTE") {
            icon = iconCm;
          }
          if (marker.color === "AMARILLO") {
            icon = iconYm;
          }
          if (marker.color === "FUCCIA") {
            icon = iconFm;
          }
          if (marker.color === "AZUL") {
            icon = iconBm;
          }
          if (marker.color === "NARANJA") {
            icon = iconOm;
          }
          if (marker.color === "NEGRO") {
            icon = iconNm;
          }

          return (
            <Marker
              eventHandlers={{
                contextmenu: () => {},

                click: () => {
                  //map.setView([marker.lat, marker.lon], 14);
                  map.openPopup(
                    `<b><u>Médico:</b></u> ${marker.medico} - <b><u>Chofer:</b></u> ${marker.chofer} - <b><u>Enfermero:</b></u> ${marker.enfermero}`,
                    [marker.lat, marker.lon]
                  );
                },
              }}
              key={index}
              position={{
                lat: marker.lat,
                lng: marker.lon,
              }}
              icon={icon}
            >
              {/*
            <Popup>
              <span>{marker.movil}</span>
            </Popup>
            */}
              <Tooltip direction="top" offset={[0, -40]} opacity={1} permanent>
                {marker.movilId + " - (" + diff_minutes(d, d2) + ")"}
              </Tooltip>
            </Marker>
          );
        }
      })
    );
  }

  function MarkersTraslados({ data }) {
    const map = useMap();
    let icon;
    let movilBand;

    if (opcionesMapa.length === 0) {
      return true;
    }

    let bandMoviles = false;
    for (var i = 0; i < opcionesMapa.length; i++) {
      if (opcionesMapa[i] === "TR") {
        bandMoviles = true;
        break;
      }
    }

    if (!bandMoviles) {
      return true;
    }

    //console.log(data)

    return (
      data &&
      data.length > 0 &&
      data.map((marker, index) => {
        if (marker.lat !== null && marker.lon !== null) {
          return (
            <Marker
              eventHandlers={{
                contextmenu: () => {},

                click: () => {
                  //map.setView([marker.lat, marker.lon], 14);
                  map.openPopup(`<b><u>Destino:</b></u> ${marker.destino}`, [
                    marker.lat,
                    marker.lon,
                  ]);
                },
              }}
              key={index}
              position={{
                lat: marker.lat,
                lng: marker.lon,
              }}
              icon={iconT}
            >
              {/*
            <Popup>
              <span>{marker.movil}</span>
            </Popup>
            */}

              <Tooltip direction="top" offset={[7, -40]} opacity={1} permanent>
                {marker.servicioId}
              </Tooltip>
            </Marker>
          );
        }
      })
    );
  }

  const leftToolbarTemplate = () => {
    return (
      <Fragment>
        <Grid id="dataMapaBotones" container spacing={0}>
          <Grid item xs={6} md={6}>
            <Button
              icon="pi pi-truck"
              className="p-button-rounded p-button-danger"
              tooltip="Móviles"
              style={{ marginLeft: "1em", marginRight: "1em" }}
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
                className: "tooltip",
              }}
              onClick={handleDrawerOpenMoviles}
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <Button
              icon="pi pi-replay"
              className="p-button-rounded p-button-warning"
              tooltip="Recargar"
              style={{ marginLeft: "1em", marginRight: "1em" }}
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
                className: "tooltip",
              }}
              onClick={reload}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4}>
            <MultiSelect
              id="multiselect1"
              value={opcionesMapa}
              options={opciones}
              onChange={(e) => {
                setOpcionesMapa(e.value);
                localStorage.setItem("opcionesMapa", JSON.stringify(e.value));
                reload();
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <MultiSelect
              id="multiselect2"
              value={coloresMapa}
              options={colors}
              onChange={(e) => {
                setColoresMapa(e.value);
                localStorage.setItem("coloresMapa", JSON.stringify(e.value));
                reload();
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <MultiSelect
              id="multiselect3"
              value={estadosMapa}
              options={estados}
              onChange={(e) => {
                setEstadosMapa(e.value);
                localStorage.setItem("estadosMapa", JSON.stringify(e.value));
                reload();
              }}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  const renderHeader = () => {
    return (
      <Fragment>
        <div align="left">
          <Tag value="Datos del Servicio"></Tag>
        </div>
      </Fragment>
    );
  };

  const renderHeaderResumen = () => {
    return (
      <Fragment>
        <div align="left">
          {drawer === "resumen" ? (
            <Tag value="Total/Pendientes/Demorados"></Tag>
          ) : null}
        </div>
      </Fragment>
    );
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const onHideResumen = (e) => {
    setOpenDrawer(false);
  };


  /* INICIO */

  if (despachos && despachos.length > 0 && colores & bandStart) {
    // Servicios tomados

    let indicativosDemoraLlegada = "";
    let indicativosAlertaLlegada = "";
    let indicativosDemoraSalida = "";
    let indicativosAlertaSalida = "";
    let indicativosPendiente = "";

    let rojoDemorados = 0;
    let amarilloDemorados = 0;
    let verdeDemorados = 0;
    let celesteDemorados = 0;
    let azulDemorados = 0;
    let fucciaDemorados = 0;
    let naranjaDemorados = 0;
    let blancoDemorados = 0;
    let negroDemorados = 0;

    let bandServicio = false;

    for (var i = 0; i < despachos.length; i++) {
      if (despachos[i].nuevoServicio) {
        bandServicio = true;
      }

      if (
        (despachos[i].estado === "TOMADO" ||
          despachos[i].estado === "PASADO" ||
          despachos[i].estado === "SALIDA") &&
        despachos[i].movilId < 1000
      ) {
        // Alerta y Demora de Llegadas

        let cantidadCaracteresTomado = despachos[i].tomado.length;
        let ubicacionEspacioTomado = despachos[i].tomado.indexOf(" ", 11);
        if (ubicacionEspacioTomado !== -1) {
          let tiempoTomado = despachos[i].tomado.substring(
            ubicacionEspacioTomado + 1,
            cantidadCaracteresTomado
          );
          let alertaLlegada;
          let demoraLlegada;
          for (var j = 0; j < colores.length; j++) {
            if (colores[j].nombre === despachos[i].color) {
              alertaLlegada = colores[j].alertaLlegada;
              demoraLlegada = colores[j].demoraLlegada;
              break;
            }
          }

          if (
            Number(tiempoTomado) >= Number(demoraLlegada) &&
            Number(demoraLlegada) !== 0
          ) {
            if (indicativosDemoraLlegada === "") {
              indicativosDemoraLlegada = String(despachos[i].id);
            } else {
              indicativosDemoraLlegada =
                indicativosDemoraLlegada + " - " + String(despachos[i].id);
            }

            switch (despachos[i].estado) {
              case "TOMADO":
                despachos[i].estado = "TOMADO / DEMORADO";
                break;
              case "PASADO":
                despachos[i].estado = "PASADO / DEMORADO";
                break;
              case "SALIDA":
                despachos[i].estado = "SALIDA / DEMORADO";
                break;
            }

            if (despachos[i].color === "ROJO") {
              rojoDemorados = rojoDemorados + 1;
            }
            if (despachos[i].color === "AMARILLO") {
              amarilloDemorados = amarilloDemorados + 1;
            }
            if (despachos[i].color === "VERDE") {
              verdeDemorados = verdeDemorados + 1;
            }
            if (despachos[i].color === "CELESTE") {
              celesteDemorados = celesteDemorados + 1;
            }
            if (despachos[i].color === "AZUL") {
              azulDemorados = azulDemorados + 1;
            }
            if (despachos[i].color === "FUCCIA") {
              fucciaDemorados = fucciaDemorados + 1;
            }
            if (despachos[i].color === "NARANJA") {
              naranjaDemorados = naranjaDemorados + 1;
            }
            if (despachos[i].color === "BLANCO") {
              blancoDemorados = blancoDemorados + 1;
            }
            if (despachos[i].color === "NEGRO") {
              negroDemorados = negroDemorados + 1;
            }
          }

          if (
            Number(tiempoTomado) >= Number(alertaLlegada) &&
            Number(tiempoTomado) < Number(demoraLlegada) &&
            Number(alertaLlegada) !== 0
          ) {
            if (indicativosAlertaLlegada === "") {
              indicativosAlertaLlegada = String(despachos[i].id);
            } else {
              indicativosAlertaLlegada =
                indicativosAlertaLlegada + " - " + String(despachos[i].id);
            }

            switch (despachos[i].estado) {
              case "TOMADO":
                despachos[i].estado = "TOMADO / ALERTA";
                break;
              case "PASADO":
                despachos[i].estado = "PASADO / ALERTA";
                break;
              case "SALIDA":
                despachos[i].estado = "SALIDA / ALERTA";
                break;
            }
          }

          //Pendientes

          let alertaPendiente;
          for (var j = 0; j < colores.length; j++) {
            if (colores[j].nombre === despachos[i].color) {
              alertaPendiente = colores[j].alertaPendiente;
              break;
            }
          }

          if (despachos[i].estado.substring(0, 6) === "TOMADO") {
            if (alertaPendiente) {
              if (indicativosPendiente === "") {
                indicativosPendiente = String(despachos[i].id);
              } else {
                indicativosPendiente =
                  indicativosPendiente + " - " + String(despachos[i].id);
              }
            }
          }
          // Alerta y Demora de Salidas

          if (
            despachos[i].estado.substring(0, 6) === "PASADO" &&
            despachos[i].pasado !== null
          ) {
            let cantidadCaracteresPasado = despachos[i].pasado.length;
            let ubicacionEspacioPasado = despachos[i].pasado.indexOf(" ", 11);

            if (ubicacionEspacioPasado !== -1) {
              let tiempoPasado = despachos[i].pasado.substring(
                ubicacionEspacioPasado + 1,
                cantidadCaracteresPasado
              );
              let alertaSalida;
              let demoraSalida;
              for (var j = 0; j < colores.length; j++) {
                if (colores[j].nombre === despachos[i].color) {
                  alertaSalida = colores[j].alertaSalida;
                  demoraSalida = colores[j].demoraSalida;
                  break;
                }
              }
              if (
                Number(tiempoPasado) >= Number(demoraSalida) &&
                Number(demoraSalida) !== 0
              ) {
                if (indicativosDemoraSalida === "") {
                  indicativosDemoraSalida = String(despachos[i].id);
                } else {
                  indicativosDemoraSalida =
                    indicativosDemoraSalida + " - " + String(despachos[i].id);
                }
                despachos[i].estado = "PASADO / DEMORADO";
              }

              if (
                Number(tiempoPasado) >= Number(alertaSalida) &&
                Number(tiempoPasado) < Number(demoraSalida) &&
                Number(alertaSalida) !== 0
              ) {
                if (indicativosAlertaSalida === "") {
                  indicativosAlertaSalida = String(despachos[i].id);
                } else {
                  indicativosAlertaSalida =
                    indicativosAlertaSalida + " - " + String(despachos[i].id);
                }
              }
            }
          }
        }
      }
    }

    if (
      rojoDemorados > 0 ||
      amarilloDemorados > 0 ||
      verdeDemorados > 0 ||
      celesteDemorados > 0 ||
      azulDemorados > 0 ||
      fucciaDemorados > 0 ||
      naranjaDemorados > 0 ||
      blancoDemorados > 0 ||
      negroDemorados > 0
    ) {
      guardarDemorados({
        rojoDemorados,
        amarilloDemorados,
        verdeDemorados,
        celesteDemorados,
        azulDemorados,
        fucciaDemorados,
        naranjaDemorados,
        blancoDemorados,
        negroDemorados,
      });
    }

    let d = new Date(timer);
    let d2 = new Date();
    d.setSeconds(d.getSeconds() + 60);
    if (d2 > d || first) {
      setFirst(false);

      if (configuracion && configuracion.alertas) {
        if (indicativosDemoraSalida !== "") {
          mensajeAlertaTL(
            "Alertas",
            "Id/s " +
              indicativosDemoraSalida +
              " están demorados en la salida del móvil !.",
            "error"
          );
        }

        if (indicativosAlertaSalida !== "") {
          setTimeout(() => {
            mensajeAlertaTL(
              "Alertas",
              "Id/s " +
                indicativosAlertaSalida +
                " están en alerta en la salida del móvil !.",
              "warn"
            );
          }, 1000);
        }

        if (indicativosDemoraLlegada !== "") {
          setTimeout(() => {
            mensajeAlertaTL(
              "Alertas",
              "Id/s " +
                indicativosDemoraLlegada +
                " están demorados en la llegada al destino !.",
              "error"
            );
          }, 2000);
        }

        if (indicativosAlertaLlegada !== "") {
          setTimeout(() => {
            mensajeAlertaTL(
              "Alertas",
              "Id/s " +
                indicativosAlertaLlegada +
                " están en alerta en la llegada al destino !.",
              "warn"
            );
          }, 3000);
        }

        if (indicativosPendiente !== "") {
          mensajeAlertaTR(
            "Alertas",
            "Id/s " +
              indicativosPendiente +
              " están pendientes de asignar móvil !.",
            "info"
          );
        }

        if (tripulaciones) {
          for (var i = 0; i < tripulaciones.length; i++) {
            if (tripulaciones[i].confirmaApp && !confirmarApp) {
              setConfirmarApp(true);
              setTripulacionApp(tripulaciones[i]);
              break;
            }
          }
        }

        if (bandServicio && toast.current !== null) {
          mostrarMensajeNuevoServicio(despachos);
        }
  
        setTimer(new Date());
      }
      setBandStart(false)
      //setEstadoTomado(true);
    }
  }

//////////////////////


  if (
    !band &&
    despachos &&
    despachos.length > 0 &&
    opcionesMapa &&
    opcionesMapa.length > 0 &&
    tripulaciones &&
    tripulaciones.length >= 0
  ) {

    let bandDomicilios = false;
    for (var i = 0; i < opcionesMapa.length; i++) {
      if (opcionesMapa[i] === "DO") {
        bandDomicilios = true;
        break;
      }
    }

    if (bandDomicilios) {
      despachos.map((loc, index) => {
        if (
          loc.lat !== null &&
          loc.lon !== null &&
          coloresMapa.indexOf(loc.color.substring(0, 2)) !== -1
        ) {
          bound.push([Number(loc.lat), Number(loc.lon)]);
        }
      });
    }

    let bandMoviles = false;
    for (var i = 0; i < opcionesMapa.length; i++) {
      if (opcionesMapa[i] === "MO") {
        bandMoviles = true;
        break;
      }
    }

    if (bandMoviles) {
      if (!band && tripulaciones && tripulaciones.length > 0) {
        tripulaciones.map((loc, index) => {
          if (
            loc.lat !== null &&
            loc.lon !== null &&
            loc.localizacion === true
          ) {
            bound.push([Number(loc.lat), Number(loc.lon)]);
          }
        });
      }
    }

    if (tripulaciones && tripulaciones.length > 0) {
      let trip = [];

      for (var i = 0; i < tripulaciones.length; i++) {
        if (tripulaciones[i].localizacion) {
          trip.push({
            movilId: tripulaciones[i].movilId,
            movil: tripulaciones[i].movil,
            color: "",
            lat: tripulaciones[i].lat,
            lon: tripulaciones[i].lon,
            fechaGps: tripulaciones[i].fechaGps,
            medico: tripulaciones[i].medico,
            chofer: tripulaciones[i].chofer,
            enfermero: tripulaciones[i].enfermero,
          });
          for (var j = 0; j < despachos.length; j++) {
            if (tripulaciones[i].servicioId === despachos[j].id) {
              trip[i] = {
                movilId: tripulaciones[i].movilId,
                movil: tripulaciones[i].movil,
                color: despachos[j].color,
                lat: tripulaciones[i].lat,
                lon: tripulaciones[i].lon,
                fechaGps: tripulaciones[i].fechaGps,
                medico: tripulaciones[i].medico,
                chofer: tripulaciones[i].chofer,
                enfermero: tripulaciones[i].enfermero,
              };
            }
          }
        }
      }
      setTripula(trip);
      //console.log(trip)
    }

    setBand(true);
    setBounds(bound);
  }



  /* FIN */

  const confirmarAppDialogFooter = (
    <Fragment>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={(e) => aceptarAppNotificacion()}
      />
      {/*
      <Button
        label="No"
        icon="pi pi-clock"
        className="p-button-text"
        onClick={(e) => hideConfirmarAppNotificacion()}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={(e) => cancelarAppNotificacion()}
      />
      */}
    </Fragment>
  );

  const confirmarAppDialogHeader = () => {
    return (
      <div>
        <Tag value="Aviso de Móvil Liberado"></Tag>
      </div>
    );
  };

  const aceptarAppNotificacion = () => {
    confirmarMovilLiberado({ tripulacionId: tripulacionApp.id });
    setTripulacionApp(null);
    setConfirmarApp(false);
  };


  function mostrarMensajeNuevoServicio(desp) {
    let newElement = null;

    for (var i = desp.length - 1; i >= 0; i--) {
      if (desp[i].nuevoServicio) {
        newElement = {
          id: desp[i].id,
          nombreTitular: desp[i].nombreTitular,
          color: desp[i].color,
          titularId: desp[i].titularId,
          domicilio: desp[i].domicilio,
          status: 0,
        };
        break;
      }
    }

    const index = desp.findIndex(
      (item) =>
        item.titularId === newElement.titularId && item.id !== newElement.id
    );

    if (configuracion.pami === newElement.titularId) {
      if (newElement && newElement.status === 0) {
        let id = newElement.id;

        toast.current.show({
          severity: "warn",
          summary: "Nuevo Servicio",
          detail: `La empresa ${newElement.nombreTitular} ha agregado un servicio ${newElement.color} con el ID. ${newElement.id} en domicilio ${newElement.domicilio}.`,
          life: 5000,
          sticky: true,
        });

        newElement.status = 1;
        setTimeout(() => {
          confirmarNuevoServicioPrestador({ id: id });
        }, 30000);
      }
    }

    if (configuracion.pami !== newElement.titularId) {
      if (newElement && newElement.status === 0 && index !== -1) {
        let id = newElement.id;
        let indexId = desp[index].id;

        toast.current.show({
          severity: "warn",
          summary: "Nuevo Servicio",
          detail: `Ya existe un servicio pendiente del Afiliado ${newElement.nombreTitular} con el ID. ${indexId}.`,
          life: 5000,
          sticky: true,
        });

        newElement.status = 1;
        setTimeout(() => {
          confirmarNuevoServicioPrestador({ id: id });
        }, 30000);
      } else {
        let id = newElement.id;
        newElement.status = 1;
        setTimeout(() => {
          confirmarNuevoServicioPrestador({ id: id });
        }, 1000);
      }
    }
  }



  //console.log(band);
  //console.log(servicioSeleccionado)

  return (
    <Fragment>
      <Toast ref={toast} />

      {despachos && despachos.length > 0 && bandStart ? (
        <Fragment>
          <Toast ref={toast} position="top-left" />
          <CssBaseline />
          <div className="card">
            <Toolbar
              className="p-toolbar"
              //style={{ padding: "0.3em" }}
              start={leftToolbarTemplate}
              end={rightToolbarTemplate}
            ></Toolbar>
          </div>

          <div className="dialog-demo p-dialog-despacho">
            <div className="card">
              <Dialog
                header={renderHeader}
                visible={visible}
                className="p-dialog-despacho"
                icon="pi pi-external-link"
                closable={true}
                onHide={() => onHide()}
                //footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{
                  width: "100vw",
                  padding: "0",
                  overflowY: "visible",
                }}
                position="top-right"
              >
                <Grid container spacing={1} style={{ padding: "0" }}>
                  <Grid item xs={12} md={8}>
                    <ScrollPanel style={{ width: "100%", height: "100%" }}>
                      <div style={{ padding: "0em", lineHeight: "1.5" }}>
                        <ConsultaServicio />
                      </div>
                    </ScrollPanel>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ScrollPanel
                      style={{ width: "100%", height: "100%" }}
                      className="custombar1"
                    >
                      <div style={{ padding: "0em", lineHeight: "1.5" }}>
                        <NotasServicio />
                      </div>
                    </ScrollPanel>
                  </Grid>
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderResumen}
                visible={openDrawer}
                icon="pi pi-external-link"
                closable={true}
                onHide={() => onHideResumen()}
                //footer={renderFooter()}
                breakpoints={{ "960px": "45vw" }}
                style={{
                  width: drawer === "resumen" ? "15vw" : "25vw",
                  padding: "0",
                  overflowY: "visible",
                }}
                position="top-left"
              >
                <Grid container spacing={1} style={{ padding: "0" }}>
                  {drawer === "resumen" ? <ResumenServicio /> : null}
                  {drawer === "moviles" ? <ResumenMoviles /> : null}
                </Grid>
              </Dialog>
            </div>
          </div>

          {bounds.length > 0 ? (
            <Fragment>
              <MapContainer
                bounds={bounds}
                zoom={13}
                scrollWheelZoom={false}
                style={
                  hideHeader === false ? { height: "80vh" } : { height: "90vh" }
                }
              >
                {servicioSeleccionado ? <SpeedDialIcon desde="mapa" /> : null}

                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                
                <MarkersDomicilios data={despachos} />
              
              
                <MarkersMoviles data={tripula} />
            
                {/*
                <MarkersTraslados data={trasladosServicios} />
              */}
              </MapContainer>
            </Fragment>
          ) : (
            <Spinner />
          )}

          {/*    
        <MapContainer
          center={[47.217324, 13.097555]}
          zoom={13}
          scrollWheelZoom={false}
          style={{ height: "100vh" }}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Markers data={data} />
          <Marker position={position}>
            <Popup>
              <span>{"5"}</span>
            </Popup>
            <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>
              {"5"}
            </Tooltip>
          </Marker>
        </MapContainer>
          */}

          {tripulacionApp ? (
            <Dialog
              header={confirmarAppDialogHeader}
              visible={confirmarApp}
              closable={false}
              footer={confirmarAppDialogFooter}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "50vw", paddingBottom: "0" }}
              position="center"
            >
              <h4
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >{`El móvil ${tripulacionApp.movilId} con tripulación ${
                tripulacionApp.medico ? tripulacionApp.medico : ""
              }-${tripulacionApp.chofer ? tripulacionApp.chofer : ""}-${
                tripulacionApp.enfermero ? tripulacionApp.enfermero : ""
              } quedó liberado.`}</h4>
            </Dialog>
          ) : null}
        </Fragment>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
}
