import React, { useContext, useEffect, useState } from "react";
import { Route, Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Spinner from "../layout/Spinner";

function RutaPrivada({ children }) {
  const authContext = useContext(AuthContext);
  const { autenticado, usuarioAutenticado, state } = authContext;

  useEffect(() => {
    usuarioAutenticado();
  }, [autenticado]);

  //return autenticado ? children : <Navigate to="/login" />;


  return autenticado === true ? (
    children
  ) : autenticado === false ? (
    <Navigate to = "/login" />
  ) : (
    <Spinner />
  );
}

export default RutaPrivada;
