//imports front
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import { Navigate } from "react-router";
import * as EmailValidator from "email-validator";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

const theme = createTheme();

export default function Change(props) {
  const authContext = useContext(AuthContext);
  const { cambiarPassword } = authContext;

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const [clave1, guardarClave1] = useState(null);
  const [clave2, guardarClave2] = useState(null);
  const [mensajeError, guardarMensajeError] = useState("");
  const [estado, guardarEstado] = useState(false);

  let { token } = useParams();

  const resetPassword = (e) => {
    e.preventDefault();

    //Validar

    let token1 = token.split("-")

    let tokenFin = token1[0] + token1[1] + token1[2] + token1[3] + token1[4] 


    if (tokenFin !== "90f9a1bf27b64286b611eed22bfcc60b") {
      mensajeAlerta("Reset", "Hubo un error !.", "error");
      return;
    }

    let usuarioId = Number(token1[5])

    if (clave1 === null || clave1.trim() === "") {
      mensajeAlerta("Reset", "El password es incorrecto !.", "error");
      return;
    }

    if (clave1.length < 6) {
      mensajeAlerta(
        "Reset",
        "El password debe tener al menos 6 caracteres !.",
        "error"
      );
      return;
    }

    if (clave2 != clave1) {
      mensajeAlerta("Reset", "Los password son diferentes !.", "error");
      return;
    }

    cambiarPassword({clave1, usuarioId});
    guardarEstado(true);
  };

  //console.log(token);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar Contraseña
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              style={{marginTop: "0.5vw"}}
              required
              fullWidth
              name="clave1"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={clave1}
              onChange={(e) =>
                guardarClave1(e.target.value)
              }
            />
            <TextField
              variant="outlined"
              style={{marginTop: "1.5vw"}}
              required
              fullWidth
              name="clave2"
              label="Repetir password"
              type="password"
              id="password2"
              autoComplete="current-password"
              value={clave2}
              onChange={(e) =>
                guardarClave2(e.target.value)
              }
            />

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={resetPassword}
              style={{ marginTop: "1vw" }}
            >
              Aceptar
            </Button>
          </Box>
        </Box>
        {estado ? <Navigate to="/" /> : null}
      </Container>
    </ThemeProvider>
  );
}
