import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "primereact/button";
import { AlertContext } from "../../../context/AlertContext";
import { Link, Redirect } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../../layout/Spinner";
import Autocomplete from "@mui/material/Autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { AuthContext } from "../../../context/AuthContext";
import { Dialog } from "primereact/dialog";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import { Tag } from "primereact/tag";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import Barra from "../Barra";

const PREFIX = "MyCard";
const classes = {
  root: `${PREFIX}-root`,
  layout: `${PREFIX}-layout`,
  paper: `${PREFIX}-paper`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },
  [`&.${classes.layout}`]: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export default function Accesos(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    usuarios,
    pantallas,
    rows,
    statusAcceso,
    obtenerUsuarios,
    agregarAccesos,
    obtenerAccesos,
    obtenerAcceso,
    setRows,
  } = configuracionContext;

  const [usuario, guardarUsuario] = useState(null);
  const [id, setId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate();

  useEffect(() => {
    usuarioAutenticado();
  }, [state.id]);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Accesos" });
    }
    obtenerUsuarios();
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const onHide = (e) => {
    setVisible(false);
    guardarUsuario(null);
    setSelectedRow(null);
  };

  const editClick = () => {
    if (selectedRow) {
      guardarUsuario(selectedRow);
      obtenerAccesos({ usuarioId: selectedRow.id });
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un usuario.",
        life: 3000,
      });
    }
  };

  const onChangeUsuario = (e, value) => {
    e.preventDefault();
    if (value) {
      guardarUsuario({
        ...usuario,
        usuarioId: value.id,
        usuarioNombre: value.usuario,
        usuarioLocal: value.local,
        usuarioExterior: value.exterior,
      });

      obtenerAccesos({ usuarioId: value.id });
    } else {
      guardarUsuario(null);
    }
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const usuarioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Usuario</span>
        {rowData.usuario}
      </Fragment>
    );
  };

  const emailTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Email</span>
        {rowData.email}
      </Fragment>
    );
  };

  const apellidoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Apellido</span>
        {rowData.apellido}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const localTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Local</span>
        <Checkbox checked={rowData.local}></Checkbox>
      </Fragment>
    );
  };

  const exteriorTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Exterior</span>
        <Checkbox checked={rowData.exterior}></Checkbox>
      </Fragment>
    );
  };

  const aceptarClick = (e) => {
    e.preventDefault();

    if (!usuario || usuario.usuario.trim() === "") {
      mensajeAlerta("El usuario es necesario !.", "error");
      return;
    }

    agregarAccesos({ rows, usuario });
    setVisible(false);
    setSelectedRow(null);
    setTimeout(() => {
      obtenerUsuarios();
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Usuarios"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return <div>{usuario ? <Tag value={usuario.usuario}></Tag> : null}</div>;
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const estadoCheckbox = (rowData) => {
    console.log("si");
  };

  console.log(usuarios);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />

      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {usuarios ? (
            <Fragment>
              <Grid id="dataTableBotones" xs={12} sm={12} md={12}>
                {/*
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                */}
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                />

                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={usuarios}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                        style={{ display: "none" }}
                      ></Column>
                      <Column
                        field="apellido"
                        header="Apellido"
                        body={apellidoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        body={nombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="usuario"
                        header="Usuario"
                        body={usuarioTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="email"
                        header="Email"
                        body={emailTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="local"
                        header="Local"
                        body={localTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="exterior"
                        header="Exterior"
                        body={exteriorTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                maximized={true}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3}>
                  {/*
                  <Grid item xs={12} md={6} className={classes.grid}>
                    <Autocomplete
                      id="usuarios"
                      fullWidth
                      options={usuarios}
                      getOptionLabel={(option) => option.usuario}
                      renderInput={(params) => (
                        <TextField {...params} label="Usuario" />
                      )}
                      onChange={(event, value) => onChangeUsuario(event, value)}
                    />
                  </Grid>
                  */}
                  {usuario ? (
                    <Fragment>
                      <Grid item xs={6} md={2} style={{ paddingLeft: 0 }}>
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={usuario.local}
                              onChange={(e) =>
                                guardarUsuario({
                                  ...usuario,
                                  local: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Local"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={6} md={2} style={{ paddingLeft: 0 }}>
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={usuario.exterior}
                              onChange={(e) =>
                                guardarUsuario({
                                  ...usuario,
                                  exterior: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Exterior"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Fragment>
                  ) : null}

                  {usuario && pantallas ? (
                    <Fragment>
                      <Grid item xs={12} md={12} className={classes.grid}>
                        <div className="datatable-responsive-demo">
                          <div className="card">
                            <DataTable
                              value={pantallas}
                              selection={rows}
                              onSelectionChange={(e) => setRows(e.value)}
                              dataKey="id"
                              //header={header}
                              className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                              style={{ fontSize: "small" }}
                              //globalFilter={globalFilter}
                              emptyMessage="No hay datos."
                              paginator
                              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                              rows={10}
                              rowsPerPageOptions={[10, 20, 50]}
                              //paginatorLeft={paginatorLeft}
                              //paginatorRight={paginatorRight}
                            >
                              <Column field="id" header="ID" sortable></Column>
                              <Column
                                field="menu"
                                header="Menú"
                                sortable
                              ></Column>
                              <Column
                                field="descripcion"
                                header="Descripción"
                                sortable
                              ></Column>
                              <Column
                                selectionMode="multiple"
                                headerStyle={{ width: "3em" }}
                              ></Column>
                            </DataTable>
                          </div>
                        </div>
                      </Grid>
                    </Fragment>
                  ) : null}
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}

          {/*  

          {usuarios ? (
            <Fragment>
              <CssBaseline />
              <main className={classes.layout}>
                <Paper className={classes.paper}>
                  <DialogTitle id="id" style={{ paddingTop: 0 }}>
                    <Box display="flex" alignItems="center" margin-top="0.1em">
                      <Box flexGrow={1}>{"Accesos a usuarios"}</Box>
                      <Box>
                        <IconButton component={Link} to="/">
                          <CloseIcon style={{ color: "#3F51B5" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </DialogTitle>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} className={classes.grid}>
                      <Autocomplete
                        id="usuarios"
                        fullWidth
                        options={usuarios}
                        getOptionLabel={(option) => option.usuario}
                        renderInput={(params) => (
                          <TextField {...params} label="Usuario" />
                        )}
                        onChange={(event, value) =>
                          onChangeUsuario(event, value)
                        }
                      />
                    </Grid>
                    {usuario ? (
                      <Fragment>
                        <Grid item xs={6} md={2} style={{ paddingLeft: 0 }}>
                          <FormControlLabel
                            value="yes"
                            control={
                              <Checkbox
                                color="primary"
                                checked={usuario.usuarioLocal}
                                onChange={(e) =>
                                  guardarUsuario({
                                    ...usuario,
                                    usuarioLocal: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Local"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item xs={6} md={2} style={{ paddingLeft: 0 }}>
                          <FormControlLabel
                            value="yes"
                            control={
                              <Checkbox
                                color="primary"
                                checked={usuario.usuarioExterior}
                                onChange={(e) =>
                                  guardarUsuario({
                                    ...usuario,
                                    usuarioExterior: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Exterior"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Fragment>
                    ) : null}

                    {usuario && pantallas ? (
                      <Fragment>
                        <Grid item xs={12} md={12} className={classes.grid}>
                          <div className="datatable-responsive-demo">
                            <div className="card">
                              <DataTable
                                value={pantallas}
                                selection={rows}
                                onSelectionChange={(e) => setRows(e.value)}
                                dataKey="id"
                                //header={header}
                                className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                                style={{ fontSize: "small" }}
                                globalFilter={globalFilter}
                                emptyMessage="No hay datos."
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                //paginatorLeft={paginatorLeft}
                                //paginatorRight={paginatorRight}
                              >
                                <Column
                                  field="id"
                                  header="ID"
                                  sortable
                                ></Column>
                                <Column
                                  field="menu"
                                  header="Menú"
                                  sortable
                                ></Column>
                                <Column
                                  field="descripcion"
                                  header="Descripción"
                                  sortable
                                ></Column>
                                <Column
                                  selectionMode="multiple"
                                  headerStyle={{ width: "3em" }}
                                ></Column>
                              </DataTable>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            style={{ backgroundColor: "green" }}
                            onClick={aceptarClick}
                          >
                            Aceptar
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            style={{ backgroundColor: "#D8261C" }}
                            onClick={exitClick}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                      </Fragment>
                    ) : null}
                  </Grid>
                </Paper>
              </main>
            </Fragment>
          ) : (
            <Spinner />
          )}
          */}
        </Fragment>
      ) : null}
    </Fragment>
  );
}
