//imports front
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { AuthContext } from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import * as EmailValidator from "email-validator";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material//Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Navigate } from "react-router";


export default function Reset(props) {
  const authContext = useContext(AuthContext);
  const { verificacionEmail, logo, setLogo } = authContext;

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const [email, guardarEmail] = useState("");
  const [mensajeError, guardarMensajeError] = useState("");
  const [estado, guardarEstado] = useState(false);

  const resetPassword = (e) => {
    e.preventDefault();

    //Validar

    if (email.trim() === "") {
      mensajeAlerta("El email es necesario !.", "error");
      return;
    }

    if (!EmailValidator.validate(email)) {
      mensajeAlerta("El email es incorrecto !.", "error");
      return;
    }

    verificacionEmail({ email });
    guardarEstado(true);
    setTimeout(() => {
      window.location.reload("/")
    }, 1000);
  };

  if (logo) {
    setLogo(false)
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Restablecer Contraseña
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Dirección de Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => guardarEmail(e.target.value)}
          />

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={resetPassword}
            style={{ marginTop: "1vw" }}
          >
            Enviar Verificación
          </Button>
        </Box>
      </Box>
      {estado ? <Navigate to="/" /> : null}
    </Container>
  );
}
