import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { AlertContext } from "../../../context/AlertContext";
import { Link, Redirect } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { AuthContext } from "../../../context/AuthContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../../layout/Spinner";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
import Barra from "../Barra";


const PREFIX = "NumeroInterno";
const classes = {
  root: `${PREFIX}-root`,
  layout: `${PREFIX}-layout`,
  paper: `${PREFIX}-paper`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: "auto",
    width: "40%",

  },
  [`&.${classes.layout}`]: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

}));

export default function NumeroInterno(props) {
  //const classes = useStyles();

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { statusAcceso, obtenerAcceso } = configuracionContext;

  const [configuracion, guardarConfiguracion] = useState({
    numeroInterno: 0,
  });

  const toast = useRef(null);
  const [redirect, setRedirect] = useState(false);
  let navigate = useNavigate();


  // extraer de usuario
  const { numeroInterno } = configuracion;

  useEffect(() => {
    usuarioAutenticado();
  }, [state.id]);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "NumeroInterno" });
    }

    if (localStorage.getItem("numeroInterno") !== null) {
      guardarConfiguracion({
        ...configuracion,
        numeroInterno: localStorage.getItem("numeroInterno"),
      });
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const aceptarClick = (e) => {
    e.preventDefault();

   
    //Validar
    if (Number(numeroInterno) === 0) {
      mensajeAlerta("Configuración", "El número de interno es necesario !.", "error");
      return;
    }

    localStorage.setItem("numeroInterno", numeroInterno);
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const accesoDenegado = () => {
    mensajeAlerta("Accesos", "No tiene permiso para acceder a esta pantalla !.", "error");
    exitClick();
    return;
  };

  return (
    <Root className={classes.root}>
      <Barra />
      <Toast ref={toast} />
      <Fragment>
      
        {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
        {statusAcceso === 200 ? (
          <Fragment>
            <CssBaseline />
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <DialogTitle id="id">
                  <Box display="flex" alignItems="center" margin-top="0.1em">
                    <Box flexGrow={1}>{"Número de interno teléfonico"}</Box>
                    <Box>
                      <IconButton component={Link} to="/">
                        <CloseIcon style={{ color: "#3F51B5" }} />
                      </IconButton>
                    </Box>
                  </Box>
                </DialogTitle>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="numeroInterno"
                      name="numeroInterno"
                      label="Número de interno"
                      fullWidth
                      type="number"
                      autoComplete="given-name"
                      value={numeroInterno}
                      onChange={(e) =>
                        guardarConfiguracion({
                          ...configuracion,
                          numeroInterno: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: "green" }}
                      onClick={aceptarClick}
                    >
                      Aceptar
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: "#D8261C" }}
                      onClick={exitClick}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </main>
          </Fragment>
        ) : null}
      </Fragment>
    </Root>
  );
}
