import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from '@mui/material/Fab';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from '@mui/material/Button';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";


export default function Enfermeros(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Apellido y Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Email", dataKey: "email" },
    { title: "D.N.I.", dataKey: "numeroDocumento" },
    { title: "C.U.I.T./C.U.I.L.", dataKey: "cuit" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [enfermero, guardarEnfermero] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate();
  
  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    enfermeros,
    obtenerEnfermeros,
    obtenerAcceso,
    agregarEnfermero,
    actualizarEnfermero,
  } = configuracionContext;


  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Enfermeros" });
    }
    obtenerEnfermeros({ todos: true });
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = (e) => {
    if (selectedRow) {
      guardarEnfermero(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un enfermero.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //navigate(`/base/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarEnfermero({
      domicilio: "",
      telefono: "",
      email: "",
      numeroDocumento: "",
      cuit: "",
      habilitado: true,
    });
  };

  const exitClick = (e) => {
   navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 2);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !enfermero ||
      enfermero.nombre === undefined ||
      enfermero.nombre === null ||
      enfermero.nombre.trim() === ""
    ) {
      mensajeAlerta("Enfermeros", "El nombre es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarEnfermero({ enfermero });
    } else {
      agregarEnfermero({ enfermero });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerEnfermeros({ todos: true });
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta("Accesos", "No tiene permiso para acceder a esta pantalla !.", "error");
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };
  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Teléfono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const emailTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Email</span>
        {rowData.email}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const cuitTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">C.U.I.T.</span>
        {rowData.cuit}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado de Enfermeros", 60, 8);
        doc.autoTable(exportColumns, enfermeros);
        //doc.save("enfermeros.pdf");
        window.open(doc.output('bloburl'))
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(enfermeros);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "enfermeros");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Enfermeros"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Enfermero"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(enfermeros);
  //console.log(state);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {enfermeros ? (
            <Fragment>
              <Grid
                id="dataTableBotones"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={enfermeros}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "xx-small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //style={{ width: "5%" }}
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Apellido y Nombre"
                        //style={{ width: "20%" }}
                        body={nombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="domicilio"
                        header="Domicilio"
                        //style={{ width: "20%" }}
                        body={domicilioTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="telefono"
                        header="Teléfono"
                        //style={{ width: "10%" }}
                        body={telefonoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="email"
                        header="Email"
                        //style={{ width: "20%" }}
                        body={emailTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="numeroDocumento"
                        header="D.N.I."
                        body={documentoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="cuit"
                        header="C.U.I.T./C.U.I.L."
                        body={cuitTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={enfermero ? enfermero.id : null}
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={enfermero ? enfermero.nombre : null}
                      required
                      id="nombre"
                      label="Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarEnfermero({
                          ...enfermero,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={enfermero ? enfermero.domicilio : null}
                      id="domicilio"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarEnfermero({
                          ...enfermero,
                          domicilio: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      value={enfermero ? enfermero.telefono : null}
                      id="telefono"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarEnfermero({
                          ...enfermero,
                          telefono: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <TextField
                      value={enfermero ? enfermero.email : null}
                      id="email"
                      label="Email"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarEnfermero({
                          ...enfermero,
                          email: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <TextField
                      value={enfermero ? enfermero.numeroDocumento : null}
                      id="numeroDocumento"
                      label="D.N.I."
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarEnfermero({
                          ...enfermero,
                          numeroDocumento: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      value={enfermero ? enfermero.cuit : null}
                      id="cuit"
                      label="C.U.I.T./C.U.I.L."
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarEnfermero({
                          ...enfermero,
                          cuit: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={enfermero ? enfermero.valorGuardia : null}
                      id="valorGuardia"
                      label="Valor Guardia"
                      fullWidth
                      variant="standard"
                      type="number"
                      onChange={(e) =>
                        guardarEnfermero({
                          ...enfermero,
                          valorGuardia: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={enfermero ? enfermero.nocturno : null}
                      id="nocturno"
                      label="% Nocturno"
                      fullWidth
                      variant="standard"
                      type="number"
                      error={
                        enfermero &&
                        enfermero.nocturno !== null &&
                        enfermero.nocturno !== "" &&
                        (enfermero.nocturno < 0 || enfermero.nocturno > 100)
                      }
                      helperText={
                        enfermero &&
                        enfermero.nocturno !== null &&
                        enfermero.nocturno !== "" &&
                        (enfermero.nocturno < 0 || enfermero.nocturno > 100)
                          ? "Error "
                          : " "
                      }
                      onChange={(e) =>
                        guardarEnfermero({
                          ...enfermero,
                          nocturno: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={enfermero ? enfermero.sadofe : null}
                      id="sadofe"
                      label="% Sab. Dom. Fer."
                      fullWidth
                      variant="standard"
                      type="number"
                      error={
                        enfermero &&
                        enfermero.sadofe !== null &&
                        enfermero.sadofe !== "" &&
                        (enfermero.sadofe < 0 || enfermero.sadofe > 100)
                      }
                      helperText={
                        enfermero &&
                        enfermero.sadofe !== null &&
                        enfermero.sadofe !== "" &&
                        (enfermero.sadofe < 0 || enfermero.sadofe > 100)
                          ? "Error "
                          : " "
                      }
                      onChange={(e) =>
                        guardarEnfermero({
                          ...enfermero,
                          sadofe: e.target.value,
                        })
                      }
                    />
                  </Grid>


                  <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={enfermero ? enfermero.habilitado : null}
                          style={{ marginLeft: "1em" }}
                          onChange={(e) =>
                            guardarEnfermero({
                              ...enfermero,
                              habilitado: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Habilitado"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
