import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { NumericFormat } from "react-number-format";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import CierreEmergencias from "./CierreEmergencias";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";

export default function ConsumoMedicamentos(props) {
  //const classes = useStyles();
  const serviciosContext = useContext(ServiciosContext);
  const {
    afiliado,
    servicioSeleccionado,
    tripulaciones,
    cierreMedicamentos,
    chipData,
    guardarCierreMedicamentos,
    setChipData,
  } = serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    medicamentos,
    almacenMovil,
    stocksMedicamentos,
    obtenerStocksMedicamentos,
  } = configuracionContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    if (
      servicioSeleccionado.almacenId !== undefined &&
      servicioSeleccionado.almacenId !== null
    ) {
      obtenerStocksMedicamentos({ almacen: servicioSeleccionado.almacenId });
    }
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Leer tecla y foco

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "cantidad":
          campo = document.getElementById("medicamentos");
          campo.focus();
          break;
        case "coseguroAbonar":
          campo = document.getElementById("coseguroAbonado");
          campo.focus();
          break;
        case "google-map":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("sexo");
          campo.focus();
          break;
        case "sexo":
          campo = document.getElementById("antecedente");
          campo.focus();
          break;
        case "antecedente":
          campo = document.getElementById("tratamiento");
          campo.focus();
          break;
        case "tratamiento":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "indicativoEmisor":
          if (
            afiliado.grupoId !== 5 &&
            afiliado.grupoId !== 7 &&
            afiliado.grupoId !== 8
          ) {
            // Convenios-Ferrocarriles-Same
            campo = document.getElementById("covid");
            campo.focus();
            break;
          } else {
            campo = document.getElementById("obraSocial");
            campo.focus();
            break;
          }
        case "obraSocial":
          campo = document.getElementById("covid");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "detalleDiagnostico":
          campo = document.getElementById("diagnostico");
          campo.focus();
          break;
        case "coseguroAbonar":
          campo = document.getElementById("detalleDiagnostico");
          campo.focus();
          break;
        case "coseguroAbonado":
          campo = document.getElementById("coseguroAbonar");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("google-map");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "sexo":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "antecedente":
          campo = document.getElementById("sexo");
          campo.focus();
          break;
        case "tratamiento":
          campo = document.getElementById("antecedente");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("tratamiento");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "indicativoEmisor":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "obraSocial":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "covid":
          if (
            afiliado.grupoId !== 5 &&
            afiliado.grupoId !== 7 &&
            afiliado.grupoId !== 8
          ) {
            campo = document.getElementById("indicativoEmisor");
            campo.focus();
            break;
          } else {
            campo = document.getElementById("obraSocial");
            campo.focus();
            break;
          }
      }
    }
  }

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const agregarChip = () => {
    for (var i = 0; i < chipData.length; i++) {
      if (chipData[i].key === cierreMedicamentos.medicamentoSku) {
        return;
      }
    }
    setChipData((chipData) => [
      ...chipData,
      {
        key: cierreMedicamentos.medicamentoSku,
        cantidad: cierreMedicamentos.cantidad,
        label:
          cierreMedicamentos.medicamentoNombre +
          " | " +
          cierreMedicamentos.cantidad,
      },
    ]);
  };

  //console.log(stocksMedicamentos)

  return (
    <Fragment>
      <Grid container spacing={0} style={{ padding: "0" }}>
        <Grid item xs={6} md={3}>
          <TextField
            id="cantidad"
            label="Cantidad"
            defaultValue={1}
            type="number"
            fullWidth
            variant="standard"
            style={{ marginTop: ".25em" }}
            size="small"
            required
            onKeyDown={handleEnter}
            //decimalSeparator="."
            //prefix="$"
            onChange={(e) => {
              guardarCierreMedicamentos({
                ...cierreMedicamentos,
                cantidad: e.target.value,
              });
            }}
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <Autocomplete
            id="medicamentos"
            fullWidth
            required
            disabled={!stocksMedicamentos}
            noOptionsText={"No hay opciones"}
            options={stocksMedicamentos}
            disablePortal
            //renderOption={(option) => {option.nombre}}
            getOptionLabel={(option) => option.nombre}
            renderInput={(params) => {
              return (
                <TextField {...params} label="Medicamento" variant="standard" />
              );
            }}
            style={{ marginLeft: ".5em", marginTop: ".28em" }}
            onKeyDown={handleEnter}
            onChange={(event, value) => {
              if (value) {
                guardarCierreMedicamentos({
                  ...cierreMedicamentos,
                  medicamentoId: value.id,
                  medicamentoNombre: value.nombre,
                  medicamentoSku: value.sku,
                });
              } else {
                guardarCierreMedicamentos({
                  ...cierreMedicamentos,
                  medicamentoId: null,
                  medicamentoNombre: null,
                  medicamentoSku: null,
                });
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            id="agregar"
            variant="contained"
            color="primary"
            style={{ marginTop: "1em" }}
            disabled={
              cierreMedicamentos &&
              cierreMedicamentos.cantidad > 0 &&
              cierreMedicamentos.medicamentoSku !== undefined &&
              cierreMedicamentos.medicamentoSku !== null
                ? false
                : true
            }
            onClick={agregarChip}
          >
            Agregar
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper component="ul">
            {chipData.map((data) => {
              let icon;

              return (
                <li key={data.key}>
                  <Chip
                    size="small"
                    color="secondary"
                    icon={icon}
                    label={data.label}
                    onDelete={handleDelete(data)}
                  />
                </li>
              );
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}></Grid>
      </Grid>
    </Fragment>
  );
}
