import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { AlertContext } from "../../../context/AlertContext";
import { Link, Redirect } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { AuthContext } from "../../../context/AuthContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import Barra from "../Barra";


const PREFIX = "MyCard";
const classes = {
  root: `${PREFIX}-root`,
  layout: `${PREFIX}-layout`,
  paper: `${PREFIX}-paper`,
  DarkerDisabledTextField: `${PREFIX}-DarkerDisabledTextField`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: "auto",
    width: "40%",
  },
  [`&.${classes.layout}`]: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  [`&.${classes.DarkerDisabledTextField}`]: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
    },
  },
}));

export default function NumeroInterno(props) {
  //const classes = useStyles();

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    configuracion,
    obtenerAcceso,
    actualizarConfiguracion,
    obtenerConfiguracion,
    guardarConfiguracion,
  } = configuracionContext;

  const toast = useRef(null);
  let navigate = useNavigate();

  useEffect(() => {
    usuarioAutenticado();
  }, [state.id]);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "NumeroInterno" });
    }
    obtenerConfiguracion();
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const aceptarClick = (e) => {
    e.preventDefault();

    // extraer de usuario
    const {
      edadPediatrico,
      diurnoDesde,
      diurnoHasta,
      nocturnoDesde,
      nocturnoHasta,
    } = configuracion;

    //Validar
    if (edadPediatrico === 0) {
      mensajeAlerta("Parámetros", "edad es necesario !.", "error");
      return;
    }
    if (diurnoDesde === "") {
      mensajeAlerta("Parámetros", "Diurno desde es necesario !.", "error");
      return;
    }
    if (diurnoHasta === "") {
      mensajeAlerta("Parámetros", "Diurno hasta es necesario !.", "error");
      return;
    }

    actualizarConfiguracion(configuracion);
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  //console.log(configuracion)

  return (
    <Root className={classes.root}>
      <Barra />
      <Toast ref={toast} />
      <Fragment>
        {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
        {statusAcceso === 200 ? (
          <Fragment>
            <CssBaseline />
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <DialogTitle id="id">
                  <Box display="flex" alignItems="center" margin-top="0.1em">
                    <Box flexGrow={1}>{"Parámetros del sistema"}</Box>
                    <Box>
                      <IconButton component={Link} to="/">
                        <CloseIcon style={{ color: "#3F51B5" }} />
                      </IconButton>
                    </Box>
                  </Box>
                </DialogTitle>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      id="edadPediatrico"
                      name="edadPediatrico"
                      label="Edad Pediátrico Hasta "
                      fullWidth
                      autoComplete="given-name"
                      variant="standard"
                      value={configuracion.edadPediatrico}
                      onChange={(e) =>
                        guardarConfiguracion({
                          ...configuracion,
                          edadPediatrico: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="diurnoDesde"
                      label="Servicio diurno desde"
                      type="time"
                      value={configuracion.diurnoDesde}
                      fullWidth
                      variant="standard"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      onChange={(e) =>
                        guardarConfiguracion({
                          ...configuracion,
                          diurnoDesde: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="diurnoHasta"
                      label="Servicio diurno hasta"
                      type="time"
                      value={configuracion.diurnoHasta}
                      fullWidth
                      variant="standard"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      onChange={(e) =>
                        guardarConfiguracion({
                          ...configuracion,
                          diurnoHasta: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="nocturnoDesde"
                      label="Servicio nocturno desde"
                      type="time"
                      value={configuracion.diurnoHasta}
                      className={classes.DarkerDisabledTextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      variant="standard"
                      disabled
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="nocturnoHasta"
                      label="Servicio nocturno hasta"
                      type="time"
                      defaultValue={configuracion.diurnoDesde}
                      className={classes.DarkerDisabledTextField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                      fullWidth
                      variant="standard"
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          id="farmacia"
                          color="primary"
                          checked={
                            configuracion ? configuracion.medicamentos : false
                          }
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarConfiguracion({
                              ...configuracion,
                              medicamentos: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Utilizar carga de medicamentos en cierre del servicio"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          id="location"
                          color="primary"
                          checked={
                            configuracion ? configuracion.location : false
                          }
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarConfiguracion({
                              ...configuracion,
                              location: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Habilitar geolocalización en celulares de móviles."
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          id="hc"
                          color="primary"
                          checked={configuracion ? configuracion.hc : false}
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarConfiguracion({
                              ...configuracion,
                              hc: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Habilitar carga de H.C. en dispositivos móviles."
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          id="alertas"
                          color="primary"
                          checked={
                            configuracion ? configuracion.alertas : false
                          }
                          //onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarConfiguracion({
                              ...configuracion,
                              alertas: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Habilitar alertas en pantalla despachos."
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}></Grid>

                  <Grid item xs={6} md={6}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: "green" }}
                      onClick={aceptarClick}
                    >
                      Aceptar
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: "#D8261C" }}
                      onClick={exitClick}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </main>
          </Fragment>
        ) : null}
      </Fragment>
    </Root>
  );
}
