import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, Redirect } from "react-router-dom";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { AuthContext } from "../../../context/AuthContext";
import { Button } from "primereact/button";
import Spinner from "../Spinner";
import { ToggleButton } from "primereact/togglebutton";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";


export default function ResumenMoviles(props) {
  //const classes = useStyles();
  const loaded = useRef(false);
  const [loop, setLoop] = useState(null);
  const [band, setBand] = useState(false);
  const [band2, setBand2] = useState(false);
  const [tripula, setTripula] = useState([]);
  const [derivado, setDerivado] = useState([]);
  const [checked, setChecked] = useState(true);
  const [distancia, setDistancia] = useState(0);
  const [bandStart, setBandStart] = useState(false);

  const serviciosContext = useContext(ServiciosContext);
  const {
    tripulaciones,
    despachos,
    selectedRow,
    servicio,
    refrescar,
    movilMarcado,
    servicioSeleccionado,
    movilesDerivados,
    obtenerTripulaciones,
    obtenerServiciosPendientes,
    asignarMovil,
    asignarMovilDerivado,
    guardarServicioSeleccionado,
    setOpenDrawer,
    setMovilMarcado,
    setRefrescar,
    obtenerMovilesDerivados,
    guardarMovilesDerivados,
    setOpenMp,
    setBandMp,
  } = serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    colores,
    obtenerColores,
    zonasMovilesDerivados,
    obtenerZonasMovilesDerivados,
  } = configuracionContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const options = ["Propios", "Derivados"];
  const toastBC = useRef(null);

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    obtenerTripulaciones({ estado: false });
    obtenerMovilesDerivados();
    obtenerServiciosPendientes();
    obtenerZonasMovilesDerivados();
    obtenerColores();
    setBand(true);
    setBand2(true);
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      obtenerTripulaciones({ estado: false });
      obtenerMovilesDerivados();
      obtenerServiciosPendientes();
      obtenerZonasMovilesDerivados();
      obtenerColores();
      setBand(true);
      setBand2(true);
    }, 30000);

    return () => clearTimeout(interval);
  }, []);

  /*
  if (!bandStart) {
    setTimeout(() => {
      handleStart();
    }, 30000);
    setBandStart(true);
  }
  */

  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === "refrescar") {
      setTimeout(() => {
        setRefrescar(newValue);
      }, 2000);
    }
  };

  /*
  const handleStart = () => {
    let interval = setInterval(() => {
      //obtenerTripulaciones({ estado: false });
      //console.log('handle')
      obtenerTripulaciones({ estado: false });
      obtenerMovilesDerivados();
      obtenerServiciosPendientes();
      obtenerZonasMovilesDerivados();
      //obtenerColores();
      setBand(true);
      //setBand2(true);
    }, 30000);
    setLoop(interval);
  };
  */

  const handleReset = () => {
    clearInterval(loop);
  };

  if (!loop) {
    //handleStart();
  }

  //const theme = useTheme();

  // Propios
  if (band === true && tripulaciones && despachos && checked) {
    let trip = [];
    for (var i = 0; i < tripulaciones.length; i++) {
      if (tripulaciones[i].activo) {
        trip.push({
          id: tripulaciones[i].id,
          movilId: tripulaciones[i].movilId,
          movil: tripulaciones[i].movil,
          detalle: tripulaciones[i].detalle,
          color: "",
          medicoEstado: tripulaciones[i].medicoEstado,
          choferEstado: tripulaciones[i].choferEstado,
          enfermeroEstado: tripulaciones[i].enfermeroEstado,
          lat: tripulaciones[i].lat,
          lon: tripulaciones[i].lon,
          fechaGps: tripulaciones[i].fechaGps,
          estado: "",
          distancia: "",
          indicativo: "",
          medico: tripulaciones[i].medico,
          chofer: tripulaciones[i].chofer,
          enfermero: tripulaciones[i].enfermero,
          telefonoId: tripulaciones[i].telefonoId,
          cantidadServiciosPendientes:
            tripulaciones[i].cantidadServiciosPendientes,
          cantidadServiciosFinalizados:
            tripulaciones[i].cantidadServiciosFinalizados,
          /*
          distancia: servicioSeleccionado
            ? distanciaKm(
                Number(zonasMovilesDerivados[i].lat),
                Number(zonasMovilesDerivados[i].lon),
                Number(servicioSeleccionado.lat),
                Number(servicioSeleccionado.lon)
              )
            : null,
          */
        });
      }

      for (var j = 0; j < despachos.length; j++) {
        if (tripulaciones[i].servicioId === despachos[j].id) {
          trip[i] = {
            id: tripulaciones[i].id,
            movilId: tripulaciones[i].movilId,
            movil: tripulaciones[i].movil,
            detalle: tripulaciones[i].detalle,
            color: despachos[j].color,
            medicoEstado: tripulaciones[i].medicoEstado,
            choferEstado: tripulaciones[i].choferEstado,
            enfermeroEstado: tripulaciones[i].enfermeroEstado,
            lat: tripulaciones[i].lat,
            lon: tripulaciones[i].lon,
            fechaGps: tripulaciones[i].fechaGps,
            estado: despachos[j].estado,
            indicativo: despachos[j].id,
            medico: tripulaciones[i].medico,
            chofer: tripulaciones[i].chofer,
            enfermero: tripulaciones[i].enfermero,
            telefonoId: tripulaciones[i].telefonoId,
            cantidadServiciosPendientes:
              tripulaciones[i].cantidadServiciosPendientes,
            cantidadServiciosFinalizados:
              tripulaciones[i].cantidadServiciosFinalizados,
          };
        }
      }
    }

    if (trip.length > 0) {
      trip.sort(function (a, b) {
        return a.movilId - b.movilId;
      });
      setTripula(trip);
    } else {
      console.log("No hay datos");
    }
    setBand(false);
  }

  //Derivados
  if (band2 === true && movilesDerivados && despachos && !checked) {
    let zona = [];
    if (zonasMovilesDerivados.length > 0 && servicioSeleccionado) {
      for (var i = 0; i < zonasMovilesDerivados.length; i++) {
        zona.push({
          id: zonasMovilesDerivados[i].id,
          movil: zonasMovilesDerivados[i].movilId,
          lat: zonasMovilesDerivados[i].lat,
          lon: zonasMovilesDerivados[i].lon,
          distancia: distanciaKm(
            Number(zonasMovilesDerivados[i].lat),
            Number(zonasMovilesDerivados[i].lon),
            Number(servicioSeleccionado.lat),
            Number(servicioSeleccionado.lon)
          ),
        });
      }

      zona.sort(function (a, b) {
        return a.distancia - b.distancia;
      });
    } else {
      //console.log("No hay datos");
    }

    let der = [];

    for (var i = 0; i < movilesDerivados.length; i++) {
      der.push({
        id: movilesDerivados[i].id,
        movil: movilesDerivados[i].id,
        detalle: movilesDerivados[i].detalle,
        nombre: movilesDerivados[i].nombre,
        color: "",
        estado: "",
        distancia: 999999,
        indicativo: "",
        derivadoServicioId: movilesDerivados[i].derivadoServicioId,
      });
    }

    for (var i = 0; i < der.length; i++) {
      for (var j = 0; j < zona.length; j++) {
        if (der[i].movil === zona[j].movil && der[i].distancia === 999999) {
          der[i].distancia = zona[j].distancia;
        }
      }
    }

    for (var i = 0; i < der.length; i++) {
      for (var j = 0; j < despachos.length; j++) {
        if (der[i].derivadoServicioId === despachos[j].id) {
          der[i].color = despachos[j].color;
          der[i].estado = despachos[j].estado;
          der[i].indicativo = despachos[j].id;
        }
      }
    }

    der.sort(function (a, b) {
      return a.distancia - b.distancia;
    });

    setDerivado(der);
    setBand2(false);
  }

  function distanciaKm(lat1, lon1, lat2, lon2) {
    if (lat1 === null || lon1 === null || lat2 === null || lon2 === null) {
      return 0;
    }
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres

    //console.log(d/1000)
    return parseFloat(d / 1000).toFixed(2);
  }

  const movilSeleccionado = (elem) => {
    if (movilMarcado === elem.movilId) {
      setMovilMarcado(null);
      var element = document.getElementById("hiddenButton");
      element.focus();
    } else {
      setMovilMarcado(elem.movilId);
    }

    if (elem.movilId < 1000) {
      if (
        servicioSeleccionado !== null &&
        servicioSeleccionado.movilId === null
      ) {
        if (
          colores &&
          colores.length > 0 &&
          servicioSeleccionado.color !== "" &&
          ((colores.find((x) => x.nombre === servicioSeleccionado.color)
            .medico &&
            elem.medicoEstado !== "INGRESO") ||
            (colores.find((x) => x.nombre === servicioSeleccionado.color)
              .chofer &&
              elem.choferEstado !== "INGRESO") ||
            (colores.find((x) => x.nombre === servicioSeleccionado.color)
              .enfermero &&
              elem.enfermeroEstado !== "INGRESO"))
        ) {
          toastBC.current.show({
            severity: "warn",
            summary: "Tripulación",
            detail: `La tripulación no cumple los requisitos para asignarle el servicio.`,
            life: 5000,
          });
          return;
        }

        let simultaneo = colores.find(
          (item) => item.nombre === servicioSeleccionado.color
        ).simultaneo;

        if (!simultaneo && elem.color !== "") {
          toastBC.current.show({
            severity: "warn",
            summary: "Simultáneo",
            detail: `El color ${servicioSeleccionado.color} no acepta servicios simultáneos. El móvil ${elem.movilId} ya esta asistiendo un servicio.`,
            life: 5000,
          });
          return;
        }

        let bandSimultaneo = true;

        for (var i = 0; i < despachos.length; i++) {
          if (despachos[i].tripulacionId === elem.id) {
            let simultaneo = colores.find(
              (x) => x.nombre === despachos[i].color
            ).simultaneo;
            if (!simultaneo) {
              bandSimultaneo = false;
              break;
            }
          }
        }

        if (!bandSimultaneo) {
          toastBC.current.show({
            severity: "warn",
            summary: "Simultáneo",
            detail: `El móvil ${elem.movilId} esta asistiendo un servicio que no acepta simultáneos.`,
            life: 5000,
          });
          return;
        }
        //console.log(e);
        toastBC.current.show({
          severity: "warn",
          sticky: true,
          content: (
            <div
              className="p-flex p-flex-column"
              style={{ flex: "1", marginLeft: "1em" }}
            >
              <div className="p-text-center">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "3rem" }}
                ></i>
                <h4>{`Esta seguro de asignar el móvil ${elem.movilId} al indicativo ${servicioSeleccionado.id} ?`}</h4>
                <p>{`Tripulacion : ${elem.medico} - ${elem.chofer} - ${elem.enfermero}`}</p>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="Si"
                    className="p-button-success"
                    onClick={(e) =>
                      opcionSiPasado(
                        elem.id,
                        servicioSeleccionado.id,
                        elem.movilId,
                        servicioSeleccionado.color,
                        servicioSeleccionado.grupo
                      )
                    }
                  />
                </div>
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="No"
                    className="p-button-danger"
                    style={{ marginTop: "1em" }}
                    onClick={opcionNo}
                  />
                </div>
              </div>
            </div>
          ),
        });
      } else {
        toastBC.current.show({
          severity: "info",
          summary: "Info Tripulación",
          detail: `Médico: ${elem.medico} Chofer: ${elem.chofer} Enfermero: ${elem.enfermero}`,
          life: 3000,
        });
      }
    }

    if (elem.movil >= 1000) {
      //console.log(servicioSeleccionado)

      if (
        servicioSeleccionado !== null &&
        servicioSeleccionado.movilId === null
      ) {
        toastBC.current.show({
          severity: "warn",
          sticky: true,
          content: (
            <div
              className="p-flex p-flex-column"
              style={{ flex: "1", marginLeft: "1em" }}
            >
              <div className="p-text-center">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "3rem" }}
                ></i>
                <h4>{`Esta seguro de asignar el móvil ${elem.movil} al indicativo ${servicioSeleccionado.id} ?`}</h4>
                <p>{`Móvil : ${elem.nombre} - ${elem.detalle}`}</p>
              </div>
              <div className="p-grid p-fluid">
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="Si"
                    className="p-button-success"
                    onClick={(e) =>
                      opcionSiPasado(
                        elem.id,
                        servicioSeleccionado.id,
                        elem.movil,
                        servicioSeleccionado.color,
                        servicioSeleccionado.grupo,
                        elem.nombre,
                      )
                    }
                  />
                </div>
                <div className="p-col-6">
                  <Button
                    type="button"
                    label="No"
                    className="p-button-danger"
                    style={{ marginTop: "1em" }}
                    onClick={opcionNo}
                  />
                </div>
              </div>
            </div>
          ),
        });
      } else {
        let zonas = [];
        zonas = zonasMovilesDerivados.filter(
          (item) => item.movilId === elem.movilId
        );
        let detail = "Zonas de cobertura ";
        for (var i = 0; i < zonas.length; i++) {
          detail = detail + zonas[i].localidad + " ";
        }
        toastBC.current.show({
          severity: "info",
          summary: "Móvil Derivado",
          detail: detail,
          life: 5000,
        });
      }
    }
  };

  const opcionSiPasado = (tripulacion, servicio, movil, color, grupo, detalleDerivado) => {

    if (movil < 1000) {
      asignarMovil({
        tripulacionId: tripulacion,
        movil,
        color,
        grupo,
        servicioId: servicio,
        usuario: state.usuario.usuario,
        indicativoEmisor: servicioSeleccionado.indicativoEmisor,
        titularId: servicioSeleccionado.titularId,
      });
    } else {
      asignarMovilDerivado({
        movil,
        color,
        servicioId: servicio,
        usuario: state.usuario.usuario,
        indicativoEmisor: servicioSeleccionado.indicativoEmisor,
        titularId: servicioSeleccionado.titularId,
        detalleDerivado,
      });
    }
    toastBC.current.clear();
    guardarServicioSeleccionado(null);
    setOpenDrawer(false);

    if (servicioSeleccionado.linkMp) {
      setOpenMp(true);
    }
  };

  const opcionSiSalida = (servicio) => {
    //console.log(servicio);
  };

  function opcionNo() {
    toastBC.current.clear();
  }


  //console.log(tripula)

  return (
    <Fragment>
      {colores &&
      colores.length > 0 &&
      ((tripula && tripula.length > 0) || (derivado && derivado.length > 0)) ? (
        <Grid
          container
          spacing={0}
          style={{
            marginTop: "-6em",
            marginLeft: "1em",
            paddingLeft: "0em",
            alignItems: "normal",
          }}
        >
          <Toast ref={toastBC} position="top-right" />
          <Button
            id="hiddenButton"
            style={{ backgroundColor: "white", opacity: 0, height: "0.1vw" }}
            className="p-button-rounded"
          />
          <Grid
            item
            xs={12}
            md={12}
            style={{ marginTop: "6em", marginLeft: "3em" }}
          >
            <ToggleButton
              onLabel="Propios"
              offLabel="Derivados"
              onIcon="pi pi-arrow-circle-down"
              offIcon="pi pi-arrow-circle-up"
              checked={checked}
              onChange={(e) => setChecked(e.value)}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
            <h5>Liberados</h5>
          </Grid>

          {checked && tripula ? (
            <Fragment>
              {tripula.map((element) => {
                let totalServicios =
                  Number(element.cantidadServiciosFinalizados) +
                  Number(element.cantidadServiciosPendientes);

                if (element.color === "") {
                  return (
                    <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                      <Button
                        label={
                          element.detalle.trim() != ""
                            ? element.detalle
                            : element.movilId +
                              " ( " +
                              Number(totalServicios) +
                              " )"
                        }
                        icon={
                          element.medicoEstado === "PENDIENTE" &&
                          element.choferEstado === "PENDIENTE" &&
                          element.enfermeroEstado === "PENDIENTE"
                            ? "pi pi-info-circle"
                            : null
                        }
                        className="p-button-rounded p-button-outlined p-button-sm"
                        disabled={
                          servicioSeleccionado &&
                          colores &&
                          colores.length > 0 &&
                          ((colores.find(
                            (x) => x.nombre === servicioSeleccionado.color
                          ).medico === "SI" &&
                            element.medicoEstado === "PENDIENTE") ||
                            (colores.find(
                              (x) => x.nombre === servicioSeleccionado.color
                            ).chofer === "SI" &&
                              element.choferEstado === "PENDIENTE") ||
                            (colores.find(
                              (x) => x.nombre === servicioSeleccionado.color
                            ).enfermero === "SI" &&
                              element.enfermeroEstado === "PENDIENTE"))
                        }
                        style={{
                          backgroundColor: "brown",
                          color: "white",
                        }}
                        onClick={(e) => movilSeleccionado(element)}
                        /*
                  tooltip={
                    servicioSeleccionado &&
                    colores &&
                    ((colores.find(
                        (x) => x.nombre === servicioSeleccionado.color
                      ).medico === "SI" &&
                        element.medicoEstado === "PENDIENTE") ||
                      (colores.find(
                        (x) => x.nombre === servicioSeleccionado.color
                      ).chofer === "SI" &&
                        element.choferEstado === "PENDIENTE") ||
                      (colores.find(
                        (x) => x.nombre === servicioSeleccionado.color
                      ).enfermero === "SI" &&
                        element.enfermeroEstado === "PENDIENTE"))
                      ? "Tripulación incompleta"
                      : null
                  }
                  tooltipOptions={{
                    position: "right",
                    mouseTrack: true,
                    mouseTrackTop: 15,
                    className: classes.tooltip,
                  }}
                  */
                      />
                      <Badge
                        value={
                          servicioSeleccionado !== null &&
                          element.lat !== null &&
                          element.lon !== null &&
                          servicioSeleccionado.lat !== null &&
                          servicioSeleccionado.lon !== null
                            ? distanciaKm(
                                Number(element.lat),
                                Number(element.lon),
                                Number(servicioSeleccionado.lat),
                                Number(servicioSeleccionado.lon)
                              ) + " Km"
                            : null
                        }
                      ></Badge>
                    </Grid>
                  );
                }
              })}

              <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                <h5>Pasados</h5>
              </Grid>
              {tripula.map((element) => {
                let totalServicios =
                  Number(element.cantidadServiciosFinalizados) +
                  Number(element.cantidadServiciosPendientes);

                if (
                  element.color !== "" &&
                  element.estado.substring(0, 6) === "PASADO"
                ) {
                  return (
                    <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                      <Button
                        label={
                          element.detalle.trim() != ""
                            ? element.detalle
                            : element.movilId +
                              " ( " +
                              Number(totalServicios) +
                              " )"
                        }
                        className="p-button-rounded p-button-outlined p-button-sm"
                        style={{
                          backgroundColor:
                            element.color === "ROJO"
                              ? "red"
                              : element.color === "AMARILLO"
                              ? "yellow"
                              : element.color === "VERDE"
                              ? "green"
                              : element.color === "AZUL"
                              ? "blue"
                              : element.color === "CELESTE"
                              ? "aqua"
                              : element.color === "NARANJA"
                              ? "orange"
                              : element.color === "FUCCIA"
                              ? "fuchsia"
                              : element.color === "BLANCO"
                              ? "white"
                              : element.color === "NEGRO"
                              ? "black"
                              : null,
                          color:
                            element.color === "ROJO" ||
                            element.color === "VERDE" ||
                            element.color === "AZUL" ||
                            element.color === "NEGRO"
                              ? "white"
                              : "black",
                        }}
                        onClick={(e) => movilSeleccionado(element)}
                      />
                      {/*console.log(servicioSeleccionado)*/}
                      <Badge
                        value={
                          servicioSeleccionado !== null &&
                          element.lat !== null &&
                          element.lon !== null &&
                          servicioSeleccionado.lat !== null &&
                          servicioSeleccionado.lon !== null
                            ? distanciaKm(
                                Number(element.lat),
                                Number(element.lon),
                                Number(servicioSeleccionado.lat),
                                Number(servicioSeleccionado.lon)
                              ) + " Km"
                            : null
                        }
                      ></Badge>
                      <Badge
                        value={"Ind. " + element.indicativo}
                        severity="success"
                      ></Badge>
                    </Grid>
                  );
                }
              })}

              <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                <h5>Salida</h5>
              </Grid>
              {tripula.map((element) => {
                let totalServicios =
                  Number(element.cantidadServiciosFinalizados) +
                  Number(element.cantidadServiciosPendientes);

                if (
                  element.color !== "" &&
                  element.estado.substring(0, 6) === "SALIDA"
                ) {
                  return (
                    <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                      <Button
                        label={
                          element.detalle.trim() != ""
                            ? element.detalle
                            : element.movilId +
                              " ( " +
                              Number(totalServicios) +
                              " )"
                        }
                        className="p-button-rounded p-button-outlined p-button-sm"
                        style={{
                          backgroundColor:
                            element.color === "ROJO"
                              ? "red"
                              : element.color === "AMARILLO"
                              ? "yellow"
                              : element.color === "VERDE"
                              ? "green"
                              : element.color === "AZUL"
                              ? "blue"
                              : element.color === "CELESTE"
                              ? "aqua"
                              : element.color === "NARANJA"
                              ? "orange"
                              : element.color === "FUCCIA"
                              ? "fuchsia"
                              : element.color === "BLANCO"
                              ? "white"
                              : element.color === "NEGRO"
                              ? "black"
                              : null,
                          color:
                            element.color === "ROJO" ||
                            element.color === "VERDE" ||
                            element.color === "AZUL" ||
                            element.color === "NEGRO"
                              ? "white"
                              : "black",
                        }}
                        onClick={(e) => movilSeleccionado(element)}
                      />
                      <Badge
                        value={
                          servicioSeleccionado !== null &&
                          element.lat !== null &&
                          element.lon !== null &&
                          servicioSeleccionado.lat !== null &&
                          servicioSeleccionado.lon !== null
                            ? distanciaKm(
                                Number(element.lat),
                                Number(element.lon),
                                Number(servicioSeleccionado.lat),
                                Number(servicioSeleccionado.lon)
                              ) + " Km"
                            : null
                        }
                      ></Badge>
                      <Badge
                        value={"Ind. " + element.indicativo}
                        severity="success"
                      ></Badge>
                    </Grid>
                  );
                }
              })}

              <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                <h5>Llegada</h5>
              </Grid>
              {tripula.map((element) => {
                let totalServicios =
                  Number(element.cantidadServiciosFinalizados) +
                  Number(element.cantidadServiciosPendientes);

                if (element.color !== "" && element.estado === "LLEGADA") {
                  return (
                    <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                      <Button
                        label={
                          element.detalle.trim() != ""
                            ? element.detalle
                            : element.movilId +
                              " ( " +
                              Number(totalServicios) +
                              " )"
                        }
                        className="p-button-rounded p-button-outlined p-button-sm"
                        style={{
                          backgroundColor:
                            element.color === "ROJO"
                              ? "red"
                              : element.color === "AMARILLO"
                              ? "yellow"
                              : element.color === "VERDE"
                              ? "green"
                              : element.color === "AZUL"
                              ? "blue"
                              : element.color === "CELESTE"
                              ? "aqua"
                              : element.color === "NARANJA"
                              ? "orange"
                              : element.color === "FUCCIA"
                              ? "fuchsia"
                              : element.color === "BLANCO"
                              ? "white"
                              : element.color === "NEGRO"
                              ? "black"
                              : null,
                          color:
                            element.color === "ROJO" ||
                            element.color === "VERDE" ||
                            element.color === "AZUL" ||
                            element.color === "NEGRO"
                              ? "white"
                              : "black",
                        }}
                        onClick={(e) => movilSeleccionado(element)}
                      />
                      <Badge
                        value={
                          servicioSeleccionado !== null &&
                          element.lat !== null &&
                          element.lon !== null &&
                          servicioSeleccionado.lat !== null &&
                          servicioSeleccionado.lon !== null
                            ? distanciaKm(
                                Number(element.lat),
                                Number(element.lon),
                                Number(servicioSeleccionado.lat),
                                Number(servicioSeleccionado.lon)
                              ) + " Km"
                            : null
                        }
                      ></Badge>
                      <Badge
                        value={"Ind. " + element.indicativo}
                        severity="success"
                      ></Badge>
                    </Grid>
                  );
                }
              })}
            </Fragment>
          ) : null}

          {!checked && derivado ? (
            <Fragment>
              {derivado.map((element) => {
                if (
                  element.color !== "" &&
                  element.estado.substring(0, 6) === "PASADO"
                ) {
                  return (
                    <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                      <Button
                        label={
                          element.nombre
                            ? element.movil + " " + element.nombre
                            : element.movil
                        }
                        className="p-button-rounded p-button-outlined p-button-sm"
                        style={{
                          backgroundColor:
                            element.color === "ROJO"
                              ? "red"
                              : element.color === "AMARILLO"
                              ? "yellow"
                              : element.color === "VERDE"
                              ? "green"
                              : element.color === "AZUL"
                              ? "blue"
                              : element.color === "CELESTE"
                              ? "aqua"
                              : element.color === "NARANJA"
                              ? "orange"
                              : element.color === "FUCCIA"
                              ? "fuchsia"
                              : element.color === "BLANCO"
                              ? "white"
                              : element.color === "NEGRO"
                              ? "black"
                              : null,
                          color:
                            element.color === "ROJO" ||
                            element.color === "VERDE" ||
                            element.color === "AZUL" ||
                            element.color === "NEGRO"
                              ? "white"
                              : "black",
                        }}
                        onClick={(e) => movilSeleccionado(element)}
                      />
                      <Badge
                        value={"Ind. " + element.indicativo}
                        severity="success"
                      ></Badge>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                      <Button
                        label={
                          element.nombre
                            ? element.movil + " " + element.nombre
                            : element.movil
                        }
                        className="p-button-rounded p-button-outlined p-button-sm"
                        style={{
                          backgroundColor: "brown",
                          color: "white",
                        }}
                        onClick={(e) => movilSeleccionado(element)}
                      />
                    </Grid>
                  );
                }
              })}

              {/*
              <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                <h5>Pasados</h5>
              </Grid>
              {derivado.map((element) => {
                if (
                  element.color !== "" &&
                  element.estado.substring(0, 6) === "PASADO"
                ) {
                  return (
                    <Grid item xs={12} md={12} style={{ marginTop: "0em" }}>
                      <Button
                        label={
                          element.nombre
                            ? element.movil + " " + element.nombre
                            : element.movil
                        }
                        className="p-button-rounded p-button-outlined p-button-sm"
                        style={{
                          backgroundColor:
                            element.color === "ROJO"
                              ? "red"
                              : element.color === "AMARILLO"
                              ? "yellow"
                              : element.color === "VERDE"
                              ? "green"
                              : element.color === "AZUL"
                              ? "blue"
                              : element.color === "CELESTE"
                              ? "aqua"
                              : element.color === "NARANJA"
                              ? "orange"
                              : element.color === "FUCCIA"
                              ? "fuchsia"
                              : element.color === "BLANCO"
                              ? "white"
                              : element.color === "NEGRO"
                              ? "black"
                              : null,
                          color:
                            element.color === "ROJO" ||
                            element.color === "VERDE" ||
                            element.color === "AZUL" ||
                            element.color === "NEGRO"
                              ? "white"
                              : "black",
                        }}
                        onClick={(e) => movilSeleccionado(element)}
                      />
                      <Badge
                        value={"Ind. " + element.indicativo}
                        severity="success"
                      ></Badge>
                    </Grid>
                  );
                }
              })}
            */}
            </Fragment>
          ) : null}

        </Grid>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
}
