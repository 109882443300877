import React, { Fragment, useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "primereact/checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";

export default function AjustesStocksMedicamentos(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [nuevoStock, setNuevoStock] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "SKU", dataKey: "sku" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Almacen", dataKey: "almacen" },
    { title: "Stock Anterior", dataKey: "stockAnterior" },
    { title: "Stock Posterior", dataKey: "stockPosterior" },
    { title: "Usuario", dataKey: "usuario" },
  ];

  const [visible, setVisible] = useState(false);
  let navigate = useNavigate();

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    almacenes,
    almacen,
    ajustesStocksMedicamentos,
    obtenerAjustesStocksMedicamentos,
    obtenerAcceso,
    obtenerAlmacenes,
    guardarAlmacen,
    guardarStocksMedicamentos,
    ajustarStockMedicamento,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "AjustesStocksMedicamentos",
      });
    }
    obtenerAjustesStocksMedicamentos();
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = (e) => {
    const { id } = selectedRow;
    if (id) {
      setVisible(true);
    }
  };

  const exitClick = (e) => {
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(3, 1);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const skuTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">SKU</span>
        {rowData.sku}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const almacenTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Almacen</span>
        {rowData.almacen}
      </Fragment>
    );
  };

  const stockAnteriorTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Stock Anterior</span>
        {rowData.stockAnterior}
      </Fragment>
    );
  };

  const stockPosteriorTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Stock Posterior</span>
        {rowData.stockPosterior}
      </Fragment>
    );
  };

  const usuarioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Usuario</span>
        {rowData.usuario}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado de Ajustes de Medicamentos", 60, 8);
        doc.autoTable(exportColumns, ajustesStocksMedicamentos);
        //doc.save("ajustesstocksdemedicamentos.pdf");
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(ajustesStocksMedicamentos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "ajustesstocksdemedicamentos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons" style={{ marginTop: "0.5em" }}>
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Ajustes de Stock de Medicamentos"></Tag>
      </div>
    </Fragment>
  );

  //console.log(ajustesStocksMedicamentos);

  return (
    <Fragment>
      <Barra />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {ajustesStocksMedicamentos ? (
            <Grid id="dataTableBotones" item xs={12} sm={12} md={12}>
              {/*
              <Button
                icon="pi pi-plus"
                tooltip="Agregar"
                className="p-button-rounded p-button-help"
                style={{ marginLeft: "0em" }}
                onClick={addClick}
              />
              <Button
                icon="pi pi-pencil"
                tooltip="Ajustar"
                className="p-button-rounded p-button-danger"
                style={{ marginLeft: "1em" }}
                onClick={editClick}
              />
              */}
              <Button
                icon="pi pi-times"
                tooltip="Cerrar"
                className="p-button-rounded p-button-primary"
                style={{ marginLeft: "1em" }}
                onClick={exitClick}
              />

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              />
              <div className="datatable-responsive-demo">
                <div className="card">
                  <Tooltip target=".export-buttons>button" position="bottom" />
                  <DataTable
                    value={ajustesStocksMedicamentos}
                    selection={selectedRow}
                    onSelectionChange={(e) => setSelectedRow(e.value)}
                    selectionMode="single"
                    dataKey="id"
                    header={header}
                    className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                    style={{ fontSize: "small" }}
                    globalFilter={globalFilter}
                    emptyMessage="No hay datos."
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    //paginatorLeft={paginatorLeft}
                    //paginatorRight={paginatorRight}
                  >
                    <Column
                      field="id"
                      header="ID"
                      body={idTemplate}
                      sortable
                      //style={{ width: "5%" }}
                    ></Column>
                    <Column
                      field="sku"
                      header="SKU"
                      body={skuTemplate}
                      sortable
                      //style={{ width: "10%" }}
                    ></Column>
                    <Column
                      field="nombre"
                      header="Nombre"
                      body={nombreTemplate}
                      sortable
                      //style={{ width: "25%" }}
                    ></Column>
                    <Column
                      field="almacen"
                      header="Almacen"
                      body={almacenTemplate}
                      sortable
                      //style={{ width: "10%" }}
                      //filter
                      //filterPlaceholder="filtrar por almacen"
                    ></Column>
                    <Column
                      field="stockAnterior"
                      header="Stock Anterior"
                      body={stockAnteriorTemplate}
                      sortable
                      //style={{ width: "7%" }}
                    ></Column>
                    <Column
                      field="stockPosterior"
                      header="Stock Posterior"
                      body={stockPosteriorTemplate}
                      sortable
                      //style={{ width: "7%" }}
                    ></Column>
                    <Column
                      field="usuario"
                      header="Usuario"
                      body={usuarioTemplate}
                      sortable
                      //style={{ width: "7%" }}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </Grid>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
