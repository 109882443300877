import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "../servicios/ConsultaServicio";
import NotasServicio from "../servicios/NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import hcOffcanvasNav from "hc-offcanvas-nav";
import { saveAs } from "file-saver";
import { ToggleButton } from "primereact/togglebutton";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

export default function ListadosLiquidacionEnfermeros(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "ID" },
    { title: "Base", dataKey: "Base" },
    { title: "Móvil", dataKey: "Movil" },
    { title: "Ing. Enf.", dataKey: "FechaIngresoEnfermero" },
    { title: "Sal. Enf.", dataKey: "FechaSalidaEnfermero" },
    { title: "Valor Hora Diurna", dataKey: "ValorHoraDiurna" },
    { title: "valor Hora Nocturna", dataKey: "valorHoraNocturna" },
    { title: "Horas", dataKey: "Horas" },
    { title: "Horas Diurnas", dataKey: "HorasDiurnas" },
    { title: "Importe Diurnas", dataKey: "ImporteDiurnas" },
    { title: "Horas Nocturnas", dataKey: "HorasNocturnas" },
    { title: "Importe Nocturnas", dataKey: "ImporteNocturnas" },
    { title: "Importe Total", dataKey: "ImporteTotal" },
  ];

  const [visibleFechas, setVisibleFechas] = useState(true);
  const [fechas, setFechas] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const toast = useRef(null);
  const [radio, setRadio] = useState("tomado");
  const [tripulanteSeleccionado, setTripulanteSeleccionado] = useState(null);
  const [checked1, setChecked1] = useState(false);
  const [tope, setTope] = useState(14);
  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    colores,
    moviles,
    enfermeros,
    configuracion,
    obtenerAcceso,
    obtenerEnfermeros,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    serviciosFinalizados,
    serviciosFinalizadosExcel,
    obtenerLiquidacionesEnfermeros,
  } = serviciosContext;

  useEffect(() => {
    if (configuracion && configuracion.destinatario !== 2) {
      exitClick();
    }

    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Listados" });
    }
    obtenerEnfermeros({ todos: false });
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const exitClick = (e) => {
    onHideFechas();
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(1, 2);
  };

  const startClick = (e) => {
    setTimeout(() => {
      navigate(`/listadosliquidacionenfermeros`);
      setVisibleFechas(true);
    }, 2000);
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
    setTripulanteSeleccionado(null);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    if (
      !fechas ||
      fechas.desde === undefined ||
      fechas.desde === null ||
      fechas.desde.trim() === ""
    ) {
      mensajeAlerta(
        "Listado de Liquidación de Enfermeros",
        "Fecha desde es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !fechas ||
      fechas.hasta === undefined ||
      fechas.hasta === null ||
      fechas.hasta.trim() === ""
    ) {
      mensajeAlerta(
        "Listado de Liquidación de Enfermeros",
        "Fecha hasta es necesaria !.",
        "error"
      );
      return;
    }

    if (tripulanteSeleccionado === null) {
      mensajeAlerta(
        "Listado de Liquidación de Enfermeros",
        "Tripulante es necesario !.",
        "error"
      );
      return;
    }

    let fdesde = fechas.desde; //+ "T00:00:00";
    let fhasta = fechas.hasta; //+ "T23:59:59";

    var elem;

    await obtenerLiquidacionesEnfermeros({
      fdesde: fdesde,
      fhasta: fhasta,
      tripulanteSeleccionado,
      nocturnoDesde: configuracion.nocturnoDesde,
      nocturnoHasta: configuracion.nocturnoHasta,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHideFechas();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const exportExcel = () => {
    if (serviciosFinalizadosExcel && serviciosFinalizadosExcel.length > 0) {
      for (let i = 0; i < serviciosFinalizadosExcel.length; i++) {
        serviciosFinalizadosExcel[i].ImporteDiurnas = parseFloat(
          serviciosFinalizadosExcel[i].ImporteDiurnas,
          10
        );
        serviciosFinalizadosExcel[i].ImporteNocturnas = parseFloat(
          serviciosFinalizadosExcel[i].ImporteNocturnas,
          10
        );
        serviciosFinalizadosExcel[i].ImporteTotal = parseFloat(
          serviciosFinalizadosExcel[i].ImporteTotal,
          10
        );
      }

      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(serviciosFinalizadosExcel);
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "horariosTripulacion");
      });
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });

    startClick();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default("l");
        doc.setFontSize(12);
        doc.text(
          "Liquidacion de " +
            tripulanteSeleccionado.nombre +
            " desde " +
            formatDate(fechas.desde) +
            " hasta " +
            formatDate(fechas.hasta),
          50,
          8
        );
        doc.autoTable(exportColumns, serviciosFinalizadosExcel, {
          styles: {
            fontSize: 7,
          },
          columnStyles: {
            5: {
              halign: "right",
            },
            6: {
              halign: "right",
            },
            7: {
              halign: "right",
            },
            8: {
              halign: "right",
            },
            9: {
              halign: "right",
            },
            10: {
              halign: "right",
            },
            11: {
              halign: "right",
            },
            12: {
              halign: "right",
            },
          },
          didParseCell: (data) => {
            if (data.cell && data.cell.section === "head") {
              switch (data.cell.raw) {
                case "ID":
                  data.cell.styles.halign = "left";
                  break;
                case "Base":
                  data.cell.styles.halign = "left";
                  break;
                case "Móvil":
                  data.cell.styles.halign = "left";
                  break;
                case "Móvil":
                  data.cell.styles.halign = "left";
                  break;
                case "Ing. Enf.":
                  data.cell.styles.halign = "left";
                  break;
                case "Sal. Enf.":
                  data.cell.styles.halign = "left";
                  break;
                default:
                  data.cell.styles.halign = "right";
                  break;
              }
            }
          },
        });

        const lastItem =
          serviciosFinalizadosExcel[serviciosFinalizadosExcel.length - 1];

        doc.setFontSize(10);
        let finalY = doc.previousAutoTable.finalY;

        //doc.text(String(lastItem.Coseguros), 60, finalY + 20);
        /*
        doc.text(
          checked1 ? String(lastItem.Presentismo) : "0.00",
          95,
          finalY + 20
        );
        */
        window.open(doc.output("bloburl"));
      });
    });
    startClick();
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Liquidación de Enfermeros"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>

        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarFechas("excel");
            //exportExcel();
          }}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        >
          Excel
        </Button>
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarFechas("pdf");
          }}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        >
          PDF
        </Button>
        {/*
        <ButtonMui
          onClick={aceptarFechas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        */}
        <ButtonMui
          onClick={exitClick}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(enfermeros);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {enfermeros ? (
            <Fragment>
              <Dialog
                header={renderHeaderFechas()}
                visible={visibleFechas}
                closable={false}
                footer={renderFooterFechas()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="desde"
                      label="Desde"
                      type="datetime-local"
                      fullWidth
                      variant="standard"
                      //onKeyDown={handleEnter}
                      value={fechas && fechas.desde ? fechas.desde : null}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setFechas({
                          ...fechas,
                          desde: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="hasta"
                      label="Hasta"
                      type="datetime-local"
                      fullWidth
                      variant="standard"
                      //onKeyDown={handleEnter}
                      value={fechas && fechas.hasta ? fechas.hasta : null}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setFechas({
                          ...fechas,
                          hasta: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Fragment>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ paddingLeft: 0, marginTop: "0em" }}
                    />

                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        id="nombre"
                        fullWidth
                        options={enfermeros}
                        noOptionsText={"No hay opciones"}
                        style={{ marginLeft: ".8vw", color: "green" }}
                        //defaultValue={grupo ? agrupar[grupo - 1] : null}
                        disablePortal
                        getOptionLabel={(option) => option.nombre}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Seleccione el Enfermero"}
                            variant="standard"
                          />
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            setTripulanteSeleccionado(value);
                          } else {
                            setTripulanteSeleccionado(null);
                          }
                        }}
                      />
                    </Grid>
                  </Fragment>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
