import React, { useState, useContext, Fragment } from "react";
import { createContext } from "react";
import clienteAxios from "../config/axios";
import { AlertContext } from "./AlertContext";

const MapsContext = createContext();
const { Provider, Consumer } = MapsContext;

function MapsProvider({ children }) {
  const [location, setLocation] = useState([]);
  const [open, setOpen] = useState(false);


  const obtenerMovilesConTelefono = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/gps/obtenermovilescontelefono"
      );
      let data = respuesta.data;
      console.log(data)
      setLocation(data);
      //setBand(false)
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Fragment>
      <Provider
        value={{
          location,
          open,
          obtenerMovilesConTelefono,
          setOpen,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { MapsProvider, Consumer as MapsConsumer, MapsContext };
