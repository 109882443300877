import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../layout/Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

export default function Tickets(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Apellido y Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Email", dataKey: "email" },
    { title: "D.N.I.", dataKey: "numeroDocumento" },
    { title: "C.U.I.T./C.U.I.L.", dataKey: "cuit" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [ticket, guardarTicket] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleRespuesta, setVisibleRespuesta] = useState(false);
  const toast = useRef(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    tickets,
    obtenerTickets,
    obtenerAcceso,
    agregarTicket,
    actualizarTicket,
    obtenerTicketsAll,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      //obtenerAcceso({ usuarioId: state.usuario.id, layout: "Tickets" });
      obtenerTickets({ usuarioId: state.usuario.id, role: state.usuario.role });
    }
  }, [state.id]);

  const addClick = (e) => {
    //navigate(`/base/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarTicket({
      asunto: "",
      detalle: "",
      estado: false,
    });
  };

  const todosClick = (e) => {
    //navigate(`/base/0`);
    obtenerTicketsAll();
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const onHide = (e) => {
    setVisible(false);
    setSelectedRow(null);
  };

  const onHideRespuesta = (e) => {
    setVisibleRespuesta(false);
    setSelectedRow(null);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !ticket ||
      ticket.asunto === undefined ||
      ticket.asunto === null ||
      ticket.asunto.trim() === ""
    ) {
      mensajeAlerta("El asunto es necesario !.", "error");
      return;
    }

    if (
      !ticket ||
      ticket.detalle === undefined ||
      ticket.detalle === null ||
      ticket.detalle.trim() === ""
    ) {
      mensajeAlerta("El detalle es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarTicket({ ticket });
    } else {
      agregarTicket({ usuarioId: state.usuario.id, ticket });
    }
    onHide();
    onHideRespuesta();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerTickets({ usuarioId: state.usuario.id, role: state.usuario.role });
    }, 2000);
    //exitClick();
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {formatDateTime(rowData.fecha)}
      </Fragment>
    );
  };

  const asuntoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Asunto</span>
        {rowData.asunto}
      </Fragment>
    );
  };
  const detalleTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Detalle</span>
        {rowData.detalle}
      </Fragment>
    );
  };

  const estadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Estado</span>
        {rowData.estado === null || rowData.estado === false
          ? "PENDIENTE"
          : "CONTESTADO"}
      </Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        style={{
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        icon="pi pi-search"
        className="p-button-success p-button-text"
        onClick={() => {
          if (selectedRow) {
            guardarTicket(selectedRow);
            setVisibleRespuesta(true);
          } else {
            toast.current.show({
              severity: "info",
              summary: "Consulta",
              detail: "Debe seleccionar un ticket.",
              life: 3000,
            });
          }
          //consultaSocio(rowData);
        }}
        tooltip="Datos del ticket"
        tooltipOptions={{
          position: "bottom",
          mouseTrack: true,
          mouseTrackTop: 15,
          className: "tooltip",
        }}
      />
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Ticket"></Tag>
      </div>
    );
  };

  const renderHeaderRespuesta = () => {
    return state &&
      state.usuario !== null &&
      state.usuario.role === "a" &&
      ticket &&
      ticket.usuarios !== undefined &&
      ticket.usuarios !== null ? (
      <div>
        <Tag value={"Ticket " + ticket.usuarios.usuario}></Tag>
      </div>
    ) : (
      <div>
        <Tag value="Ticket"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderFooterRespuesta = () => {
    return (
      <div>
        {state && state.usuario !== null && state.usuario.role === "a" ? (
          <ButtonMui
            onClick={aceptarClick}
            color="primary"
            type="button"
            fullWidth
            variant="contained"
            startIcon={<CheckIcon />}
          >
            Aceptar
          </ButtonMui>
        ) : null}
        <ButtonMui
          onClick={onHideRespuesta}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cerrar
        </ButtonMui>
      </div>
    );
  };

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  //console.log(tickets);
  //console.log(ticket);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      <Fragment>
        {tickets && state && state.usuario !== null ? (
          <Fragment>
            <Grid id="dataTableBotones" xs={12} sm={12} md={12}>
              <Button
                icon="pi pi-plus"
                tooltip="Agregar"
                className="p-button-rounded p-button-help"
                style={{ marginLeft: "0em" }}
                onClick={addClick}
              />
              {state && state.usuario !== null && state.usuario.role === "a" ? (
                <Button
                  icon="pi pi-ellipsis-v"
                  tooltip="Todos"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={todosClick}
                />
              ) : null}
              <Button
                icon="pi pi-times"
                tooltip="Cerrar"
                className="p-button-rounded p-button-primary"
                style={{ marginLeft: "1em" }}
                onClick={exitClick}
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ align: "center", margin: "1em" }}
              />

              <div className="datatable-responsive-demo">
                <div className="card">
                  <Tooltip target=".export-buttons>button" position="bottom" />
                  <DataTable
                    value={tickets}
                    selection={selectedRow}
                    onSelectionChange={(e) => setSelectedRow(e.value)}
                    selectionMode="single"
                    dataKey="id"
                    //header={header}
                    className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                    style={{ fontSize: "xx-small" }}
                    globalFilter={globalFilter}
                    emptyMessage="No hay datos."
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    //paginatorLeft={paginatorLeft}
                    //paginatorRight={paginatorRight}
                  >
                    <Column
                      field="id"
                      header="ID"
                      style={{ width: "5%" }}
                      body={idTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="fecha"
                      header="Fecha"
                      style={{ width: "10%" }}
                      body={fechaTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="asunto"
                      header="Asunto"
                      style={{ width: "20%" }}
                      body={asuntoTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="detalle"
                      header="Detalle"
                      style={{ width: "50%" }}
                      body={detalleTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="estado"
                      header="Estado"
                      body={estadoTemplate}
                      sortable
                    ></Column>
                    <Column
                      className="colBotones"
                      body={actionBodyTemplate}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </Grid>
            <Dialog
              header={renderHeader()}
              visible={visible}
              closable={false}
              footer={renderFooter()}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "60vw", paddingBottom: "0" }}
              position="center"
            >
              <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                <Grid item xs={12} md={12}>
                  <TextField
                    value={ticket ? ticket.asunto : null}
                    required
                    id="asunto"
                    label="Asunto"
                    fullWidth
                    onChange={(e) =>
                      guardarTicket({
                        ...ticket,
                        asunto: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <textarea
                    rows="5"
                    required
                    //cols="100"
                    name="text"
                    placeholder="Ingrese detalle"
                    disabled={selectedRow !== null}
                    value={ticket ? ticket.detalle : null}
                    //onKeyDown={handleEnter}
                    onChange={(e) =>
                      guardarTicket({
                        ...ticket,
                        detalle: e.target.value,
                      })
                    }
                    style={{ width: "100%" }}
                  ></textarea>
                </Grid>
              </Grid>
            </Dialog>

            <Dialog
              header={renderHeaderRespuesta()}
              visible={visibleRespuesta}
              closable={false}
              footer={renderFooterRespuesta()}
              breakpoints={{ "960px": "75vw" }}
              style={{ width: "75vw", paddingBottom: "0" }}
              position="center"
            >
              <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={ticket ? ticket.asunto : null}
                      required
                      id="asunto"
                      label="Asunto"
                      fullWidth
                      disabled={selectedRow !== null}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <textarea
                      rows="5"
                      required
                      //cols="100"
                      name="text"
                      placeholder="Ingrese detalle"
                      disabled={selectedRow !== null}
                      value={ticket ? ticket.detalle : null}
                      //onKeyDown={handleEnter}
                      style={{ width: "100%" }}
                    ></textarea>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      value={
                        ticket && ticket.estado
                          ? formatDateTime(ticket.fecha_respuesta)
                          : null
                      }
                      disabled
                      id="fecha"
                      label="Fecha"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <textarea
                      rows="5"
                      required
                      //cols="100"
                      name="text"
                      placeholder=""
                      disabled={state.usuario.role !== "a"}
                      value={ticket ? ticket.detalle_respuesta : null}
                      //onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarTicket({
                          ...ticket,
                          detalle_respuesta: e.target.value,
                        })
                      }
                      style={{ width: "100%" }}
                    ></textarea>
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>
          </Fragment>
        ) : (
          <Spinner />
        )}
        <Grid item xs={12} sm={12}></Grid>
      </Fragment>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
