import React, { useState, useContext, Fragment } from "react";
import clienteAxios from "../config/axios";
import tokenAuth from "../config/token";
import { createContext } from "react";
import { AlertContext } from "./AlertContext";

const AuthContext = createContext();
const { Provider, Consumer } = AuthContext;

function AuthProvider({ children }) {
  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const [state, guardarState] = useState({
    token: localStorage.getItem("token"),
    autenticado: null,
    usuario: null,
    cargando: true,
  });

  const registrarUsuario = async (datos) => {

    try {
      const respuesta = await clienteAxios.post(
        "/api/auth/crearusuario",
        datos
      );
      //console.log(respuesta);
      
      enviarEmail(datos)

      mensajeAlerta("Registro de Usuarios", "El usuario se ha creado correctamente.", "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Registro de Usuarios", "El usuario ya existe !.", "error");
      localStorage.removeItem("token");
      guardarState({
        ...state,
        token: null,
        usuario: null,
        autenticado: null,
        cargando: false,
      });
    }
  };

  // Retorna el usuario autenticado
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");

    tokenAuth(token);

    try {
      const respuesta = await clienteAxios.post("/api/auth/usuarioautenticado");

      //console.log(respuesta.data)

      if (token === null) {
        guardarState({
          ...state,
          autenticado: false,
          usuario: null,
          cargando: false,
          token: null,
          nombre: null,
          id: null,
        });
        return;
      }

      //console.log(respuesta)

      guardarState({
        ...state,
        autenticado: true,
        usuario: respuesta.data.usuario,
        cargando: false,
        nombre: respuesta.data.usuario.usuario,
        id: respuesta.data.usuario.id,
      });

      window.$usuario = respuesta.data.usuario;
    } catch (error) {
      console.log(error.response);
      guardarState({
        ...state,
        autenticado: false,
        usuario: null,
        cargando: false,
        token: null,
        nombre: null,
        id: null,
      });
    }
  };

  // Cuando el usuario inicia sesión
  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/api/auth", datos);
      localStorage.setItem("token", respuesta.data.token);
      //console.log(localStorage)
      // Obtener el usuario
      usuarioAutenticado();
      window.location.reload();
    } catch (error) {
      console.log(error.response.data.msg);
      mensajeAlerta("Inicio de Sesión", error.response.data.msg, "error");
      localStorage.removeItem("token");
      guardarState({
        ...state,
        token: null,
        usuario: null,
        autenticado: null,
        cargando: false,
      });
    }
  };

  // Cierra la sesión del usuario
  const cerrarSesion = () => {
    localStorage.removeItem("token");
    guardarState({
      ...state,
      token: null,
      usuario: null,
      autenticado: null,
      cargando: false,
    });
    //window.location.reload();
  };


  const enviarEmail = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/api/auth/enviaremail", datos);
      //let data = respuesta.data
      //console.log(data)

      mensajeAlerta("Restablecer Contraseña", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      //mensajeAlerta(error.response.data, "error");
    }
  };


  const verificacionEmail = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/api/auth/enviaremail", datos);

      mensajeAlerta("Verificación de Email", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Verificación de Email", error.response.data, "error");
    }
  };

  const cambiarPassword = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/auth/cambiarpassword",
        datos
      );

      mensajeAlerta("Cambio de Password", respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta("Cambio de Password", error.response.data, "error");
    }
  };

  return (
    <Fragment>
      <Provider
        value={{
          token: state.token,
          autenticado: state.autenticado,
          usuario: state.usuario,
          cargando: state.cargando,
          state,
          registrarUsuario,
          iniciarSesion,
          usuarioAutenticado,
          cerrarSesion,
          verificacionEmail,
          cambiarPassword,
          enviarEmail,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { AuthProvider, Consumer as AuthConsumer, AuthContext };
