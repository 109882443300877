import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

export default function ValorizacionesServicios(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Telefono", dataKey: "telefono" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const colores = [
    { nombre: "AMARILLO", id: 3 },
    { nombre: "VERDE", id: 4 },
    { nombre: "ROJO", id: 5 },
    { nombre: "FUCCIA", id: 7 },
    { nombre: "CELESTE", id: 12 },
  ];

  const [color, setColor] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const [titularId, setTitularId] = useState(null);
  const [titularNombre, setTitularNombre] = useState(null);
  const [titularSeleccionado, setTitularSeleccionado] = useState(null);
  const [check, setCheck] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    valorizar,
    valorizaciones,
    obtenerValorizacionesServicios,
    obtenerAcceso,
    actualizarValorizaciones,
    guardarValorizar,
    obtenerValorizacionesServiciosConvenio,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const { rows, setRows, obtenerDatosConvenios } = serviciosContext;

  const buscarAfiliados = () => {
    if (titularNombre !== null && titularNombre !== "") {
      obtenerDatosConvenios({
        opcion: "Apellido y nombre",
        texto: titularNombre,
      });
    }
    if (titularId !== null && titularId !== "") {
      obtenerDatosConvenios({
        opcion: "Número de socio",
        texto: titularId,
      });
    }
  };

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "ValorizacionesServicios",
      });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerValorizacionesServicios();
  }, []);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = (e) => {
    if (selectedRow) {
      setCheck({
        pediatrico: false,
        nocturno: false,
        sdf: false,
        fz: false,
      });
      setColor(null);
      let afiliado = selectedRow.afiliadoId.substring(0, 8);
      setTitularId(afiliado);
      setTitularSeleccionado(null);
      setRows(null);
      //guardarValorizar(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un convenio.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //navigate(`/base/0`);
    setVisible(true);
    setSelectedRow(null);
    setTitularId(null);
    setTitularNombre(null);
    setTitularSeleccionado(null);
    setColor(null);
    setCheck({
      pediatrico: false,
      nocturno: false,
      sdf: false,
      fz: false,
    });
  };

  const exitClick = (e) => {
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 1);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar

    if (
      !titularSeleccionado ||
      titularSeleccionado === undefined ||
      titularSeleccionado === null
    ) {
      mensajeAlerta(
        "Valorización de Servicios",
        "El convenio es necesario !.",
        "error"
      );
      return;
    }

    if (!color || color === undefined || color === null) {
      mensajeAlerta(
        "Valorización de Servicios",
        "El color es necesario !.",
        "error"
      );
      return;
    }

    if (
      (valorizar.verde === null ||
        valorizar.verde === 0 ||
        valorizar.verde === undefined) &&
      (valorizar.verdeFz > 0 ||
        valorizar.verdeSdf > 0 ||
        valorizar.verdeSdfFz > 0 ||
        valorizar.verdeN > 0 ||
        valorizar.verdeNFz > 0 ||
        valorizar.verdeNSdf > 0 ||
        valorizar.verdeNSdfFz > 0 ||
        valorizar.verdeP > 0 ||
        valorizar.verdePFz > 0 ||
        valorizar.verdePSdf > 0 ||
        valorizar.verdePSdfFz > 0 ||
        valorizar.verdePN > 0 ||
        valorizar.verdePNFz > 0 ||
        valorizar.verdePNSdf > 0 ||
        valorizar.verdePNSdfFz > 0)
    ) {
      mensajeAlerta(
        "Valorización de Servicios",
        "Primero debe ingresar el valor de verde !.",
        "error"
      );
      return;
    }

    if (
      (valorizar.amarillo === null ||
        valorizar.amarillo === 0 ||
        valorizar.amarillo === undefined) &&
      (valorizar.amarilloFz > 0 ||
        valorizar.amarilloSdf > 0 ||
        valorizar.amarilloSdfFz > 0 ||
        valorizar.amarilloN > 0 ||
        valorizar.amarilloNFz > 0 ||
        valorizar.amarilloNSdf > 0 ||
        valorizar.amarilloNSdfFz > 0 ||
        valorizar.amarilloP > 0 ||
        valorizar.amarilloPFz > 0 ||
        valorizar.amarilloPSdf > 0 ||
        valorizar.amarilloPSdfFz > 0 ||
        valorizar.amarilloPN > 0 ||
        valorizar.amarilloPNFz > 0 ||
        valorizar.amarilloPNSdf > 0 ||
        valorizar.amarilloPNSdfFz > 0)
    ) {
      mensajeAlerta(
        "Valorización de Servicios",
        "Primero debe ingresar el valor de amarillo !.",
        "error"
      );
      return;
    }

    if (
      (valorizar.rojo === null ||
        valorizar.rojo === 0 ||
        valorizar.rojo === undefined) &&
      (valorizar.rojoFz > 0 ||
        valorizar.rojoSdf > 0 ||
        valorizar.rojoSdfFz > 0 ||
        valorizar.rojoN > 0 ||
        valorizar.rojoNFz > 0 ||
        valorizar.rojoNSdf > 0 ||
        valorizar.rojoNSdfFz > 0 ||
        valorizar.rojoP > 0 ||
        valorizar.rojoPFz > 0 ||
        valorizar.rojoPSdf > 0 ||
        valorizar.rojoPSdfFz > 0 ||
        valorizar.rojoPN > 0 ||
        valorizar.rojoPNFz > 0 ||
        valorizar.rojoPNSdf > 0 ||
        valorizar.rojoPNSdfFz > 0)
    ) {
      mensajeAlerta(
        "Valorización de Servicios",
        "Primero debe ingresar el valor de rojo !.",
        "error"
      );
      return;
    }

    if (
      (valorizar.fuccia === null ||
        valorizar.fuccia === 0 ||
        valorizar.fuccia === undefined) &&
      (valorizar.fucciaFz > 0 ||
        valorizar.fucciaSdf > 0 ||
        valorizar.fucciaSdfFz > 0 ||
        valorizar.fucciaN > 0 ||
        valorizar.fucciaNFz > 0 ||
        valorizar.fucciaNSdf > 0 ||
        valorizar.fucciaNSdfFz > 0 ||
        valorizar.fucciaP > 0 ||
        valorizar.fucciaPFz > 0 ||
        valorizar.fucciaPSdf > 0 ||
        valorizar.fucciaPSdfFz > 0 ||
        valorizar.fucciaPN > 0 ||
        valorizar.fucciaPNFz > 0 ||
        valorizar.fucciaPNSdf > 0 ||
        valorizar.fucciaPNSdfFz > 0)
    ) {
      mensajeAlerta(
        "Valorización de Servicios",
        "Primero debe ingresar el valor de fuccia !.",
        "error"
      );
      return;
    }

    if (
      (valorizar.celeste === null ||
        valorizar.celeste === 0 ||
        valorizar.celeste === undefined) &&
      (valorizar.celesteFz > 0 ||
        valorizar.celesteSdf > 0 ||
        valorizar.celesteSdfFz > 0 ||
        valorizar.celesteN > 0 ||
        valorizar.celesteNFz > 0 ||
        valorizar.celesteNSdf > 0 ||
        valorizar.celesteNSdfFz > 0 ||
        valorizar.celesteP > 0 ||
        valorizar.celestePFz > 0 ||
        valorizar.celestePSdf > 0 ||
        valorizar.celestePSdfFz > 0 ||
        valorizar.celestePN > 0 ||
        valorizar.celestePNFz > 0 ||
        valorizar.celestePNSdf > 0 ||
        valorizar.celestePNSdfFz > 0)
    ) {
      mensajeAlerta(
        "Valorización de Servicios",
        "Primero debe ingresar el valor de celeste !.",
        "error"
      );
      return;
    }

    // Llamar al context

    actualizarValorizaciones({ valorizar, color, titularSeleccionado });
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerValorizacionesServicios();
    }, 2000);
    setVisible(true);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID Afiliado</span>
        {rowData.afiliadoId}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, valorizar);
        doc.save("valorizar.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(valorizaciones);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "valorizacionesServicios");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        {/*
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        */}
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Valorizaciones de Servicios"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Convenios a Valorizar"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(valorizaciones);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {valorizaciones ? (
            <Fragment>
              <Grid id="dataTableBotones" xs={12} sm={12} md={12}>
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                />

                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={valorizaciones}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="afiliadoId"
                        header="ID Afiliado"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre/Razón Social"
                        body={nombreTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="titular"
                      label="ID Titular"
                      fullWidth
                      type="number"
                      value={titularId ? titularId : ""}
                      disabled={titularNombre !== null && titularNombre !== ""}
                      //onKeyDown={handleEnter}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setTitularId(e.target.value);
                        setTitularSeleccionado(null);
                        setRows(null);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      id="razonSearch"
                      label="Apellido y Nombre o Razón Social"
                      fullWidth
                      value={titularNombre ? titularNombre : ""}
                      disabled={
                        titularId !== null &&
                        titularId !== "" &&
                        titularId !== 0
                      }
                      //onKeyDown={handleEnter}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setTitularNombre(e.target.value);
                        setTitularSeleccionado(null);
                        setRows(null);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Button
                      icon="pi pi-search"
                      className="p-button-rounded p-button-success"
                      onClick={() => buscarAfiliados()}
                    />
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <Autocomplete
                      id="razon"
                      fullWidth
                      options={rows}
                      disabled={!rows}
                      noOptionsText={"No hay opciones"}
                      style={{ marginLeft: ".8vw", color: "green" }}
                      //defaultValue={grupo ? agrupar[grupo - 1] : null}
                      value={titularSeleccionado}
                      disablePortal
                      getOptionLabel={(option) => option.nombre_razonsocial}
                      renderInput={(params) => (
                        <TextField {...params} label="Seleccione el Afiliado" />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          setTitularSeleccionado(value);
                          setTitularId(value.id_titular);
                          setTitularNombre(null);
                          obtenerValorizacionesServiciosConvenio({
                            afiliadoId: value.id,
                          });
                        } else {
                          setTitularSeleccionado(null);
                        }
                      }}
                    />
                  </Grid>

                  {titularSeleccionado ? (
                    <Fragment>
                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          id="color"
                          fullWidth
                          options={colores}
                          noOptionsText={"No hay opciones"}
                          style={{ marginLeft: ".8vw", color: "green" }}
                          //defaultValue={grupo ? agrupar[grupo - 1] : null}
                          disablePortal
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField {...params} label="Colores" />
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              setColor(value);
                            } else {
                              setColor(null);
                            }
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        style={{ paddingLeft: "1vw", paddingTop: "3vw" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={check ? check.pediatrico : null}
                              style={{ marginLeft: "1em" }}
                              onChange={(e) =>
                                setCheck({
                                  ...check,
                                  pediatrico: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Pediátrico"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        style={{ paddingLeft: 0, paddingTop: "3vw" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={check ? check.nocturno : null}
                              style={{ marginLeft: "1em" }}
                              onChange={(e) =>
                                setCheck({
                                  ...check,
                                  nocturno: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Nocturno"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        style={{ paddingLeft: 0, paddingTop: "3vw" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={check ? check.sdf : null}
                              style={{ marginLeft: "1em" }}
                              onChange={(e) =>
                                setCheck({
                                  ...check,
                                  sdf: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Sab./Dom./Fer."
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={2}
                        style={{ paddingLeft: 0, paddingTop: "3vw" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={check ? check.fz : null}
                              style={{ marginLeft: "1em" }}
                              onChange={(e) =>
                                setCheck({
                                  ...check,
                                  fz: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Fuera de Zona"
                          labelPlacement="start"
                        />
                      </Grid>

                      {/*Verde */}
                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verde : null}
                            id="verde"
                            label="Verde"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verde: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verde !== null &&
                              valorizar.verde < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verde !== null &&
                              valorizar.verde < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdeFz : null}
                            id="verdeFz"
                            label="Verde Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdeFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdeFz !== null &&
                              valorizar.verdeFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdeFz !== null &&
                              valorizar.verdeFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdeSdf : null}
                            id="verdeSdf"
                            label="Verde Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdeSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdeSdf !== null &&
                              valorizar.verdeSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdeSdf !== null &&
                              valorizar.verdeSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdeSdfFz : null}
                            id="verdeSdfFz"
                            label="Verde Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdeSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdeSdfFz !== null &&
                              valorizar.verdeSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdeSdfFz !== null &&
                              valorizar.verdeSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdeN : null}
                            id="verdeN"
                            label="Verde Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdeN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdeN !== null &&
                              valorizar.verdeN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdeN !== null &&
                              valorizar.verdeN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdeNFz : null}
                            id="verdeNFz"
                            label="Verde Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdeNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdeNFz !== null &&
                              valorizar.verdeNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdeNFz !== null &&
                              valorizar.verdeNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdeNSdf : null}
                            id="verdeNSdf"
                            label="Verde Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdeNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdeNSdf !== null &&
                              valorizar.verdeNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdeNSdf !== null &&
                              valorizar.verdeNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdeNSdfFz : null}
                            id="verdeNSdfFz"
                            label="Verde Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdeNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdeNSdfFz !== null &&
                              valorizar.verdeNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdeNSdfFz !== null &&
                              valorizar.verdeNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdeP : null}
                            id="verdeP"
                            label="Verde Pediátrico"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdeP: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdeP !== null &&
                              valorizar.verdeP < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdeP !== null &&
                              valorizar.verdeP < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdePFz : null}
                            id="verdePFz"
                            label="Verde Pediátrico y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdePFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdePFz !== null &&
                              valorizar.verdePFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdePFz !== null &&
                              valorizar.verdePFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdePSdf : null}
                            id="verdePSdf"
                            label="Verde Pediátrico y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdePSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdePSdf !== null &&
                              valorizar.verdePSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdePSdf !== null &&
                              valorizar.verdePSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdePSdfFz : null}
                            id="verdePSdfFz"
                            label="Verde Pediátrico y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdePSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdePSdfFz !== null &&
                              valorizar.verdePSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdePSdfFz !== null &&
                              valorizar.verdePSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdePN : null}
                            id="verdePN"
                            label="Verde Pediátrico y Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdePN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdePN !== null &&
                              valorizar.verdePN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdePN !== null &&
                              valorizar.verdePN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdePNFz : null}
                            id="verdePNFz"
                            label="Verde Pediátrico y Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdePNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdePNFz !== null &&
                              valorizar.verdePNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdePNFz !== null &&
                              valorizar.verdePNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdePNSdf : null}
                            id="verdePNSdf"
                            label="Verde Pediátrico y Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdePNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdePNSdf !== null &&
                              valorizar.verdePNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdePNSdf !== null &&
                              valorizar.verdePNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "VERDE" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.verdePNSdfFz : null}
                            id="verdePNSdfFz"
                            label="Verde Pediátrico y Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                verdePNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.verdePNSdfFz !== null &&
                              valorizar.verdePNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.verdePNSdfFz !== null &&
                              valorizar.verdePNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {/* Amarillo */}
                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarillo : null}
                            id="amarillo"
                            label="Amarillo"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarillo: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarillo !== null &&
                              valorizar.amarillo < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarillo !== null &&
                              valorizar.amarillo < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloFz : null}
                            id="amarilloFz"
                            label="Amarillo Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloFz !== null &&
                              valorizar.amarilloFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloFz !== null &&
                              valorizar.amarilloFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloSdf : null}
                            id="amarilloSdf"
                            label="Amarillo Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloSdf !== null &&
                              valorizar.amarilloSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloSdf !== null &&
                              valorizar.amarilloSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloSdfFz : null}
                            id="amarilloSdfFz"
                            label="Amarillo Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloSdfFz !== null &&
                              valorizar.amarilloSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloSdfFz !== null &&
                              valorizar.amarilloSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloN : null}
                            id="amarilloN"
                            label="Amarillo Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloN !== null &&
                              valorizar.amarilloN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloN !== null &&
                              valorizar.amarilloN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloNFz : null}
                            id="amarilloNFz"
                            label="Amarillo Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloNFz !== null &&
                              valorizar.amarilloNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloNFz !== null &&
                              valorizar.amarilloNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloNSdf : null}
                            id="amarilloNSdf"
                            label="Amarillo Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloNSdf !== null &&
                              valorizar.amarilloNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloNSdf !== null &&
                              valorizar.amarilloNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloNSdfFz : null}
                            id="amarilloNSdfFz"
                            label="Amarillo Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloNSdfFz !== null &&
                              valorizar.amarilloNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloNSdfFz !== null &&
                              valorizar.amarilloNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloP : null}
                            id="amarilloP"
                            label="Amarillo Pediátrico"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloP: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloP !== null &&
                              valorizar.amarilloP < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloP !== null &&
                              valorizar.amarilloP < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloPFz : null}
                            id="amarilloPFz"
                            label="Amarillo Pediátrico y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloPFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloPFz !== null &&
                              valorizar.amarilloPFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloPFz !== null &&
                              valorizar.amarilloPFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloPSdf : null}
                            id="amarilloPSdf"
                            label="Amarillo Pediátrico y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloPSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloPSdf !== null &&
                              valorizar.amarilloPSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloPSdf !== null &&
                              valorizar.amarilloPSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloPSdfFz : null}
                            id="amarilloPSdfFz"
                            label="Amarillo Pediátrico y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloPSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloPSdfFz !== null &&
                              valorizar.amarilloPSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloPSdfFz !== null &&
                              valorizar.amarilloPSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloPN : null}
                            id="amarilloPN"
                            label="Amarillo Pediátrico y Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloPN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloPN !== null &&
                              valorizar.amarilloPN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloPN !== null &&
                              valorizar.amarilloPN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloPNFz : null}
                            id="amarilloPNFz"
                            label="Amarillo Pediátrico y Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloPNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloPNFz !== null &&
                              valorizar.amarilloPNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloPNFz !== null &&
                              valorizar.amarilloPNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloPNSdf : null}
                            id="amarilloPNSdf"
                            label="Amarillo Pediátrico y Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloPNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloPNSdf !== null &&
                              valorizar.amarilloPNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloPNSdf !== null &&
                              valorizar.amarilloPNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "AMARILLO" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.amarilloPNSdfFz : null}
                            id="amarilloPNSdfFz"
                            label="Amarillo Pediátrico y Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                amarilloPNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.amarilloPNSdfFz !== null &&
                              valorizar.amarilloPNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.amarilloPNSdfFz !== null &&
                              valorizar.amarilloPNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {/* Rojo */}
                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojo : null}
                            id="rojo"
                            label="Rojo"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojo: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojo !== null &&
                              valorizar.rojo < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojo !== null &&
                              valorizar.rojo < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoFz : null}
                            id="rojoFz"
                            label="Rojo Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoFz !== null &&
                              valorizar.rojoFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoFz !== null &&
                              valorizar.rojoFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoSdf : null}
                            id="rojoSdf"
                            label="Rojo Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoSdf !== null &&
                              valorizar.rojoSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoSdf !== null &&
                              valorizar.rojoSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoSdfFz : null}
                            id="rojoSdfFz"
                            label="Rojo Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoSdfFz !== null &&
                              valorizar.rojoSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoSdfFz !== null &&
                              valorizar.rojoSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoN : null}
                            id="rojoN"
                            label="Rojo Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoN !== null &&
                              valorizar.rojoN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoN !== null &&
                              valorizar.rojoN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoNFz : null}
                            id="rojoNFz"
                            label="Rojo Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoNFz !== null &&
                              valorizar.rojoNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoNFz !== null &&
                              valorizar.rojoNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoNSdf : null}
                            id="rojoNSdf"
                            label="Rojo Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoNSdf !== null &&
                              valorizar.rojoNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoNSdf !== null &&
                              valorizar.rojoNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoNSdfFz : null}
                            id="rojoNSdfFz"
                            label="Rojo Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoNSdfFz !== null &&
                              valorizar.rojoNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoNSdfFz !== null &&
                              valorizar.rojoNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoP : null}
                            id="rojoP"
                            label="Rojo Pediátrico"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoP: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoP !== null &&
                              valorizar.rojoP < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoP !== null &&
                              valorizar.rojoP < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoPFz : null}
                            id="rojoPFz"
                            label="Rojo Pediátrico y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoPFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoPFz !== null &&
                              valorizar.rojoPFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoPFz !== null &&
                              valorizar.rojoPFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoPSdf : null}
                            id="rojoPSdf"
                            label="Rojo Pediátrico y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoPSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoPSdf !== null &&
                              valorizar.rojoPSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoPSdf !== null &&
                              valorizar.rojoPSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoPSdfFz : null}
                            id="rojoPSdfFz"
                            label="Rojo Pediátrico y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoPSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoPSdfFz !== null &&
                              valorizar.rojoPSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoPSdfFz !== null &&
                              valorizar.rojoPSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoPN : null}
                            id="rojoPN"
                            label="Rojo Pediátrico y Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoPN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoPN !== null &&
                              valorizar.rojoPN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoPN !== null &&
                              valorizar.rojoPN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoPNFz : null}
                            id="rojoPNFz"
                            label="Rojo Pediátrico y Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoPNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoPNFz !== null &&
                              valorizar.rojoPNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoPNFz !== null &&
                              valorizar.rojoPNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoPNSdf : null}
                            id="rojoPNSdf"
                            label="Rojo Pediátrico y Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoPNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoPNSdf !== null &&
                              valorizar.rojoPNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoPNSdf !== null &&
                              valorizar.rojoPNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "ROJO" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.rojoPNSdfFz : null}
                            id="rojoPNSdfFz"
                            label="Rojo Pediátrico y Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                rojoPNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.rojoPNSdfFz !== null &&
                              valorizar.rojoPNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.rojoPNSdfFz !== null &&
                              valorizar.rojoPNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {/* Fuccia */}
                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fuccia : null}
                            id="fuccia"
                            label="Fuccia"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fuccia: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fuccia !== null &&
                              valorizar.fuccia < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fuccia !== null &&
                              valorizar.fuccia < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaFz : null}
                            id="fucciaFz"
                            label="Fuccia Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaFz !== null &&
                              valorizar.fucciaFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaFz !== null &&
                              valorizar.fucciaFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaSdf : null}
                            id="fucciaSdf"
                            label="Fuccia Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaSdf !== null &&
                              valorizar.fucciaSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaSdf !== null &&
                              valorizar.fucciaSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaSdfFz : null}
                            id="fucciaSdfFz"
                            label="Fuccia Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaSdfFz !== null &&
                              valorizar.fucciaSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaSdfFz !== null &&
                              valorizar.fucciaSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaN : null}
                            id="fucciaN"
                            label="Fuccia Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaN !== null &&
                              valorizar.fucciaN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaN !== null &&
                              valorizar.fucciaN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaNFz : null}
                            id="fucciaNFz"
                            label="Fuccia Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaNFz !== null &&
                              valorizar.fucciaNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaNFz !== null &&
                              valorizar.fucciaNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaNSdf : null}
                            id="fucciaNSdf"
                            label="Fuccia Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaNSdf !== null &&
                              valorizar.fucciaNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaNSdf !== null &&
                              valorizar.fucciaNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaNSdfFz : null}
                            id="fucciaNSdfFz"
                            label="Fuccia Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaNSdfFz !== null &&
                              valorizar.fucciaNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaNSdfFz !== null &&
                              valorizar.fucciaNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaP : null}
                            id="fucciaP"
                            label="Fuccia Pediátrico"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaP: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaP !== null &&
                              valorizar.fucciaP < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaP !== null &&
                              valorizar.fucciaP < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaPFz : null}
                            id="fucciaPFz"
                            label="Fuccia Pediátrico y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaPFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaPFz !== null &&
                              valorizar.fucciaPFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaPFz !== null &&
                              valorizar.fucciaPFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaPSdf : null}
                            id="fucciaPSdf"
                            label="Fuccia Pediátrico y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaPSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaPSdf !== null &&
                              valorizar.fucciaPSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaPSdf !== null &&
                              valorizar.fucciaPSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaPSdfFz : null}
                            id="fucciaPSdfFz"
                            label="Fuccia Pediátrico y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaPSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaPSdfFz !== null &&
                              valorizar.fucciaPSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaPSdfFz !== null &&
                              valorizar.fucciaPSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaPN : null}
                            id="fucciaPN"
                            label="Fuccia Pediátrico y Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaPN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaPN !== null &&
                              valorizar.fucciaPN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaPN !== null &&
                              valorizar.fucciaPN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaPNFz : null}
                            id="fucciaPNFz"
                            label="Fuccia Pediátrico y Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaPNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaPNFz !== null &&
                              valorizar.fucciaPNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaPNFz !== null &&
                              valorizar.fucciaPNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaPNSdf : null}
                            id="fucciaPNSdf"
                            label="Fuccia Pediátrico y Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaPNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaPNSdf !== null &&
                              valorizar.fucciaPNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaPNSdf !== null &&
                              valorizar.fucciaPNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "FUCCIA" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.fucciaPNSdfFz : null}
                            id="fucciaPNSdfFz"
                            label="Fuccia Pediátrico y Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                fucciaPNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.fucciaPNSdfFz !== null &&
                              valorizar.fucciaPNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.fucciaPNSdfFz !== null &&
                              valorizar.fucciaPNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {/*Celeste */}
                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celeste : null}
                            id="celeste"
                            label="Celeste"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celeste: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celeste !== null &&
                              valorizar.celeste < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celeste !== null &&
                              valorizar.celeste < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celesteFz : null}
                            id="celesteFz"
                            label="Celeste Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celesteFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celesteFz !== null &&
                              valorizar.celesteFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celesteFz !== null &&
                              valorizar.celesteFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celesteSdf : null}
                            id="celesteSdf"
                            label="Celeste Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celesteSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celesteSdf !== null &&
                              valorizar.celesteSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celesteSdf !== null &&
                              valorizar.celesteSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === false &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celesteSdfFz : null}
                            id="celesteSdfFz"
                            label="Celeste Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celesteSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celesteSdfFz !== null &&
                              valorizar.celesteSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celesteSdfFz !== null &&
                              valorizar.celesteSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celesteN : null}
                            id="celesteN"
                            label="Celeste Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celesteN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celesteN !== null &&
                              valorizar.celesteN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celesteN !== null &&
                              valorizar.celesteN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celesteNFz : null}
                            id="celesteNFz"
                            label="Celeste Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celesteNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celesteNFz !== null &&
                              valorizar.celesteNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celesteNFz !== null &&
                              valorizar.celesteNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celesteNSdf : null}
                            id="celesteNSdf"
                            label="Celeste Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celesteNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celesteNSdf !== null &&
                              valorizar.celesteNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celesteNSdf !== null &&
                              valorizar.celesteNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === false &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celesteNSdfFz : null}
                            id="celesteNSdfFz"
                            label="Celeste Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celesteNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celesteNSdfFz !== null &&
                              valorizar.celesteNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celesteNSdfFz !== null &&
                              valorizar.celesteNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celesteP : null}
                            id="celesteP"
                            label="Celeste Pediátrico"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celesteP: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celesteP !== null &&
                              valorizar.celesteP < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celesteP !== null &&
                              valorizar.celesteP < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celestePFz : null}
                            id="celestePFz"
                            label="Celeste Pediátrico y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celestePFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celestePFz !== null &&
                              valorizar.celestePFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celestePFz !== null &&
                              valorizar.celestePFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celestePSdf : null}
                            id="celestePSdf"
                            label="Celeste Pediátrico y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celestePSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celestePSdf !== null &&
                              valorizar.celestePSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celestePSdf !== null &&
                              valorizar.celestePSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === true &&
                      check.nocturno === false &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celestePSdfFz : null}
                            id="celestePSdfFz"
                            label="Celeste Pediátrico y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celestePSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celestePSdfFz !== null &&
                              valorizar.celestePSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celestePSdfFz !== null &&
                              valorizar.celestePSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celestePN : null}
                            id="celestePN"
                            label="Celeste Pediátrico y Nocturno"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celestePN: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celestePN !== null &&
                              valorizar.celestePN < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celestePN !== null &&
                              valorizar.celestePN < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === false &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celestePNFz : null}
                            id="celestePNFz"
                            label="Celeste Pediátrico y Nocturno y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celestePNFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celestePNFz !== null &&
                              valorizar.celestePNFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celestePNFz !== null &&
                              valorizar.celestePNFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === false ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celestePNSdf : null}
                            id="celestePNSdf"
                            label="Celeste Pediátrico y Nocturno y Sab./Dom./Fer."
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celestePNSdf: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celestePNSdf !== null &&
                              valorizar.celestePNSdf < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celestePNSdf !== null &&
                              valorizar.celestePNSdf < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}

                      {color &&
                      color.nombre === "CELESTE" &&
                      check.pediatrico === true &&
                      check.nocturno === true &&
                      check.sdf === true &&
                      check.fz === true ? (
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={valorizar ? valorizar.celestePNSdfFz : null}
                            id="celestePNSdfFz"
                            label="Celeste Pediátrico y Nocturno y Sab./Dom./Fer. y Fuera de Zona"
                            fullWidth
                            type="number"
                            onChange={(e) =>
                              guardarValorizar({
                                ...valorizar,
                                celestePNSdfFz: e.target.value,
                              })
                            }
                            error={
                              valorizar &&
                              valorizar.celestePNSdfFz !== null &&
                              valorizar.celestePNSdfFz < 0
                            }
                            helperText={
                              valorizar &&
                              valorizar.celestePNSdfFz !== null &&
                              valorizar.celestePNSdfFz < 0
                                ? "Importe debe ser mayor a cero"
                                : null
                            }
                          />
                        </Grid>
                      ) : null}
                    </Fragment>
                  ) : null}
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
