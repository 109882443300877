import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "../servicios/ConsultaServicio";
import NotasServicio from "../servicios/NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import hcOffcanvasNav from "hc-offcanvas-nav";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

const agrupar = [
  {
    id: 1,
    nombre: "Base",
  },
  {
    id: 2,
    nombre: "ColorInicial",
  },
  {
    id: 3,
    nombre: "ColorFinal",
  },
  {
    id: 4,
    nombre: "Antecedente",
  },
  {
    id: 5,
    nombre: "Tratamiento",
  },
  {
    id: 6,
    nombre: "MotivoConsulta",
  },
  {
    id: 7,
    nombre: "Diagnostico",
  },
  {
    id: 8,
    nombre: "IDTitular",
  },
  {
    id: 9,
    nombre: "Documento",
  },
  {
    id: 10,
    nombre: "Fecha Tomado",
  },
  {
    id: 11,
    nombre: "Fecha Pasado",
  },
  {
    id: 12,
    nombre: "Fecha Salida",
  },
  {
    id: 13,
    nombre: "Fecha LLegada",
  },
  {
    id: 14,
    nombre: "Fecha Fin",
  },
];

export default function ListadosTraslados(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "Id", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Titular", dataKey: "titularId" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Destino", dataKey: "destino" },
    { title: "Distancia", dataKey: "distancia" },
  ];

  const [visibleFechas, setVisibleFechas] = useState(true);
  const [fechas, setFechas] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const toast = useRef(null);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [servicio, setServicio] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [val, setVal] = useState(null);
  const [agrupado, setAgrupado] = useState(null);
  const [radio, setRadio] = useState("tomado");
  const [movilesDerivados, setMovilesDerivados] = useState(null);
  const [color, setColor] = useState(null);
  const [laboralCheck, setLaboralCheck] = useState(false);
  const [movilPropio, setMovilPropio] = useState(null);
  const [movilPropioCheck, setMovilPropioCheck] = useState(false);
  const [movilDerivado, setMovilDerivado] = useState(null);
  const [movilDerivadoCheck, setMovilDerivadoCheck] = useState(false);
  const [titularId, setTitularId] = useState(null);
  const [titularNombre, setTitularNombre] = useState(null);
  const [titularSeleccionado, setTitularSeleccionado] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    colores,
    configuracion,
    moviles,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerColores,
    obtenerMoviles,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    serviciosFinalizados,
    rows,
    filtrosListadosServicios,
    serviciosFinalizadosExcel,
    obtenerServiciosFinalizados,
    obtenerDatosTitulares,
    setRows,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Listados" });
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const groupBy = (prop) => (data) => {
    return data.reduce((dict, item) => {
      const { [prop]: _, ...rest } = item;
      dict[item[prop]] = [...(dict[item[prop]] || []), rest];
      return dict;
    }, {});
  };

  const calendar = (e) => {
    setVisibleFechas(true);
  };

  const exitClick = (e) => {
    onHideFechas();
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(1, 2);
  };

  const startClick = (e) => {
    setTimeout(() => {
      navigate(`/listadostraslados`);
      setVisibleFechas(true);
    }, 2000);
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    if (
      !fechas ||
      fechas.desde === undefined ||
      fechas.desde === null ||
      fechas.desde.trim() === ""
    ) {
      mensajeAlerta(
        "Listados de Traslados",
        "Fecha desde es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !fechas ||
      fechas.hasta === undefined ||
      fechas.hasta === null ||
      fechas.hasta.trim() === ""
    ) {
      mensajeAlerta(
        "Listados de Traslados",
        "Fecha hasta es necesaria !.",
        "error"
      );
      return;
    }

    let fdesde = fechas.desde + "T00:00:00";
    let fhasta = fechas.hasta + "T23:59:59";

    var elem;

    await obtenerServiciosFinalizados({
      fdesde: fdesde,
      fhasta: fhasta,
      radio,
      titularSeleccionado,
      traslado: true,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHideFechas();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const exportExcel = () => {
    if (serviciosFinalizadosExcel && serviciosFinalizadosExcel.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(serviciosFinalizadosExcel);
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "trasladosServicios");
      });
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });

    startClick();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado traslados", 60, 8);
        doc.autoTable(exportColumns, serviciosFinalizadosExcel, {
          styles: {
            fontSize: 7,
          },
        });
        //doc.save("trasladosServicios.pdf");
        window.open(doc.output("bloburl"));
      });
    });
    startClick();
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Listado de Traslados"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>

        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarFechas("excel");
            //exportExcel();
          }}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        >
          Excel
        </Button>
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarFechas("pdf");
          }}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        >
          PDF
        </Button>
        {/*
        <ButtonMui
          onClick={aceptarFechas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        */}
        <ButtonMui
          onClick={exitClick}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const buscarAfiliados = () => {
    if (titularNombre !== null && titularNombre !== "") {
      obtenerDatosTitulares({
        opcion: "Apellido y nombre",
        texto: titularNombre,
      });
    }
    if (titularId !== null && titularId !== "") {
      obtenerDatosTitulares({
        opcion: "Número de socio",
        texto: titularId,
      });
    }
  };

  //console.log(moviles);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid
                container
                spacing={12}
                style={{ marginTop: "1vw", marginLeft: "1vw" }}
              >
                <label htmlFor="tomado">F. Tomado</label>
                <RadioButton
                  inputId="tomado"
                  value="tomado"
                  name="fecha"
                  onChange={(e) => setRadio(e.value)}
                  checked={radio === "tomado"}
                  style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                />
                <label style={{ marginLeft: "1vw" }} htmlFor="pasado">
                  F. Pasado
                </label>
                <RadioButton
                  inputId="pasado"
                  value="pasado"
                  name="fecha"
                  onChange={(e) => setRadio(e.value)}
                  checked={radio === "pasado"}
                  style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                />
                <label style={{ marginLeft: "1vw" }} htmlFor="salida">
                  F. Salida
                </label>
                <RadioButton
                  inputId="salida"
                  value="salida"
                  name="fecha"
                  onChange={(e) => setRadio(e.value)}
                  checked={radio === "salida"}
                  style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                />
                <label style={{ marginLeft: "1vw" }} htmlFor="llegada">
                  F. Llegada
                </label>
                <RadioButton
                  inputId="llegada"
                  value="llegada"
                  name="fecha"
                  onChange={(e) => setRadio(e.value)}
                  checked={radio === "llegada"}
                  style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                />
                <label style={{ marginLeft: "1vw" }} htmlFor="fin">
                  F. Fin
                </label>
                <RadioButton
                  inputId="fin"
                  value="fin"
                  name="fecha"
                  onChange={(e) => setRadio(e.value)}
                  checked={radio === "fin"}
                  style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  id="desde"
                  label="Desde"
                  type="date"
                  fullWidth
                  variant="standard"
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.desde ? fechas.desde : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      desde: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="hasta"
                  label="Hasta"
                  type="date"
                  fullWidth
                  variant="standard"
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.hasta ? fechas.hasta : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      hasta: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              />

              <Grid item xs={12} md={2}>
                <TextField
                  id="titular"
                  label="ID Titular"
                  fullWidth
                  variant="standard"
                  type="number"
                  value={titularId ? titularId : ""}
                  disabled={titularNombre !== null && titularNombre !== ""}
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setTitularId(e.target.value);
                    setTitularSeleccionado(null);
                    setRows(null);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  id="razonSearch"
                  label="Apellido y Nombre o Razón Social"
                  fullWidth
                  variant="standard"
                  value={titularNombre ? titularNombre : ""}
                  disabled={
                    titularId !== null && titularId !== "" && titularId !== 0
                  }
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setTitularNombre(e.target.value);
                    setTitularSeleccionado(null);
                    setRows(null);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-success"
                  onClick={() => buscarAfiliados()}
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <Autocomplete
                  id="razon"
                  fullWidth
                  options={rows}
                  disabled={!rows}
                  noOptionsText={"No hay opciones"}
                  style={{ marginLeft: ".8vw", color: "green" }}
                  //defaultValue={grupo ? agrupar[grupo - 1] : null}
                  value={titularSeleccionado}
                  disablePortal
                  getOptionLabel={(option) => option.nombre_razonsocial}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Seleccione el Afiliado"
                      variant="standard"
                    />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      setTitularSeleccionado(value);
                      setTitularId(value.id_titular);
                      setTitularNombre(null);
                    } else {
                      setTitularSeleccionado(null);
                    }
                  }}
                />
              </Grid>

              {/*   
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                {!rows &&
                ((titularId && titularId.length >= 4) ||
                  (titularNombre && titularNombre.length >= 3)) ? (
                  <ProgressBar
                    mode="indeterminate"
                    style={{ height: "6px" }}
                  ></ProgressBar>
                ) : null}
              </Grid>
                  */}
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
