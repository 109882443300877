import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { AlertContext } from "../../../../context/AlertContext";
import { ServiciosContext } from "../../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from '@mui/material/Fab';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from '@mui/material/Button';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";


export default function Topes(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const exportColumns = [
    { title: "Titular", dataKey: "titularId" },
    { title: "Tope", dataKey: "cantidad" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [tope, guardarTope] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate();

  const [titularId, setTitularId] = useState(null);
  const [titularNombre, setTitularNombre] = useState(null);
  const [titularSeleccionado, setTitularSeleccionado] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const serviciosContext = useContext(ServiciosContext);
  const { rows, setRows, obtenerDatosTitulares } = serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    topes,
    obtenerTopes,
    obtenerAcceso,
    agregarTope,
    actualizarTope,
  } = configuracionContext;


  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Topes" });
    }
    obtenerTopes({ todos: true });
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = () => {
    if (selectedRow) {
      guardarTope(selectedRow);
      setTitularId(selectedRow.titularId);
      setTitularNombre(selectedRow.nombre);
      setTitularSeleccionado(selectedRow);
      setRows(selectedRow)
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un tope.",
        life: 3000,
      });
    }
  };

  const buscarAfiliados = () => {
    if (titularNombre !== null && titularNombre !== "") {
      obtenerDatosTitulares({
        opcion: "Apellido y nombre",
        texto: titularNombre,
      });
    }
    if (titularId !== null && titularId !== "") {
      obtenerDatosTitulares({
        opcion: "Número de socio",
        texto: titularId,
      });
    }
  };

  const addClick = (e) => {
    //navigate(`/tope/0`);
    setTitularId(null);
    setTitularNombre(null);
    setTitularSeleccionado(null);
    setVisible(true);
    setSelectedRow(null);
    guardarTope({
      habilitado: true,
    });
  };

  const exitClick = (e) => {
   navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 2);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar

    if (
      !titularId ||
      titularId === undefined ||
      titularId === null ||
      titularId.trim() === ""
    ) {
      mensajeAlerta("Topes de Servicios", "El titular es necesario !.", "error");
      return;
    }

    if (
      !titularSeleccionado ||
      titularSeleccionado === undefined ||
      titularSeleccionado === null
    ) {
      mensajeAlerta("Topes de Servicios", "El titular es necesario !.", "error");
      return;
    }

    if (
      !tope ||
      tope.cantidad === undefined ||
      tope.cantidad === null ||
      tope.cantidad === 0
    ) {
      mensajeAlerta("Topes de Servicios", "El tope es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarTope({ tope });
    } else {
      agregarTope({ tope, titularSeleccionado });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerTopes({ todos: true });
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta("Accesos", "No tiene permiso para acceder a esta pantalla !.", "error");
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const titularTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Titular</span>
        {rowData.titularId}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const topeTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tope</span>
        {rowData.cantidad}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado de Topes de Servicios", 60, 8);
        doc.autoTable(exportColumns, topes);
        //doc.save("topes.pdf");
        window.open(doc.output('bloburl'))
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(topes);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "topes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Topes de Servicios"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Tope de Servicio"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(titularSeleccionado);
  //console.log(rows);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {topes ? (
            <Fragment>
              <Grid
                id="dataTableBotones"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={topes}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                        style={{ display: "none" }}
                      ></Column>
                      <Column
                        field="titularId"
                        header="Titular"
                        body={titularTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        body={nombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="cantidad"
                        header="Tope"
                        body={topeTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {topes ? (
                    <Fragment>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id="titular"
                          label="ID Titular"
                          fullWidth
                          variant="standard"
                          type="number"
                          value={titularId ? titularId : ""}
                          disabled={
                            (titularNombre !== null && titularNombre !== "") ||
                            selectedRow
                          }
                          //onKeyDown={handleEnter}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setTitularId(e.target.value);
                            setTitularSeleccionado(null);
                            setRows(null);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          id="razonSearch"
                          label="Apellido y Nombre o Razón Social"
                          fullWidth
                          variant="standard"
                          value={titularNombre ? titularNombre : ""}
                          disabled={
                            titularId !== null &&
                            titularId !== "" &&
                            titularId !== 0
                          }
                          //onKeyDown={handleEnter}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setTitularNombre(e.target.value);
                            setTitularSeleccionado(null);
                            setRows(null);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Button
                          icon="pi pi-search"
                          className="p-button-rounded p-button-success"
                          onClick={() => buscarAfiliados()}
                          disabled={selectedRow}
                        />
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <Autocomplete
                          id="razon"
                          fullWidth
                          options={rows}
                          disabled={!rows || selectedRow}
                          noOptionsText={"No hay opciones"}
                          style={{ marginLeft: ".8vw", color: "green" }}
                          //defaultValue={grupo ? agrupar[grupo - 1] : null}
                          value={!rows || selectedRow ? null : titularSeleccionado}
                          disablePortal
                          getOptionLabel={(option) => option.nombre_razonsocial}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Seleccione el Afiliado"
                              variant="standard"
                            />
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              setTitularSeleccionado(value);
                              setTitularId(value.id_titular);
                              setTitularNombre(null);
                            } else {
                              setTitularSeleccionado(null);
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <TextField
                          value={tope ? tope.cantidad : null}
                          required
                          id="tope"
                          tupe="Number"
                          label="Tope"
                          fullWidth
                          variant="standard"
                          onChange={(e) =>
                            guardarTope({
                              ...tope,
                              cantidad: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={10} />

                      <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              color="primary"
                              checked={tope ? tope.habilitado : null}
                              style={{ marginLeft: "1em" }}
                              onChange={(e) =>
                                guardarTope({
                                  ...tope,
                                  habilitado: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Habilitado"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Fragment>
                  ) : (
                    <Spinner />
                  )}
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
