import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
//import { makeStyles } from "tss-react/mui";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import * as EmailValidator from "email-validator";
import { AuthContext } from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import { Navigate, useNavigate } from "react-router-dom";
import Spinner from "../layout/Spinner";
import Barra from "../layout/Barra";

export default function Account(props) {
  const authContext = useContext(AuthContext);
  const { state, registrarUsuario, usuarioAutenticado } = authContext;

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;
  const configuracionContext = useContext(ConfiguracionContext);
  const { statusAcceso, obtenerAcceso } = configuracionContext;

  const [usuario, guardarUsuario] = useState({
    nombre: "",
    apellido: "",
    email: "",
    password: "",
    password2: "",
  });

  const [redirect, setRedirect] = useState(false);
  let navigate = useNavigate();

  // extraer de usuario
  const { nombre, apellido, email, password, password2 } = usuario;

  useEffect(() => {
    usuarioAutenticado();
  }, [state.id]);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Account" });
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const crearCuenta = (e) => {
    e.preventDefault();
    //Validar

    if (nombre.trim() === "") {
      mensajeAlerta(
        "Registro de Usuarios",
        "El nombre es necesario !.",
        "error"
      );
      return;
    }

    if (apellido.trim() === "") {
      mensajeAlerta(
        "Registro de Usuarios",
        "El apellido es necesario !.",
        "error"
      );
      return;
    }

    if (email.trim() === "") {
      mensajeAlerta(
        "Registro de Usuarios",
        "El email es necesario !.",
        "error"
      );
      return;
    }
    if (!EmailValidator.validate(email)) {
      mensajeAlerta(
        "Registro de Usuarios",
        "El email es incorrecto !.",
        "error"
      );
      return;
    }

    if (password.trim() === "") {
      mensajeAlerta(
        "Registro de Usuarios",
        "El password es incorrecto !.",
        "error"
      );
      return;
    }

    if (password.length < 6) {
      mensajeAlerta(
        "Registro de Usuarios",
        "El password debe tener al menos 6 caracteres !.",
        "error"
      );
      return;
    }

    if (password2 != password) {
      mensajeAlerta(
        "Registro de Usuarios",
        "Los password son diferentes !.",
        "error"
      );
      return;
    }

    // Llamar al context
    registrarUsuario({ nombre, apellido, email, password });
    setRedirect(true);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  return (
    <Fragment>
      <Barra />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div>
              <Avatar>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Crear Cuenta
              </Typography>
              <form noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="nombre"
                      name="nombre"
                      variant="outlined"
                      required
                      fullWidth
                      id="nombre"
                      label="Nombre"
                      autoFocus
                      value={nombre}
                      onChange={(e) =>
                        guardarUsuario({
                          ...usuario,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="apellido"
                      label="Apellido"
                      name="apellido"
                      autoComplete="apellido"
                      value={apellido}
                      onChange={(e) =>
                        guardarUsuario({
                          ...usuario,
                          apellido: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Dirección de Email"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) =>
                        guardarUsuario({
                          ...usuario,
                          email: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) =>
                        guardarUsuario({
                          ...usuario,
                          password: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password2"
                      label="Repetir password"
                      type="password"
                      id="password2"
                      autoComplete="current-password"
                      value={password2}
                      onChange={(e) =>
                        guardarUsuario({
                          ...usuario,
                          password2: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={crearCuenta}
                >
                  Crear Cuenta
                </Button>
              </form>
            </div>
            {redirect ? <Navigate to="/" /> : null}
          </Container>
        </Fragment>
      ) : null}
    </Fragment>
  );
}
