import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";

export default function MovimientosMedicamentos(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "SKU", dataKey: "sku" },
    { title: "Fecha", dataKey: "fecha" },
    { title: "Cantidad", dataKey: "cantidad" },
    { title: "Almacen", dataKey: "almacen" },
    { title: "Tipo", dataKey: "tipo" },
    { title: "Descripción", dataKey: "descripcion" },
  ];

  const tipos = [
    {
      id: "E",
      nombre: "ENTRADA",
    },
    { id: "S", nombre: "SALIDA" },
  ];

  const [movimientoMedicamento, guardarMovimientoMedicamento] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    almacenes,
    almacen,
    medicamentos,
    movimientosMedicamentos,
    obtenerMovimientosMedicamentos,
    obtenerAcceso,
    obtenerAlmacenes,
    guardarAlmacen,
    guardarMovimientosMedicamentos,
    agregarMovimientoMedicamento,
    obtenerMedicamentosSku,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "MovimientosMedicamentos",
      });
    }
    obtenerMedicamentosSku({ todos: true });
    obtenerAlmacenes();
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = (e) => {
    if (selectedRow) {
      guardarMovimientoMedicamento(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un movimiento.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //navigate(`/base/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarMovimientoMedicamento({
      descripcion: null,
    });
  };

  const exitClick = (e) => {
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(3, 1);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();

    //Validar
    if (
      !movimientoMedicamento ||
      movimientoMedicamento.sku === undefined ||
      movimientoMedicamento.sku === null ||
      movimientoMedicamento.sku.trim() === ""
    ) {
      mensajeAlerta(
        "Movimientos de Medicamentos",
        "El medicamento es necesario !.",
        "error"
      );
      return;
    }

    if (
      !movimientoMedicamento ||
      movimientoMedicamento.fecha === undefined ||
      movimientoMedicamento.fecha === null ||
      movimientoMedicamento.fecha.trim() === ""
    ) {
      mensajeAlerta(
        "Movimientos de Medicamentos",
        "La fecha es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoMedicamento ||
      movimientoMedicamento.cantidad === undefined ||
      movimientoMedicamento.cantidad === null ||
      movimientoMedicamento.cantidad <= 0
    ) {
      mensajeAlerta(
        "Movimientos de Medicamentos",
        "La cantidad es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !movimientoMedicamento ||
      movimientoMedicamento.tipo === undefined ||
      movimientoMedicamento.tipo === null ||
      movimientoMedicamento.tipo.trim() === ""
    ) {
      mensajeAlerta(
        "Movimientos de Medicamentos",
        "El tipo de movimiento es necesario !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      //actualizarMovimientoMedicamento({ movimientoMedicamento });
    } else {
      agregarMovimientoMedicamento({ movimientoMedicamento });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      if (almacen) {
        obtenerMovimientosMedicamentos({ almacen: almacen });
      }
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const skuTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">SKU</span>
        {rowData.sku}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const fechaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fecha</span>
        {rowData.fecha}
      </Fragment>
    );
  };

  const almacenTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Almacen</span>
        {rowData.almacen}
      </Fragment>
    );
  };

  const cantidadTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Cantidad</span>
        {rowData.cantidad}
      </Fragment>
    );
  };

  const tipoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tipo</span>
        {rowData.tipo}
      </Fragment>
    );
  };

  const descripcionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Descripción</span>
        {rowData.descripcion}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado de Movimientos de Medicamentos", 60, 8);
        doc.autoTable(exportColumns, movimientosMedicamentos);
        //doc.save("movimientosdemedicamentos.pdf");
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(movimientosMedicamentos);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "movimientosdemedicamentos");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <Grid item xs={12} md={4}>
        <Autocomplete
          id="almacen"
          fullWidth
          options={almacenes}
          disablePortal
          getOptionLabel={(option) => option.nombre}
          renderInput={(params) => (
            <TextField {...params} label="Almacen" variant="standard" />
          )}
          //onKeyDown={handleEnter}
          onChange={(event, value) => {
            if (value) {
              obtenerMovimientosMedicamentos({ almacen: value.id });
              guardarAlmacen(value.id);
            } else {
              guardarMovimientosMedicamentos(null);
              guardarAlmacen(null);
            }
          }}
        />
      </Grid>

      <div className="p-d-flex export-buttons" style={{ marginTop: "0.5em" }}>
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Movimientos de Medicamentos"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Movimiento Medicamento"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(titular);

  return (
    <Fragment>
      <Barra />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {medicamentos && tipos && almacenes && movimientosMedicamentos ? (
            <Fragment>
              <Grid id="dataTableBotones" item xs={12} sm={12} md={12}>
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                {/*
              <Button
                icon="pi pi-pencil"
                tooltip="Editar"
                className="p-button-rounded p-button-danger"
                style={{ marginLeft: "1em" }}
                onClick={editClick}
              />
              */}
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={movimientosMedicamentos}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                        //style={{ width: "5%" }}
                      ></Column>
                      <Column
                        field="sku"
                        header="SKU"
                        body={skuTemplate}
                        sortable
                        //style={{ width: "10%" }}
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        body={nombreTemplate}
                        sortable
                        //style={{ width: "25%" }}
                      ></Column>
                      <Column
                        field="fecha"
                        header="Fecha"
                        body={fechaTemplate}
                        sortable
                        //style={{ width: "10%" }}
                        //filter
                        //filterPlaceholder="filtrar por almacen"
                      ></Column>
                      <Column
                        field="almacen"
                        header="Almacen"
                        body={almacenTemplate}
                        sortable
                        //style={{ width: "10%" }}
                        //filter
                        //filterPlaceholder="filtrar por almacen"
                      ></Column>
                      <Column
                        field="cantidad"
                        header="Cantidad"
                        body={cantidadTemplate}
                        sortable
                        //style={{ width: "7%" }}
                      ></Column>
                      <Column
                        field="tipo"
                        header="Tipo"
                        body={tipoTemplate}
                        sortable
                        //style={{ width: "7%" }}
                      ></Column>
                      <Column
                        field="descripcion"
                        header="Descripción"
                        body={descripcionTemplate}
                        sortable
                        //style={{ width: "30%" }}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {selectedRow ? (
                    <Grid item xs={6} md={3}>
                      <TextField
                        value={
                          movimientoMedicamento
                            ? movimientoMedicamento.id
                            : null
                        }
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="medicamento"
                      fullWidth
                      options={medicamentos}
                      disablePortal
                      getOptionLabel={(option) => option.descripcion}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Medicamento"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMovimientoMedicamento({
                            ...movimientoMedicamento,
                            sku: value.sku,
                          });
                        } else {
                          guardarMovimientoMedicamento({
                            ...movimientoMedicamento,
                            sku: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <TextField
                      id="fecha"
                      label="fecha"
                      type="date"
                      variant="standard"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarMovimientoMedicamento({
                          ...movimientoMedicamento,
                          fecha: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <TextField
                      value={
                        movimientoMedicamento
                          ? movimientoMedicamento.cantidad
                          : null
                      }
                      required
                      id="cantidad"
                      label="Cantidad"
                      type="number"
                      variant="standard"
                      fullWidth
                      onChange={(e) =>
                        guardarMovimientoMedicamento({
                          ...movimientoMedicamento,
                          cantidad: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="tipo"
                      fullWidth
                      options={tipos}
                      disablePortal
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo de movimiento"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          if (value.id === "E") {
                            guardarMovimientoMedicamento({
                              ...movimientoMedicamento,
                              tipo: value.id,
                              almacen: 1, // deposito
                            });
                          } else {
                            guardarMovimientoMedicamento({
                              ...movimientoMedicamento,
                              tipo: value.id,
                              almacen: "", // deposito
                            });
                          }
                        } else {
                          guardarMovimientoMedicamento({
                            ...movimientoMedicamento,
                            tipo: "",
                            almacen: "", // deposito
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="almacen"
                      fullWidth
                      options={almacenes}
                      disablePortal
                      value={
                        movimientoMedicamento &&
                        movimientoMedicamento.tipo === "E"
                          ? almacenes[0]
                          : movimientoMedicamento &&
                            movimientoMedicamento.tipo === "S"
                          ? almacenes.findIndex(
                              (item) =>
                                item.id === movimientoMedicamento.almacen
                            ) !== -1
                            ? almacenes[
                                almacenes.findIndex(
                                  (item) =>
                                    item.id === movimientoMedicamento.almacen
                                )
                              ]
                            : null
                          : null
                      }
                      disabled={
                        movimientoMedicamento &&
                        movimientoMedicamento.tipo === "E"
                          ? true
                          : false
                      }
                      getOptionDisabled={
                        movimientoMedicamento &&
                        movimientoMedicamento.tipo === "S"
                          ? (option) => option === almacenes[0]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Almacen"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarMovimientoMedicamento({
                            ...movimientoMedicamento,
                            almacen: value.id,
                            descripcion: value.nombre,
                          });
                        } else {
                          guardarMovimientoMedicamento({
                            ...movimientoMedicamento,
                            almacen: "",
                            descripcion: "",
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      value={
                        movimientoMedicamento
                          ? movimientoMedicamento.descripcion
                          : null
                      }
                      id="descripcion"
                      label="Descripción"
                      fullWidth
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        guardarMovimientoMedicamento({
                          ...movimientoMedicamento,
                          descripcion: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
