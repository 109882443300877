import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { NumericFormat } from "react-number-format";
import { Checkbox } from "primereact/checkbox";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";

export default function Colores(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Color", dataKey: "nombre" },
    { title: "Demora Llegada", dataKey: "demoraLlegada" },
    { title: "Alerta Llegada", dataKey: "alertaLlegada" },
    { title: "Demora Salida", dataKey: "demoraSalida" },
    { title: "Alerta Salida", dataKey: "alertaSalida" },
    { title: "Médico", dataKey: "medico" },
    { title: "Chofer", dataKey: "chofer" },
    { title: "Enfermero", dataKey: "enfermero" },
  ];

  const [color, guardarColor] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    colores,
    delay,
    obtenerColores,
    obtenerAcceso,
    agregarColor,
    actualizarColor,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Colores" });
    }
    obtenerColores();
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = (e) => {
    if (selectedRow) {
      guardarColor(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un color.",
        life: 3000,
      });
    }
  };

  const exitClick = (e) => {
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 2);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar
    if (
      !color ||
      color.nombre === undefined ||
      color.nombre === null ||
      color.nombre.trim() === ""
    ) {
      mensajeAlerta("Colores", "El nombre es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarColor({ color });
    } else {
      navigate(`/colores`);
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerColores();
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    const colorClassName = classNames({
      rojo: rowData.nombre === "ROJO",
      amarillo: rowData.nombre === "AMARILLO",
      verde: rowData.nombre === "VERDE",
      azul: rowData.nombre === "AZUL",
      fuccia: rowData.nombre === "FUCCIA",
      naranja: rowData.nombre === "NARANJA",
      blanco: rowData.nombre === "BLANCO",
      negro: rowData.nombre === "NEGRO",
      celeste: rowData.nombre === "CELESTE",
    });

    return (
      <Fragment>
        <span className="p-column-title">Color</span>
        <div className={colorClassName}>{rowData.nombre}</div>
      </Fragment>
    );
  };

  const demoraLlegadaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Demora Llegada</span>
        {rowData.demoraLlegada}
      </Fragment>
    );
  };
  const alertaLlegadaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Alerta Llegada</span>
        {rowData.alertaLlegada}
      </Fragment>
    );
  };

  const demoraSalidaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Demora Salida</span>
        {rowData.demoraSalida}
      </Fragment>
    );
  };
  const alertaSalidaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Alerta Salida</span>
        {rowData.alertaSalida}
      </Fragment>
    );
  };
  const medicoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Médico</span>
        <Checkbox checked={rowData.medico}></Checkbox>{" "}
      </Fragment>
    );
  };

  const choferTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Color</span>
        <Checkbox checked={rowData.chofer}></Checkbox>{" "}
      </Fragment>
    );
  };

  const enfermeroTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Enfermero</span>
        <Checkbox checked={rowData.enfermero}></Checkbox>{" "}
      </Fragment>
    );
  };

  const simultaneoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Simultáneo</span>
        <Checkbox checked={rowData.simultaneo}></Checkbox>{" "}
      </Fragment>
    );
  };

  const alertaPendienteTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Alerta Pendiente</span>
        <Checkbox checked={rowData.alertaPendiente}></Checkbox>{" "}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado de Colores", 60, 8);
        doc.autoTable(exportColumns, colores);
        //doc.save("colores.pdf");
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(colores);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "colores");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Colores"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Color"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(colores);
  //console.log(titular);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {colores ? (
            <Fragment>
              <Grid id="dataTableBotones" item xs={12} sm={12} md={12}>
                {/*
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                */}
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={colores}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        //style={{ width: "5%" }}
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Color"
                        //style={{ width: "20%" }}
                        body={nombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="demoraLlegada"
                        header="Demora Llegadas"
                        //style={{ width: "10%" }}
                        body={demoraLlegadaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="alertaLlegada"
                        header="Alerta Llegadas"
                        //style={{ width: "10%" }}
                        body={alertaLlegadaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="demoraSalida"
                        header="Demora Salidas"
                        //style={{ width: "10%" }}
                        body={demoraSalidaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="alertaSalida"
                        header="Alerta Salidas"
                        //style={{ width: "10%" }}
                        body={alertaSalidaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="alertaPendiente"
                        header="Alerta Pendientes"
                        //style={{ width: "10%" }}
                        body={alertaPendienteTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="medico"
                        header="Médico"
                        body={medicoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="chofer"
                        header="Chofer"
                        body={choferTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="enfermero"
                        header="Enfermero"
                        body={enfermeroTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="simultaneo"
                        header="Simultáneo"
                        body={simultaneoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>
              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={color ? color.id : null}
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={10}>
                    <TextField
                      value={color ? color.nombre : null}
                      required
                      id="nombre"
                      label="Color"
                      fullWidth
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <NumericFormat
                      {...props}
                      id="demoraLlegada"
                      label="Demora de llegadas en minutos"
                      customInput={TextField}
                      format="######"
                      type="numeric"
                      value={color ? color.demoraLlegada : null}
                      fullWidth
                      variant="standard"
                      size="small"
                      onChange={(e) =>
                        guardarColor({
                          ...color,
                          demoraLlegada: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <NumericFormat
                      {...props}
                      id="alertaLlegada"
                      label="Alerta de llegadas en minutos"
                      customInput={TextField}
                      format="######"
                      type="numeric"
                      value={color ? color.alertaLlegada : null}
                      fullWidth
                      variant="standard"
                      size="small"
                      onChange={(e) =>
                        guardarColor({
                          ...color,
                          alertaLlegada: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <NumericFormat
                      {...props}
                      id="demoraSalida"
                      label="Demora de salidas en minutos"
                      customInput={TextField}
                      format="######"
                      type="numeric"
                      value={color ? color.demoraSalida : null}
                      fullWidth
                      variant="standard"
                      size="small"
                      onChange={(e) =>
                        guardarColor({
                          ...color,
                          demoraSalida: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <NumericFormat
                      {...props}
                      id="alertaSalida"
                      label="Alerta de salidas en minutos"
                      customInput={TextField}
                      format="######"
                      type="numeric"
                      value={color ? color.alertaSalida : null}
                      fullWidth
                      variant="standard"
                      size="small"
                      onChange={(e) =>
                        guardarColor({
                          ...color,
                          alertaSalida: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginLeft: "1vw" }}>
                    <Typography variant="h6" gutterBottom>
                      Tripulación necesaria
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={color ? color.medico : null}
                          style={{ marginLeft: "1em" }}
                          onChange={(e) =>
                            guardarColor({
                              ...color,
                              medico: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Médico"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={6} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={color ? color.chofer : null}
                          style={{ marginLeft: "1em" }}
                          onChange={(e) =>
                            guardarColor({
                              ...color,
                              chofer: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Chofer"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={6} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={color ? color.enfermero : null}
                          style={{ marginLeft: "1em" }}
                          onChange={(e) =>
                            guardarColor({
                              ...color,
                              enfermero: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Enfermero"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={6} md={3} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={color ? color.simultaneo : null}
                          style={{ marginLeft: "1em" }}
                          onChange={(e) =>
                            guardarColor({
                              ...color,
                              simultaneo: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Simultáneo"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={6} md={4} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          color="primary"
                          checked={color ? color.alertaPendiente : null}
                          style={{ marginLeft: "1em" }}
                          onChange={(e) =>
                            guardarColor({
                              ...color,
                              alertaPendiente: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Alerta Pendiente"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
