import React, { Fragment, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AlertProvider } from "./context/AlertContext";
import { ServiciosProvider } from "./context/ServiciosContext";
import { ConfiguracionProvider } from "./context/ConfiguracionContext";
import { MapsProvider } from "./context/MapsContext";
import Barra from "./components/layout/Barra";
//import Footer from "./components/layout/Footer";
import RutaPrivada from "./components/rutas/RutaPrivada";
import Account from "./components/auth/Account";
import Login from "./components/auth/Login";
import Logo from "./components/layout/Logo";
import Reset from "./components/auth/Reset";
import Change from "./components/auth/Change";
import NumeroInterno from "./components/layout/configuracion/NumeroInterno";
import Parametros from "./components/layout/configuracion/Parametros";
import Accesos from "./components/layout/configuracion/Accesos";
import Despachos from "./components/layout/servicios/Despachos";
import TomaServicio from "./components/layout/servicios/TomaServicio";
import Medicos from "./components/layout/configuracion/archivos/Medicos";
import Finalizados from "./components/layout/servicios/Finalizados";
import Anulados from "./components/layout/servicios/Anulados";
import Pendientes from "./components/layout/servicios/Pendientes";
import ListadosServicios from "./components/layout/listados/ListadosServicios";
import ListadosLaborales from "./components/layout/listados/ListadosLaborales";
import ListadosDemoras from "./components/layout/listados/ListadosDemoras";
import ListadosTraslados from "./components/layout/listados/ListadosTraslados";
import ListadosTopes from "./components/layout/listados/ListadosTopes";
import ListadosHorariosTripulacion from "./components/layout/listados/ListadosHorariosTripulacion";
import ListadosLiquidacionMedicos from "./components/layout/listados/ListadosLiquidacionMedicos";
import ListadosLiquidacionChoferes from "./components/layout/listados/ListadosLiquidacionChoferes";
import ListadosLiquidacionEnfermeros from "./components/layout/listados/ListadosLiquidacionEnfermeros";
import EstadisticaServicioMesaMes from "./components/layout/listados/EstadisticaServiciosMesaMes";
import ListadosProgramacionTripulaciones from "./components/layout/listados/ListadosProgramacionTripulaciones";
import EstadisticaServiciosSemanales from "./components/layout/listados/EstadisticaServiciosSemanales";
import Antecedentes from "./components/layout/configuracion/archivos/Antecedentes";
import Tratamientos from "./components/layout/configuracion/archivos/Tratamientos";
import AlmacenesMoviles from "./components/layout/configuracion/archivos/AlmacenesMoviles";
import Almacenes from "./components/layout/configuracion/archivos/Almacenes";
import MovimientosMedicamentos from "./components/layout/configuracion/archivos/MovimientosMedicamentos";
import StocksMedicamentos from "./components/layout/configuracion/archivos/StocksMedicamentos";
import AjustesStocksMedicamentos from "./components/layout/configuracion/archivos/AjustesStocksMedicamentos";
import Medicamentos from "./components/layout/configuracion/archivos/Medicamentos";
import Tripulaciones from "./components/layout/servicios/Tripulaciones";
import TiposServicios from "./components/layout/configuracion/archivos/TiposServicios";
import MotivosConsultas from "./components/layout/configuracion/archivos/MotivosConsultas";
import Choferes from "./components/layout/configuracion/archivos/Choferes";
import Enfermeros from "./components/layout/configuracion/archivos/Enfermeros";
import TiposMoviles from "./components/layout/configuracion/archivos/TiposMoviles";
import MovilesPropios from "./components/layout/configuracion/archivos/MovilesPropios";
import MovilesDerivados from "./components/layout/configuracion/archivos/MovilesDerivados";
import Telefonos from "./components/layout/configuracion/archivos/Telefonos";
import Topes from "./components/layout/configuracion/archivos/Topes";
import Bases from "./components/layout/configuracion/archivos/Bases";
import Diagnosticos from "./components/layout/configuracion/archivos/Diagnosticos";
import Colores from "./components/layout/configuracion/archivos/Colores";
import TripulacionesProgramacion from "./components/layout/servicios/TripulacionesProgramacion";
import Traslados from "./components/layout/servicios/Traslados";
import Eventos from "./components/layout/servicios/Eventos";
import Coseguros from "./components/layout/configuracion/archivos/Coseguros";
import Mapa from "./components/layout/maps/Mapa";
import Feriados from "./components/layout/servicios/Feriados";
import ValorizacionesServicios from "./components/layout/servicios/ValorizacionesServicios";
import Tickets from "./components/layout/utiles/Tickets";
import Version from "./components/layout/utiles/Version";
import ProcesosPami from "./components/layout/procesos/ProcesosPami";

/*
 */

// NO VA import LoginMedicos from "./components/auth/LoginMedicos";
// NO VA import Alertas from "./components/layout/configuracion/Alertas";
// NO VC import CorreccionServicios from "./components/layout/servicios/CorreccionServicios";
// NO VA import Firebase from "./components/layout/Firebase";
//import Prueba from "./components/layout/servicios/Prueba";
// NO VA import firebase, { FirebaseContext } from "./firebase";

function App() {
  return (
    /*
    <FirebaseContext.Provider
      value={{
        firebase,
      }}
    >
    */

    <MapsProvider>
      <AlertProvider>
        <AuthProvider>
          <ServiciosProvider>
            <ConfiguracionProvider>
              <BrowserRouter>
                <Routes>
                  {/*<Route exact path="/firebase" component={Firebase} />*/}
                  <Route path="/login" element={<Login />} />
                  <Route path="change/:token" element={<Change />} />
                  <Route path="/reset" element={<Reset />} />
                  <Route path="/" element={<Logo />} />
                  <Route
                    path="/account"
                    element={
                      <RutaPrivada>
                        <Account />
                      </RutaPrivada>
                    }
                  />

                  <Route
                    path="/numerointerno"
                    element={
                      <RutaPrivada>
                        <NumeroInterno />
                      </RutaPrivada>
                    }
                  />

                  <Route
                    path="/parametros"
                    element={
                      <RutaPrivada>
                        <Parametros />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/accesos"
                    element={
                      <RutaPrivada>
                        <Accesos />
                      </RutaPrivada>
                    }
                  />

                  <Route
                    path="/despachos"
                    element={
                      <RutaPrivada>
                        <Despachos />
                      </RutaPrivada>
                    }
                  />

                  <Route
                    path="/tomaservicio"
                    element={
                      <RutaPrivada>
                        <TomaServicio />
                      </RutaPrivada>
                    }
                  />

                  <Route
                    path="/antecedentes"
                    element={
                      <RutaPrivada>
                        <Antecedentes />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/tratamientos"
                    element={
                      <RutaPrivada>
                        <Tratamientos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/almacenesmoviles"
                    element={
                      <RutaPrivada>
                        <AlmacenesMoviles />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/almacenes"
                    element={
                      <RutaPrivada>
                        <Almacenes />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/movimientosmedicamentos"
                    element={
                      <RutaPrivada>
                        <MovimientosMedicamentos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/stocksmedicamentos"
                    element={
                      <RutaPrivada>
                        <StocksMedicamentos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/ajustesstocksmedicamentos"
                    element={
                      <RutaPrivada>
                        <AjustesStocksMedicamentos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/medicamentos"
                    element={
                      <RutaPrivada>
                        <Medicamentos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/tripulaciones"
                    element={
                      <RutaPrivada>
                        <Tripulaciones />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/medicos"
                    element={
                      <RutaPrivada>
                        <Medicos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/serviciosfinalizados"
                    element={
                      <RutaPrivada>
                        <Finalizados />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/serviciosanulados"
                    element={
                      <RutaPrivada>
                        <Anulados />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/serviciospendientes"
                    element={
                      <RutaPrivada>
                        <Pendientes />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadosservicios"
                    element={
                      <RutaPrivada>
                        <ListadosServicios />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadoslaborales"
                    element={
                      <RutaPrivada>
                        <ListadosLaborales />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadosdemoras"
                    element={
                      <RutaPrivada>
                        <ListadosDemoras />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadostraslados"
                    element={
                      <RutaPrivada>
                        <ListadosTraslados />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadostopes"
                    element={
                      <RutaPrivada>
                        <ListadosTopes />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadoshorariostripulacion"
                    element={
                      <RutaPrivada>
                        <ListadosHorariosTripulacion />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadosliquidacionmedicos"
                    element={
                      <RutaPrivada>
                        <ListadosLiquidacionMedicos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadosliquidacionchoferes"
                    element={
                      <RutaPrivada>
                        <ListadosLiquidacionChoferes />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadosliquidacionenfermeros"
                    element={
                      <RutaPrivada>
                        <ListadosLiquidacionEnfermeros />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/estadisticaserviciosmesames"
                    element={
                      <RutaPrivada>
                        <EstadisticaServicioMesaMes />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/listadosprogramaciontripulaciones"
                    element={
                      <RutaPrivada>
                        <ListadosProgramacionTripulaciones />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/estadisticaserviciossemanales"
                    element={
                      <RutaPrivada>
                        <EstadisticaServiciosSemanales />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/tiposservicios"
                    element={
                      <RutaPrivada>
                        <TiposServicios />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/motivosconsultas"
                    element={
                      <RutaPrivada>
                        <MotivosConsultas />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/choferes"
                    element={
                      <RutaPrivada>
                        <Choferes />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/enfermeros"
                    element={
                      <RutaPrivada>
                        <Enfermeros />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/tiposmoviles"
                    element={
                      <RutaPrivada>
                        <TiposMoviles />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/movilespropios"
                    element={
                      <RutaPrivada>
                        <MovilesPropios />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/movilesderivados"
                    element={
                      <RutaPrivada>
                        <MovilesDerivados />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/telefonos"
                    element={
                      <RutaPrivada>
                        <Telefonos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/topes"
                    element={
                      <RutaPrivada>
                        <Topes />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/bases"
                    element={
                      <RutaPrivada>
                        <Bases />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/diagnosticos"
                    element={
                      <RutaPrivada>
                        <Diagnosticos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/colores"
                    element={
                      <RutaPrivada>
                        <Colores />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/tripulacionesprogramacion"
                    element={
                      <RutaPrivada>
                        <TripulacionesProgramacion />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/traslados"
                    element={
                      <RutaPrivada>
                        <Traslados />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/eventos"
                    element={
                      <RutaPrivada>
                        <Eventos />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/coseguros"
                    element={
                      <RutaPrivada>
                        <Coseguros />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/mapa"
                    element={
                      <RutaPrivada>
                        <Mapa />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/feriados"
                    element={
                      <RutaPrivada>
                        <Feriados />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/valorizarservicios"
                    element={
                      <RutaPrivada>
                        <ValorizacionesServicios />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/tickets"
                    element={
                      <RutaPrivada>
                        <Tickets />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/version"
                    element={
                      <RutaPrivada>
                        <Version />
                      </RutaPrivada>
                    }
                  />
                  <Route
                    path="/procesospami"
                    element={
                      <RutaPrivada>
                        <ProcesosPami />
                      </RutaPrivada>
                    }
                  />
                  {/*
                    <RutaPrivada
                      exact
                      path="/serviciosfinalizados"
                      component={Finalizados}
                    />
                    <RutaPrivada
                      exact
                      path="/correccionservicios"
                      component={CorreccionServicios}
                    />
                    <RutaPrivada
                      exact
                      path="/serviciosanulados"
                      component={Anulados}
                    />
                    <RutaPrivada
                      exact
                      path="/serviciospendientes"
                      component={Pendientes}
                    />
                    <RutaPrivada
                      exact
                      path="/listadosservicios"
                      component={ListadosServicios}
                    />
                    <RutaPrivada
                      exact
                      path="/listadoslaborales"
                      component={ListadosLaborales}
                    />
                    <RutaPrivada
                      exact
                      path="/listadostraslados"
                      component={ListadosTraslados}
                    />
                    <RutaPrivada
                      exact
                      path="/listadosdemoras"
                      component={ListadosDemoras}
                    />
                    <RutaPrivada
                      exact
                      path="/listadostopes"
                      component={ListadosTopes}
                    />
                    <RutaPrivada
                      exact
                      path="/listadoshorariostripulacion"
                      component={ListadosHorariosTripulacion}
                    />
                    <RutaPrivada
                      exact
                      path="/listadosprogramaciontripulaciones"
                      component={ListadosProgramacionTripulaciones}
                    />
                    <RutaPrivada
                      exact
                      path="/listadosliquidacionmedicos"
                      component={ListadosLiquidacionMedicos}
                    />
                    <RutaPrivada
                      exact
                      path="/estadisticaserviciosmesames"
                      component={EstadisticaServicioMesaMes}
                    />
                    <RutaPrivada
                      exact
                      path="/estadisticaserviciossemanales"
                      component={EstadisticaServiciosSemanales}
                    />
                    <RutaPrivada
                      exact
                      path="/tomaservicio"
                      component={TomaServicio}
                    />
                    <RutaPrivada
                      exact
                      path="/tratamientos"
                      component={Tratamientos}
                    />
                    <RutaPrivada
                      exact
                      path="/tiposservicios"
                      component={TiposServicios}
                    />
                    <RutaPrivada
                      exact
                      path="/motivosconsultas"
                      component={MotivosConsultas}
                    />
                    <RutaPrivada exact path="/medicos" component={Medicos} />
                    <RutaPrivada exact path="/choferes" component={Choferes} />
                    <RutaPrivada
                      exact
                      path="/enfermeros"
                      component={Enfermeros}
                    />
                    <RutaPrivada
                      exact
                      path="/tiposmoviles"
                      component={TiposMoviles}
                    />
                    <RutaPrivada
                      exact
                      path="/movilespropios"
                      component={MovilesPropios}
                    />
                    <RutaPrivada
                      exact
                      path="/movilesderivados"
                      component={MovilesDerivados}
                    />
                    <RutaPrivada
                      exact
                      path="/telefonos"
                      component={Telefonos}
                    />
                    <RutaPrivada exact path="/bases" component={Bases} />
                    <RutaPrivada
                      exact
                      path="/medicamentos"
                      component={Medicamentos}
                    />
                    <RutaPrivada
                      exact
                      path="/diagnosticos"
                      component={Diagnosticos}
                    />
                    <RutaPrivada exact path="/topes" component={Topes} />
                    <RutaPrivada
                      exact
                      path="/almacenesmoviles"
                      component={AlmacenesMoviles}
                    />
                    <RutaPrivada
                      exact
                      path="/almacenes"
                      component={Almacenes}
                    />
                    <RutaPrivada
                      exact
                      path="/movimientosmedicamentos"
                      component={MovimientosMedicamentos}
                    />
                    <RutaPrivada
                      exact
                      path="/stocksmedicamentos"
                      component={StocksMedicamentos}
                    />
                    <RutaPrivada
                      exact
                      path="/ajustesstocksmedicamentos"
                      component={AjustesStocksMedicamentos}
                    />
                    <RutaPrivada exact path="/colores" component={Colores} />
                    <RutaPrivada
                      exact
                      path="/tripulaciones"
                      component={Tripulaciones}
                    />
                    <RutaPrivada
                      exact
                      path="/tripulacionesprogramacion"
                      component={TripulacionesProgramacion}
                    />
                    <RutaPrivada
                      exact
                      path="/coseguros"
                      component={Coseguros}
                    />
                    <RutaPrivada
                      exact
                      path="/traslados"
                      component={Traslados}
                    />
                    <RutaPrivada exact path="/eventos" component={Eventos} />
                    <RutaPrivada exact path="/mapa" component={Mapa} />
                    <RutaPrivada exact path="/feriados" component={Feriados} />
                    <RutaPrivada exact path="/tickets" component={Tickets} />
                    <RutaPrivada
                      exact
                      path="/valorizarservicios"
                      component={ValorizacionesServicios}
                    />
                    */}
                </Routes>
              </BrowserRouter>
            </ConfiguracionProvider>
          </ServiciosProvider>
        </AuthProvider>
      </AlertProvider>
    </MapsProvider>
    /*</FirebaseContext.Provider>*/
  );
}

export default App;
