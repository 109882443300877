import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import { Link, Redirect } from "react-router-dom";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { Button } from "primereact/button";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";



export default function ResumenServicio(props) {
  //const classes = useStyles();
  const [value, setValue] = useState(null);
  const [value2, setValue2] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const [center, setCenter] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [selected, setSelected] = useState(null);
  const [map, setMap] = useState(null);
  const [afiliadoCartera, guardarAfiliadoCartera] = useState(null);
  const [iniciarCursor, setIniciarCursor] = useState(0);

  const serviciosContext = useContext(ServiciosContext);
  const { resumen, demorados } = serviciosContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });


  //const theme = useTheme();

  //console.log(demorados)


  return (
    <Fragment>
      {resumen && demorados ? (
        <Grid
          container
          spacing={0}
          style={{ marginTop: "1em", marginLeft: ".3em", paddingLeft: "4em" }}
        >
          <Grid item xs={12} md={12} style={{ marginTop: "2em" }}>
            <Button
              label={
                resumen.rojoTotales +
                "/" +
                resumen.rojoPendientes +
                "/" +
                demorados.rojoDemorados
              }
              className="p-button-rounded"
              style={{
                backgroundColor: "red",
                color: "black",
                fontWeight: "bold",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
            <Button
              label={
                resumen.amarilloTotales +
                "/" +
                resumen.amarilloPendientes +
                "/" +
                demorados.amarilloDemorados
              }
              className="p-button-rounded"
              style={{
                backgroundColor: "yellow",
                color: "black",
                fontWeight: "bold",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
            <Button
              label={
                resumen.verdeTotales +
                "/" +
                resumen.verdePendientes +
                "/" +
                demorados.verdeDemorados
              }
              className="p-button-rounded"
              style={{
                backgroundColor: "green",
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
            <Button
              label={
                resumen.fucciaTotales +
                "/" +
                resumen.fucciaPendientes +
                "/" +
                demorados.fucciaDemorados
              }
              className="p-button-rounded"
              style={{
                backgroundColor: "fuchsia",
                color: "black",
                fontWeight: "bold",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
            <Button
              label={
                resumen.azulTotales +
                "/" +
                resumen.azulPendientes +
                "/" +
                demorados.azulDemorados
              }
              className="p-button-rounded"
              style={{
                backgroundColor: "blue",
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
            <Button
              label={
                resumen.naranjaTotales +
                "/" +
                resumen.naranjaPendientes +
                "/" +
                demorados.naranjaDemorados
              }
              className="p-button-rounded"
              style={{
                backgroundColor: "orange",
                color: "black",
                fontWeight: "bold",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
            <Button
              label={
                resumen.blancoTotales +
                "/" +
                resumen.blancoPendientes +
                "/" +
                demorados.blancoDemorados
              }
              className="p-button-rounded"
              style={{
                backgroundColor: "white",
                color: "black",
                fontWeight: "bold",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
            <Button
              label={
                resumen.negroTotales +
                "/" +
                resumen.negroPendientes +
                "/" +
                demorados.negroDemorados
              }
              className="p-button-rounded"
              style={{
                backgroundColor: "black",
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
            <Button
              label={
                resumen.celesteTotales +
                "/" +
                resumen.celestePendientes +
                "/" +
                demorados.celesteDemorados
              }
              className="p-button-rounded"
              style={{
                backgroundColor: "aqua",
                color: "black",
                fontWeight: "bold",
              }}
            />
          </Grid>
        </Grid>
      ) : null}
    </Fragment>
  );
}
