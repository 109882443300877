import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { NumericFormat } from "react-number-format";
import { Checkbox } from "primereact/checkbox";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";

export default function MovilesPropios(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Detalle", dataKey: "detalle" },
    { title: "Matrícula", dataKey: "matricula" },
    { title: "Marca", dataKey: "marca" },
    { title: "Modelo", dataKey: "modelo" },
    { title: "Seguro", dataKey: "seguro" },
    { title: "Póliza", dataKey: "poliza" },
    { title: "Tipo de móvil", dataKey: "tipoMovil" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [value, setValue] = useState(0);
  const [movil, guardarMovil] = useState(null);
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const toast = useRef(null);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    moviles,
    tiposMoviles,
    obtenerMoviles,
    obtenerAcceso,
    agregarMovil,
    actualizarMovil,
    obtenerTiposMoviles,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Moviles" });
    }
    obtenerTiposMoviles();
    obtenerMoviles({ todos: true, propios: true });
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = (e) => {
    if (selectedRow) {
      guardarMovil(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un móvil.",
        life: 3000,
      });
    }
  };

  const addClick = (e) => {
    //navigate(`/base/0`);
    setVisible(true);
    setSelectedRow(null);
    guardarMovil({
      id: minID(),
      nombre: "MOVIL " + minID().toString().padStart(3, 0),
      propio: true,
      detalle: null,
      matricula: null,
      marca: null,
      modelo: null,
      seguro: null,
      poliza: null,
      telefono: null,
      contacto: null,
      habilitado: true,
      nocturnoDesde: null,
      nocturnoHasta: null,
      pediatrico: null,
      tripulacionId: null,
    });
  };

  const exitClick = (e) => {
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(3, 0);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar

    if (!movil || movil.id === undefined || movil.id === null) {
      mensajeAlerta(
        "Móviles Propios",
        "El ID de móvil es necesario !.",
        "error"
      );
      return;
    }

    if (
      !movil ||
      movil.id === undefined ||
      movil.id === null ||
      movil.id <= 0 ||
      movil.id > 999
    ) {
      mensajeAlerta(
        "Móviles Propios",
        "El ID de móvil es incorrecto (1 - 999) !.",
        "error"
      );
      return;
    }

    if (
      !movil ||
      movil.nombre === undefined ||
      movil.nombre === null ||
      movil.nombre.trim() === ""
    ) {
      mensajeAlerta("Móviles Propios", "El nombre es necesario !.", "error");
      return;
    }

    if (
      !movil ||
      movil.tipoId === undefined ||
      movil.tipoId === null ||
      movil.tipoId <= 0
    ) {
      mensajeAlerta(
        "Móviles Propios",
        "El tipo de móvil es necesario !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarMovil({ movil });
    } else {
      agregarMovil({ movil });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerMoviles({ todos: true, propios: true });
    }, 2000);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const detalleTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Detalle</span>
        {rowData.detalle}
      </Fragment>
    );
  };

  const matriculaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Matrícula</span>
        {rowData.matricula}
      </Fragment>
    );
  };

  const marcaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Marca</span>
        {rowData.marca}
      </Fragment>
    );
  };

  const modeloTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Modelo</span>
        {rowData.modelo}
      </Fragment>
    );
  };

  const seguroTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Seguro</span>
        {rowData.seguro}
      </Fragment>
    );
  };

  const tipoMovilTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tipo de móvil</span>
        {rowData.tipoMovil}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Habilitado</span>
        <Checkbox checked={rowData.habilitado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado de Móviles Propios", 60, 8);
        doc.autoTable(exportColumns, moviles);
        //doc.save("movilespropios.pdf");
        window.open(doc.output("bloburl"));
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(moviles);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "movilespropios");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Móviles propios"></Tag>
      </div>
    </Fragment>
  );

  const minID = () => {
    for (var id = 1; id < 1000; id++) {
      let index = moviles.findIndex((item) => item.id === id);
      if (index === -1) {
        return id;
      }
    }
  };

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Móvil Propio"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(movil);
  //console.log(titular);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {moviles && tiposMoviles ? (
            <Fragment>
              <Grid id="dataTableBotones" item xs={12} sm={12} md={12}>
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-pencil"
                  tooltip="Editar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={editClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ align: "center", margin: "1em" }}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={moviles}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "xx-small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="nombre"
                        header="Nombre"
                        //style={{ width: "20%" }}
                        body={nombreTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="detalle"
                        header="Detalle"
                        //style={{ width: "20%" }}
                        body={detalleTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="matricula"
                        header="Matrícula"
                        body={matriculaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="marca"
                        header="Marca"
                        body={marcaTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="modelo"
                        header="Modelo"
                        body={modeloTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="seguro"
                        header="Seguro"
                        body={seguroTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="tipoMovil"
                        header="Tipo de móvil"
                        body={tipoMovilTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="habilitado"
                        header="Habilitado"
                        body={habilitadoTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <TabView
                  style={{ marginTop: "1vw" }}
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                >
                  <TabPanel header="Datos del móvil">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={2}>
                        <NumericFormat
                          {...props}
                          value={movil ? movil.id : null}
                          required
                          id="id"
                          label="ID"
                          customInput={TextField}
                          type="numeric"
                          disabled={selectedRow}
                          fullWidth
                          variant="standard"
                          onChange={(e) =>
                            guardarMovil({
                              ...movil,
                              id: e.target.value,
                              nombre: "MOVIL " + e.target.value.padStart(3, 0),
                            })
                          }
                          //onBlur={() => console.log("focus lost") }
                        />
                      </Grid>

                      <Grid item xs={12} md={10}>
                        <TextField
                          value={movil ? movil.nombre : null}
                          required
                          id="nombre"
                          label="Nombre"
                          fullWidth
                          variant="standard"
                          disabled
                          onChange={(e) =>
                            guardarMovil({
                              ...movil,
                              nombre: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          value={movil ? movil.detalle : null}
                          id="detalle"
                          label="Detalle"
                          fullWidth
                          variant="standard"
                          onChange={(e) =>
                            guardarMovil({
                              ...movil,
                              detalle: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          value={movil ? movil.matricula : null}
                          id="matricula"
                          label="Matrícula"
                          fullWidth
                          variant="standard"
                          onChange={(e) =>
                            guardarMovil({
                              ...movil,
                              matricula: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          value={movil ? movil.marca : null}
                          id="marca"
                          label="Marca"
                          fullWidth
                          variant="standard"
                          onChange={(e) =>
                            guardarMovil({
                              ...movil,
                              marca: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={6} md={4}>
                        <TextField
                          value={movil ? movil.modelo : null}
                          id="modelo"
                          label="Modelo"
                          fullWidth
                          variant="standard"
                          onChange={(e) =>
                            guardarMovil({
                              ...movil,
                              modelo: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={6} md={3}>
                        <TextField
                          value={movil ? movil.seguro : null}
                          id="seguro"
                          label="Seguro"
                          fullWidth
                          variant="standard"
                          onChange={(e) =>
                            guardarMovil({
                              ...movil,
                              seguro: e.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={6} md={3}>
                        <TextField
                          value={movil ? movil.poliza : null}
                          id="poliza"
                          label="Póliza"
                          fullWidth
                          variant="standard"
                          onChange={(e) =>
                            guardarMovil({
                              ...movil,
                              poliza: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Autocomplete
                          id="tiposMoviles"
                          fullWidth
                          options={tiposMoviles}
                          disablePortal
                          //defaultValue={tiposMoviles[idTipoMovilDefault]}
                          defaultValue={
                            movil && movil.tipoId !== undefined
                              ? tiposMoviles[
                                  tiposMoviles.findIndex(
                                    (item) => item.id === movil.tipoId
                                  )
                                ]
                              : null
                          }
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de móvil"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarMovil({
                                ...movil,
                                tipoId: value.id,
                              });
                            } else {
                              guardarMovil({
                                ...movil,
                                tipoId: null,
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              id="habilitado"
                              color="primary"
                              checked={movil ? movil.habilitado : null}
                              onChange={(e) =>
                                guardarMovil({
                                  ...movil,
                                  habilitado: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Habilitado"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel header="Historial"></TabPanel>
                </TabView>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
