import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from '@mui/material/Fab';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from '@mui/material/Button';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "../servicios/ConsultaServicio";
import NotasServicio from "../servicios/NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import hcOffcanvasNav from "hc-offcanvas-nav";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

export default function ListadosProgramacionTripulaciones(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tripulante, guardarTripulante] = useState("medicos");
  let navigate = useNavigate();


  const exportColumns = [
    { title: "ID", dataKey: "ID" },
    { title: "Base", dataKey: "Base" },
    { title: "Móvil", dataKey: "Movil" },
    { title: "Médico", dataKey: "NombreMedico" },
    { title: "Ing. Méd.", dataKey: "FechaIngresoMedico" },
    { title: "Sal. Méd", dataKey: "FechaSalidaMedico" },
    { title: "Chofer", dataKey: "NombreChofer" },
    { title: "Ing. Cho.", dataKey: "FechaIngresoChofer" },
    { title: "Sal. Cho", dataKey: "FechaSalidaChofer" },
    { title: "Enfermero", dataKey: "NombreEnfermero" },
    { title: "Ing. Enf.", dataKey: "FechaIngresoEnfermero" },
    { title: "Sal. Enf", dataKey: "FechaSalidaEnfermero" },
  ];

  const exportColumns2 = [
    { title: "ID", dataKey: "ID" },
    { title: "Base", dataKey: "Base" },
    { title: "Móvil", dataKey: "Movil" },
    { title: "Médico", dataKey: "NombreMedico" },
    { title: "Ing. Méd.", dataKey: "FechaIngresoMedico" },
    { title: "Sal. Méd", dataKey: "FechaSalidaMedico" },
    { title: "Chofer", dataKey: "NombreChofer" },
    { title: "Ing. Cho.", dataKey: "FechaIngresoChofer" },
    { title: "Sal. Cho", dataKey: "FechaSalidaChofer" },
    { title: "Enfermero", dataKey: "NombreEnfermero" },
    { title: "Ing. Enf.", dataKey: "FechaIngresoEnfermero" },
    { title: "Sal. Enf", dataKey: "FechaSalidaEnfermero" },
    { title: "Armado", dataKey: "FechaArmado" },
    { title: "Cerrado", dataKey: "FechaCerrado" },
  ];

  const [visibleFechas, setVisibleFechas] = useState(true);
  const [fechas, setFechas] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const toast = useRef(null);
  const [radio, setRadio] = useState("tomado");
  const [tripulanteSeleccionado, setTripulanteSeleccionado] = useState(null);
  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    colores,
    moviles,
    medicos,
    choferes,
    enfermeros,
    obtenerAcceso,
    obtenerMedicos,
    obtenerChoferes,
    obtenerEnfermeros,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    serviciosFinalizados,
    serviciosFinalizadosExcel,
    obtenerHorariosTripulacionProgramacion,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Listados" });
    }
    obtenerMedicos({ todos: false });
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const exitClick = (e) => {
    onHideFechas();
   navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(1, 2);
  };

  const startClick = (e) => {
    setTimeout(() => {
      navigate(`/listadosprogramaciontripulaciones`);
      setVisibleFechas(true);
    }, 2000);
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    if (
      !fechas ||
      fechas.desde === undefined ||
      fechas.desde === null ||
      fechas.desde.trim() === ""
    ) {
      mensajeAlerta("Listado de Programación de Tripulaciones", "Fecha desde es necesaria !.", "error");
      return;
    }

    if (
      !fechas ||
      fechas.hasta === undefined ||
      fechas.hasta === null ||
      fechas.hasta.trim() === ""
    ) {
      mensajeAlerta("Listado de Programación de Tripulaciones", "Fecha hasta es necesaria !.", "error");
      return;
    }

    let fdesde = fechas.desde; //+ "T00:00:00";
    let fhasta = fechas.hasta; //+ "T23:59:59";

    var elem;

    await obtenerHorariosTripulacionProgramacion({
      fdesde: fdesde,
      fhasta: fhasta,
      tripulante,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHideFechas();
  };

  const accesoDenegado = () => {
    mensajeAlerta("Accesos", "No tiene permiso para acceder a esta pantalla !.", "error");
    exitClick();
    return;
  };

  const exportExcel = () => {
    if (serviciosFinalizadosExcel && serviciosFinalizadosExcel.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(serviciosFinalizadosExcel);
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "horariosProgramacionTripulaciones");
      });
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });

    startClick();
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default("l");
        doc.setFontSize(12);
        doc.text(
          "Listado de Horarios Programados desde " +
            formatDate(fechas.desde) +
            " hasta " +
            formatDate(fechas.hasta),
          50,
          8
        );
        doc.autoTable(exportColumns, serviciosFinalizadosExcel, {
          styles: {
            fontSize: 7,
          },
        });
        window.open(doc.output("bloburl"));
      });
    });
    startClick();
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Listado de Programación de Tripulaciónes"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>

        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarFechas("excel");
            //exportExcel();
          }}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        >
          Excel
        </Button>
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarFechas("pdf");
          }}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        >
          PDF
        </Button>
        {/*
        <ButtonMui
          onClick={aceptarFechas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        */}
        <ButtonMui
          onClick={exitClick}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(medicos);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {tripulante ? (
            <Fragment>
              <Dialog
                header={renderHeaderFechas()}
                visible={visibleFechas}
                closable={false}
                footer={renderFooterFechas()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", paddingBottom: "0" }}
                position="top"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="desde"
                      label="Desde"
                      type="datetime-local"
                      fullWidth
                      variant="standard"
                      //onKeyDown={handleEnter}
                      value={fechas && fechas.desde ? fechas.desde : null}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setFechas({
                          ...fechas,
                          desde: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="hasta"
                      label="Hasta"
                      type="datetime-local"
                      fullWidth
                      variant="standard"
                      //onKeyDown={handleEnter}
                      value={fechas && fechas.hasta ? fechas.hasta : null}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setFechas({
                          ...fechas,
                          hasta: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Fragment>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ paddingLeft: 0, marginTop: "0em" }}
                    />

                    <Grid item xs={12} md={12}>
                      <label htmlFor="medicos">Médicos</label>
                      <RadioButton
                        inputId="medicos"
                        value="medicos"
                        name="medicos"
                        onChange={(e) => {
                          guardarTripulante(e.value);
                          obtenerMedicos({ todos: false });
                        }}
                        checked={tripulante === "medicos"}
                        style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                      />
                      <label style={{ marginLeft: "1vw" }} htmlFor="choferes">
                        Choferes
                      </label>
                      <RadioButton
                        inputId="choferes"
                        value="choferes"
                        name="choferes"
                        onChange={(e) => {
                          guardarTripulante(e.value);
                          obtenerChoferes({ todos: false });
                        }}
                        checked={tripulante === "choferes"}
                        style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                      />
                      <label style={{ marginLeft: "1vw" }} htmlFor="enfermeros">
                        Enfermeros
                      </label>
                      <RadioButton
                        inputId="enfermeros"
                        value="enfermeros"
                        name="enfermeros"
                        onChange={(e) => {
                          guardarTripulante(e.value);
                          obtenerEnfermeros({ todos: false });
                        }}
                        checked={tripulante === "enfermeros"}
                        style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
                      />
                    </Grid>
                  </Fragment>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
