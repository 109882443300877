import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from '@mui/material/Fab';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from '@mui/material/Button';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "./ConsultaServicio";
import NotasServicio from "./NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import FlagIcon from "@mui/icons-material/Flag";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import HouseIcon from "@mui/icons-material/House";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import classNames from "classnames";
import AdjustIcon from "@mui/icons-material/Adjust";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";



export default function Pendientes(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();


  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [visibleFechas, setVisibleFechas] = useState(false);
  const [fechas, setFechas] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const toast = useRef(null);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [servicio, setServicio] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [radio, setRadio] = useState("tomado");
  const [val, setVal] = useState(null);
  const [agrupado, setAgrupado] = useState(null);
  const [progress, setProgress] = useState(false);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    configuracion,
    colores,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerColores,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    despachos,
    tripulaciones,
    filtrosListadosServicios,
    serviciosAnuladosExcel,
    obtenerServiciosPendientes,
    obtenerNotas,
    guardarServicio,
    obtenerTripulaciones,
    guardarFiltrosListadosServicios,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Pendientes" });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerServiciosPendientes();
    obtenerTripulaciones({ estado: false });
    obtenerColores()
  }, []);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 2000);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const calendar = (e) => {
    setVisibleFechas(true);
  };

  const exitClick = (e) => {
   navigate(`/`);
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const onHideUpload = (e) => {
    setVisibleUpload(false);
  };

  const accesoDenegado = () => {
    mensajeAlerta("Accesos", "No tiene permiso para acceder a esta pantalla !.", "error");
    exitClick();
    return;
  };

  const indicativoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">
          {configuracion && configuracion.destinatario !== 2 ? "Indicativo" : "Ind. - Id"}
        </span>
        {configuracion && configuracion.destinatario !== 2 ? rowData.id : rowData.indicativoId}
        {rowData.reclamado ? (
          <MoodBadIcon style={{ fontSize: "small", color: "red" }} />
        ) : null}
      </Fragment>
    );
  };

  const colorBodyTemplate = (rowData) => {
    const colorClassName = classNames({
      rojo: rowData.color === "ROJO",
      amarillo: rowData.color === "AMARILLO",
      verde: rowData.color === "VERDE",
      azul: rowData.color === "AZUL",
      celeste: rowData.color === "CELESTE",
      fuccia: rowData.color === "FUCCIA",
      naranja: rowData.color === "NARANJA",
      blanco: rowData.color === "BLANCO",
      negro: rowData.color === "NEGRO",
    });

    return (
      <Fragment>
        <span className="p-column-title">Color</span>
        <div className={colorClassName}>
          {rowData.color + " / " + rowData.motivoConsulta}
        </div>
      </Fragment>
    );
  };

  const grupoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Grupo</span>
        {rowData.grupo}
      </Fragment>
    );
  };

  const domicilioBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const localidadBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Localidad</span>
        {!rowData.fueraZona
          ? rowData.localidad
          : rowData.localidad + " " + "(FZ)"}
      </Fragment>
    );
  };

  const nombreBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const edadBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Edad</span>
        {rowData.edad}
      </Fragment>
    );
  };

  const tripula = (movil) => {
    return tripulaciones.find((item) => item.movil === movil).servicioId;
  };

  const simultaneo = (color) => {
    return colores.find((item) => item.nombre === color).simultaneo;
  };

  const movilBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Móvil</span>
        {rowData.movilId !== null ? "Móvil" + " " + rowData.movilId : null}
      </Fragment>
    );
  };

  const movilBodyTemplateBak = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Móvil</span>
        {rowData.movilId} {"   "}
        {tripulaciones &&
        colores &&
        rowData.movilId !== null &&
        rowData.id === tripula(rowData.movilId) &&
        simultaneo(rowData.color) ? (
          <Fragment>
            <AdjustIcon style={{ fontSize: "small" }} />
          </Fragment>
        ) : null}
      </Fragment>
    );
  };

  const estadoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        {rowData.estado.substring(0, 8) === "TOMADO /" ? (
          <HouseIcon style={{ fontSize: "small", color: "red" }} />
        ) : rowData.estado.substring(0, 6) === "TOMADO" ? (
          <HouseIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        {rowData.estado.substring(0, 8) === "PASADO /" ? (
          <PhoneForwardedIcon style={{ fontSize: "small", color: "red" }} />
        ) : rowData.estado.substring(0, 6) === "PASADO" ? (
          <PhoneForwardedIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        {rowData.estado.substring(0, 8) === "SALIDA /" ? (
          <LocalShippingIcon style={{ fontSize: "small", color: "red" }} />
        ) : rowData.estado.substring(0, 6) === "SALIDA" ? (
          <LocalShippingIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        {rowData.estado.substring(0, 9) === "LLEGADA /" ? (
          <FlagIcon style={{ fontSize: "small", color: "red" }} />
        ) : rowData.estado.substring(0, 7) === "LLEGADA" ? (
          <FlagIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        {rowData.estado.substring(0, 8) === "TRASLADO" ? (
          <ImportExportIcon style={{ fontSize: "small", color: "green" }} />
        ) : null}
        {"         "}

        <span className="p-column-title">Estado</span>
        {rowData.estado}
      </Fragment>
    );
  };

  const tomadoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tomado</span>
        {rowData.tomado}
      </Fragment>
    );
  };

  const pasadoBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pasado</span>
        {rowData.pasado}
      </Fragment>
    );
  };

  const salidaBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Salida</span>
        {rowData.salida}
      </Fragment>
    );
  };

  const llegadaBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Llegada</span>
        {rowData.llegada}
      </Fragment>
    );
  };

  const consultaSocio = (rowData) => {
    guardarServicio({
      ...rowData,
      origen: "despachos",
    });
    setVisible(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            margin: 0,
            padding: 0,
          }}
          icon="pi pi-search"
          className="p-button-success p-button-text"
          onClick={() => {
            consultaSocio(rowData);
            obtenerNotas({ servicioId: rowData.id });
          }}
          tooltip="Datos del servicio"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, despachos);
        doc.save("pendientes.pdf");
      });
    });
  };

  const exportExcel = () => {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(despachos);
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "serviciosPendientes");
      });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        {/*
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        */}
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Servicios Pendientes"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <Fragment>
        <div align="left">
          <Tag value="Datos del Servicio"></Tag>
        </div>
      </Fragment>
    );
  };

  const onHide = (e) => {
    setVisible(false);
  };

  //console.log(serviciosAnuladosExcel);
  //console.log(statusAcceso);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
            {!despachos ? (
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            ) : null}
                        <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ align: "center", margin: "1em" }}
            />

            <div className="datatable-responsive-demo pdatatable2">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable
                  value={despachos}
                  selection={selectedRow}
                  onSelectionChange={(e) => setSelectedRow(e.value)}
                  selectionMode="single"
                  dataKey="id"
                  header={header}
                  className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                  style={{ fontSize: "xx-small" }}
                  globalFilter={globalFilter}
                  emptyMessage="No hay datos."
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50]}
                  //paginatorLeft={paginatorLeft}
                  //paginatorRight={paginatorRight}
                >
                  <Column
                    field={
                      configuracion && configuracion.destinatario !== 2 ? "id" : "indicativoId"
                    }
                    header={
                      configuracion && configuracion.destinatario !== 2 ? "ind." : "Ind. - Id"
                    }
                    className="colIndicativo"
                    body={indicativoBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="color"
                    header="Color"
                    className="colColor"
                    body={colorBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="grupo"
                    header="Grupo"
                    className="colGrupo"
                    body={grupoBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="domicilio"
                    header="Domicilio"
                    className="colDomicilio"
                    body={domicilioBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="localidad"
                    header="Localidad"
                    className="colLocalidad"
                    body={localidadBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="edad"
                    header="Edad"
                    className="colEdad"
                    body={edadBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="nombre"
                    header="Nombre"
                    className="colNombre"
                    body={nombreBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="movilId"
                    header="Móvil"
                    className="colMovil"
                    body={movilBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="estado"
                    header="Estado"
                    className="colEstado"
                    body={estadoBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="tomado"
                    header="Tomado"
                    className="colHorarios"
                    body={tomadoBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="pasado"
                    header="Pasado"
                    className="colHorarios"
                    body={pasadoBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="salida"
                    header="Salida"
                    className="colHorarios"
                    body={salidaBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="llegada"
                    header="Llegada"
                    className="colHorarios"
                    body={llegadaBodyTemplate}
                    sortable
                  ></Column>
                  <Column
                    className="colBotones"
                    body={actionBodyTemplate}
                  ></Column>
                </DataTable>
              </div>
            </div>

          <Dialog
            header={renderHeader}
            visible={visible}
            className="p-dialog-despacho"
            icon="pi pi-external-link"
            closable={true}
            onHide={() => onHide()}
            //footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "100vw",
              padding: "0",
              overflowY: "visible",
            }}
            position="top-right"
          >
            <Grid
              container
              spacing={1}
              style={{ padding: "0" }}
            >
              <Grid item xs={12} md={8}>
                <ScrollPanel style={{ width: "100%", height: "100%" }}>
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    <ConsultaServicio />
                  </div>
                </ScrollPanel>
              </Grid>
              <Grid item xs={12} md={4}>
                <ScrollPanel
                  style={{ width: "100%", height: "100%" }}
                  className="custombar1"
                >
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    <NotasServicio />
                  </div>
                </ScrollPanel>
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
