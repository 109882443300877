import L from "leaflet";
var iconG;
var iconGp;
var iconGs;
var iconGl;
var iconGm;
var iconGt;
var iconGblinck;
var iconGpblinck;
var iconGsblinck;
var iconGlblinck;
var iconGtblinck;
var iconR;
var iconRp;
var iconRs;
var iconRl;
var iconRm;
var iconRt;
var iconRblinck;
var iconRpblinck;
var iconRsblinck;
var iconRlblinck;
var iconRtblinck;
var iconY;
var iconYp;
var iconYs;
var iconYl;
var iconYm;
var iconYt;
var iconYblinck;
var iconYpblinck;
var iconYsblinck;
var iconYlblinck;
var iconYtblinck;
var iconF;
var iconFp;
var iconFs;
var iconFl;
var iconFm;
var iconFt;
var iconFblinck;
var iconFpblinck;
var iconFsblinck;
var iconFlblinck;
var iconFtblinck;
var iconB;
var iconBp;
var iconBs;
var iconBl;
var iconBm;
var iconBt;
var iconBblinck;
var iconBpblinck;
var iconBsblinck;
var iconBlblinck;
var iconBtblinck;
var iconO;
var iconOp;
var iconOs;
var iconOl;
var iconOm;
var iconOt;
var iconOblinck;
var iconOpblinck;
var iconOsblinck;
var iconOlblinck;
var iconOtblinck;
var iconC;
var iconCp;
var iconCs;
var iconCl;
var iconCm;
var iconCt;
var iconCblinck;
var iconCpblinck;
var iconCsblinck;
var iconClblinck;
var iconCtblinck;


var iconN;
var iconNp;
var iconNs;
var iconNl;
var iconNm;
var iconNt;
var iconNblinck;
var iconNpblinck;
var iconNsblinck;
var iconNlblinck;
var iconNtblinck;



var iconM;
var iconT;


//console.log(process.env.REACT_APP_URL)


// Verde
iconG = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseG.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGp = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoG.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGs = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaG.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGl = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaG.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGm = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/movilG.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGt = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoG.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseG.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGpblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoG.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGsblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaG.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGlblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaG.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconGtblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoG.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
// Rojo
iconR = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseR.png`,
  //className: "blinking"
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRp = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoR.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRs = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaR.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRl = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaR.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRm = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/movilR.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRt = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoR.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseR.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRpblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoR.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRsblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaR.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRlblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaR.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconRtblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoR.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
// Amarillo
iconY = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseY.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYp = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoY.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYs = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaY.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYl = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaY.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYm = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/movilY.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYt = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoY.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseY.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYpblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoY.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYsblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaY.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYlblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaY.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconYtblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoY.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
// Fuccia
iconF = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseF.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFp = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoF.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFs = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaF.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFl = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaF.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFm = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/movilF.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFt = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoF.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseF.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFpblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoF.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFsblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaF.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFlblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaF.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconFtblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoF.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

// Azul
iconB = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseB.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBp = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoB.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBs = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaB.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBl = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaB.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBm = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/movilB.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBt = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoB.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseB.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBpblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoB.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBsblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaB.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBlblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaB.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconBtblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoB.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
// Naranja
iconO = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseO.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOp = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoO.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOs = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaO.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOl = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaO.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOm = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/movilO.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOt = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoO.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseO.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOpblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoO.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOsblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaO.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOlblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaO.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconOtblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoO.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
// Celeste
iconC = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseC.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconCp = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoC.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconCs = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaC.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconCl = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaC.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconCm = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/movilC.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconCt = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoC.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconCblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseC.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconCpblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoC.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconCsblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaC.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconClblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaC.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconCtblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoC.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});



// Negro
iconN = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseN.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNp = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoN.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNs = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaN.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNl = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaN.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNm = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/movilN.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNt = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoN.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/houseN.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNpblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/pasadoN.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNsblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/salidaN.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNlblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/llegadaN.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
iconNtblinck = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/trasladoN.png`,
  className: "blinking",
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});


// Movil
iconM = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/movil.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

// Traslado
iconT = L.icon({
  iconSize: [35, 31],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: `${process.env.REACT_APP_URL}/assets/images/traslado.png`,
  //shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

export {
  iconR,
  iconRp,
  iconRs,
  iconRl,
  iconRm,
  iconRt,
  iconRblinck,
  iconRpblinck,
  iconRsblinck,
  iconRlblinck,
  iconRtblinck,
  iconG,
  iconGp,
  iconGs,
  iconGl,
  iconGm,
  iconGt,
  iconGblinck,
  iconGpblinck,
  iconGsblinck,
  iconGlblinck,
  iconGtblinck,
  iconY,
  iconYp,
  iconYs,
  iconYl,
  iconYm,
  iconYt,
  iconYblinck,
  iconYpblinck,
  iconYsblinck,
  iconYlblinck,
  iconYtblinck,
  iconF,
  iconFp,
  iconFs,
  iconFl,
  iconFm,
  iconFt,
  iconFblinck,
  iconFpblinck,
  iconFsblinck,
  iconFlblinck,
  iconFtblinck,
  iconB,
  iconBp,
  iconBs,
  iconBl,
  iconBm,
  iconBt,
  iconBblinck,
  iconBpblinck,
  iconBsblinck,
  iconBlblinck,
  iconBtblinck,
  iconO,
  iconOp,
  iconOs,
  iconOl,
  iconOm,
  iconOt,
  iconOblinck,
  iconOpblinck,
  iconOsblinck,
  iconOlblinck,
  iconOtblinck,
  iconC,
  iconCp,
  iconCs,
  iconCl,
  iconCm,
  iconCt,
  iconCblinck,
  iconCpblinck,
  iconCsblinck,
  iconClblinck,
  iconCtblinck,
  iconN,
  iconNp,
  iconNs,
  iconNl,
  iconNm,
  iconNt,
  iconNblinck,
  iconNpblinck,
  iconNsblinck,
  iconNlblinck,
  iconNtblinck,
  iconM,
  iconT,
};
