import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, Redirect } from "react-router-dom";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { Button } from "primereact/button";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { ToggleButton } from "primereact/togglebutton";
import { Tag } from "primereact/tag";

export default function ResumenEstadoMoviles(props) {
  //const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [band, setBand] = useState(true);
  const [tripula, guardarTripula] = useState([]);

  const serviciosContext = useContext(ServiciosContext);
  const { resumen, demorados, tripulaciones, obtenerTripulaciones } =
    serviciosContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  //const theme = useTheme();

  //console.log(demorados)

  useEffect(() => {
    obtenerTripulaciones({ estado: false });
  }, []);

  if (band && tripulaciones) {
    tripulaciones.sort(function (a, b) {
      if (a.movilId > b.movilId) {
        return 1;
      }
      if (a.movilId < b.movilId) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    setBand(false);
    guardarTripula(tripulaciones);
  }

  //console.log(tripulaciones);

  return (
    <Fragment>
      {tripula ? (
        <Grid
          container
          spacing={0}
          style={{
            marginTop: "-6em",
            marginLeft: "1em",
            paddingLeft: "0em",
            alignItems: "normal",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            style={{ marginTop: "7em", marginLeft: "3em" }}
          >
            <ToggleButton
              onLabel="Activos"
              offLabel="Inactivos"
              onIcon="pi pi-arrow-circle-down"
              offIcon="pi pi-arrow-circle-up"
              checked={checked}
              onChange={(e) => setChecked(e.value)}
            />
          </Grid>
          {checked ? (
            <Fragment>
              {tripula.map((element) => {
                if (element.activo) {
                  return (
                    <Grid item xs={12} md={6} style={{ marginTop: "0.5em" }}>
                      <Tag severity="success" value={element.movilId}></Tag>
                    </Grid>
                  );
                }
              })}
            </Fragment>
          ) : (
            <Fragment>
              {tripula.map((element) => {
                if (!element.activo) {
                  return (
                    <Grid item xs={12} md={6} style={{ marginTop: "0.5em" }}>
                      <Tag severity="danger" value={element.movilId}></Tag>
                    </Grid>
                  );
                }
              })}
            </Fragment>
          )}
        </Grid>
      ) : null}
    </Fragment>
  );
}
