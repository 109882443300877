import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, Redirect } from "react-router-dom";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { AuthContext } from "../../../context/AuthContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { OrderList } from "primereact/orderlist";
import "../../../assets/css/OrderListDemo.css";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

export default function NotasServicio(props) {
  //const classes = useStyles();
  const toast = useRef(null);

  const serviciosContext = useContext(ServiciosContext);
  const {
    servicio,
    notas,
    nota,
    notaVisible,
    guardarOpcionesMovil,
    openDrawer,
    drawer,
    eventoRealizado,
    setDrawer,
    setOpenDrawer,
    guardarEventoRealizado,
    agregarNota,
    agregarNotaPendiente,
    obtenerNotas,
    obtenerNotasPendientes,
    setNota,
    setNotas,
    setNotaVisible,
  } = serviciosContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    if (servicio && servicio !== null && servicio.origen === "despachos") {
      obtenerNotas({ servicioId: servicio.id });
    }
    if (servicio && servicio !== null && servicio.origen === "pendientes") {
      obtenerNotasPendientes({ servicioId: servicio.id });
    }
  }, [servicio]);

  function formatDateTime(dateString) {
    var allDateTime = dateString.split(" ");

    var newDate = allDateTime[0];
    var newTime = allDateTime[1];
    return newDate + " " + newTime;
  }

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Notas del Servicio"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => {
            const notaNew = document.getElementById("notaText").value;

            if (servicio.origen === "despachos") {
              if (!notaNew || notaNew === null || notaNew.trim() === "") {
                toast.current.show({
                  severity: "error",
                  summary: "Notas",
                  detail: `La nota no puede estar vacia.`,
                  life: 3000,
                });
                return;
              }
              setNotaVisible(false);

              agregarNota({
                nota: notaNew,
                servicioId: servicio.id,
                usuario: state.usuario.usuario,
              });
              setTimeout(() => {
                obtenerNotas({ servicioId: servicio.id });
              }, 2000);
            }
            if (servicio.origen === "pendientes") {
              setNotaVisible(false);
              agregarNotaPendiente({
                nota: notaNew,
                servicioId: servicio.id,
                usuario: state.usuario.usuario,
              });
              setTimeout(() => {
                obtenerNotasPendientes({ servicioId: servicio.id });
              }, 2000);
            }
          }}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Volver"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-text"
        />
      </div>
    );
  };

  const onHide = (e) => {
    setNotas(null);
    setNotaVisible(false);
  };

  const itemTemplate = (item) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="p-mb-2 p-width">{item.nota}</h5>
          <span>
            <h6 className="p-mb-2 p-width">
              {item.fecha}
              {"  "}
              {item.usuario}
            </h6>
          </span>
        </div>
      </div>
    );
  };

  //console.log(servicio);

  return servicio ? (
    <Fragment>
      <Toast ref={toast} />
      <Grid container spacing={0} style={{ padding: "0" }}>
        <Grid item xs={12} md={12}>
          <div className="orderlist-demo">
            <div className="card">
              <Fragment>
                <Button
                  style={{
                    marginTop: "1em",
                    marginBottom: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    float: "right",
                    marginRight: 0,
                    marginLeft: "1vw",
                  }}
                  icon="pi pi-plus-circle"
                  className="p-button-success p-button-rounded"
                  onClick={() => {
                    setNota(null);
                    setNotaVisible(true);
                  }}
                  tooltip="Agregar Nota"
                  tooltipOptions={{
                    position: "bottom",
                    mouseTrack: true,
                    mouseTrackTop: 15,
                    className: "tooltip",
                  }}
                />

                <OrderList
                  value={notas}
                  //dragdrop
                  listStyle={{ height: "auto" }}
                  dataKey="id"
                  itemTemplate={itemTemplate}
                  header={
                    servicio && servicio.origen !== "pendientes"
                      ? "Notas Id. " + servicio.id
                      : "Notas Servicio Pendiente"
                  }
                  style={{ marginRight: "0vw" }}
                  //onChange={(e) => setProducts(e.value)}
                ></OrderList>
              </Fragment>
            </div>
          </div>
        </Grid>
        {/*    
        <Grid item xs={12} md={12}>
          {notas
            ? notas.map((data) => (
                <div>
                  <p>
                    {"- "}
                    {data.nota}
                    {" - "}
                    {data.fecha}
                    {" - "}
                    {data.usuario}
                  </p>
                </div>
              ))
            : null}
        </Grid>
            */}
      </Grid>
      <div className="dialog-demo">
        <div className="card">
          <Dialog
            header={renderHeader}
            visible={notaVisible}
            value={nota}
            icon="pi pi-external-link"
            closable={false}
            onHide={() => onHide()}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "40vw",
              paddingBottom: "0",
              overflowY: "visible",
            }}
            position="center"
          >
            {/*
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                id="notaLabel"
                multiline
                value={nota}
                rows={5}
                onChange={(e) => setNota(e.target.value)}
                //defaultValue=""
              />
            </FormControl>
            */}
            <InputTextarea
              id="notaText"
              rows={5}
              cols={60}
              //value={nota}
              //spellCheck={false}
              //onChange={(e) => setNota(e.target.value)}
            />{" "}
            <br />
          </Dialog>
        </div>
      </div>
    </Fragment>
  ) : null;
}
