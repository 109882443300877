import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
//import "./example-styles.css";
//import "./styles.css";
//import { Responsive, WidthProvider } from "react-grid-layout";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
//import NewWindow from "react-new-window";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import detectZoom from "detect-zoom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import "../../../assets/css/DataTableDemo.css";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import classNames from "classnames";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { NumericFormat } from "react-number-format";
import ButtonMui from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import { RadioButton } from "primereact/radiobutton";
import hcOffcanvasNav from "hc-offcanvas-nav";
import { saveAs } from "file-saver";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

export default function TripulacionesProgramacion(props) {
  //const classes = useStyles();

  const [id, setId] = useState(null);
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState(null);
  const [loop, setLoop] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [notificacion, setNotificacion] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [modalBorrar, setModalBorrar] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tripulacion, guardarTripulacion] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const toastWarm = useRef(null);
  const [movilesArray, setMovilesArray] = useState(null);
  const [telefonosArray, setTelefonosArray] = useState(null);
  const [almacenesArray, setAlmacenesArray] = useState(null);
  const [openMedico, setOpenMedico] = useState(false);
  const [openChofer, setOpenChofer] = useState(false);
  const [openEnfermero, setOpenEnfermero] = useState(false);
  const [cambiarMedico, setCambiarMedico] = useState(false);
  const [cambiarChofer, setCambiarChofer] = useState(false);
  const [cambiarEnfermero, setCambiarEnfermero] = useState(false);
  const [tripulacionAnterior, setTripulacionAnterior] = useState(null);
  const [updateTripulacion, setUpdateTripulacion] = useState(false);
  const [band, setBand] = useState(false);
  const [visibleModificaciones, setVisibleModificaciones] = useState(false);
  const [tripulacionOriginal, guardarTripulacionOriginal] = useState(null);
  const [radio, setRadio] = useState("armadas");
  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Móvil", dataKey: "movil" },
    { title: "Base", dataKey: "base" },
  ];

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso3,
    telefono,
    telefonos,
    almacen,
    almacenes,
    medicos,
    choferes,
    enfermeros,
    colores,
    bases,
    moviles,
    obtenerAcceso,
    obtenerColores,
    obtenerTelefono,
    obtenerBases,
    obtenerMoviles,
    obtenerMedicos,
    obtenerChoferes,
    obtenerEnfermeros,
    obtenerMovilesPropiosLiberados,
    obtenerMedicosLiberados,
    obtenerChoferesLiberados,
    obtenerEnfermerosLiberados,
    obtenerTelefonosLiberados,
    obtenerAlmacenesLiberados,
    obtenerMovil,
    obtenerMedico,
    obtenerChofer,
    obtenerEnfermero,
    guardarMoviles,
    guardarTelefonos,
    guardarAlmacenes,
    guardarMedicos,
    guardarChoferes,
    guardarEnfermeros,
    obtenerAcceso3,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    resumen,
    tripulacionesProgramacion,
    tripulacionesCerradas,
    estadoTomado,
    tripulacionModificada,
    obtenerTripulacionesProgramacion,
    obtenerTripulacionesCerradas,
    setEstadoTomado,
    guardarServicio,
    enviarNotificaciones,
    obtenerTripulacion,
    agregarTripulacionProgramacion,
    actualizarTripulacionProgramacion,
    actualizarTripulacionCambiada,
    verificarEstadoMoviles,
    guardarTripulacionModificada,
    modificarTripulacionCerrada,
    borrarTripulacionProgramacion,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "TripulacionesProgramacion",
      });
      obtenerAcceso3({
        usuarioId: state.usuario.id,
        layout: "ModificacionesTripulacionesProgramacion",
      });
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  useEffect(() => {
    obtenerTripulacionesProgramacion();
    obtenerBases({ todos: false });
    obtenerMoviles({ todos: false, propios: true });
    obtenerMedicos({ todos: false });
    obtenerChoferes({ todos: false });
    obtenerEnfermeros({ todos: false });
    //setUpdateTripulacion(true);
    //verificarEstadoMoviles();
  }, []);

  const editClick = () => {
    if (selectedRow) {
      guardarTripulacion(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar una tripulación.",
        life: 3000,
      });
    }
  };

  const addClick = () => {
    //navigate(`/tripulacion/0`);
    setSelectedRow(null);
    guardarTripulacion({
      id: 0,
      baseId: 0,
      movilId: 0,
      medicoId: 0,
      medicoIngreso: "",
      medicoSalida: "",
      choferId: 0,
      choferIngreso: "",
      choferSalida: "",
      enfermeroId: 0,
      enfermeroIngreso: "",
      enfermeroSalida: "",
    });

    setVisible(true);
  };

  const exitMenu = (e) => {
    navigate(`/`);
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const onHide = (e) => {
    setSelectedRow(null);
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();

    //Validar
    if (tripulacion.baseId === 0) {
      mensajeAlerta("Tripulaciones Programadas", "La base es necesaria !.", "error");
      return;
    }

    if (tripulacion.movilId === 0) {
      mensajeAlerta("Tripulaciones Programadas", "El móvil es necesario !.", "error");
      return;
    }

    if (
      tripulacion.medicoId === 0 &&
      tripulacion.choferId === 0 &&
      tripulacion.enfermeroId === 0
    ) {
      mensajeAlerta("Tripulaciones Programadas", "Es necesario cargar al menos un tripulante !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarTripulacionProgramacion({
        tripulacion,
        usuario: state.usuario.usuario,
      });
    } else {
      agregarTripulacionProgramacion({
        tripulacion,
        usuario: state.usuario.usuario,
      });
    }
    setTimeout(() => {
      obtenerTripulacionesProgramacion({ estado: false });
      setSelectedRow(null);
    }, 2000);

    setVisible(false);

    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const borrarProgramacion = (rowData) => {
    if (statusAcceso3 !== 200) {
      mensajeAlerta(
        "No tiene permiso para acceder a esta pantalla !.",
        "error"
      );
      return;
    }
    confirmDialog({
      message: `Desea borrar la programación id ${rowData.id} ?`,
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Si",
      accept: () => {
        borrarTripulacionProgramacion({ id: rowData.id });
        setTimeout(() => {
          obtenerTripulacionesProgramacion({ estado: false });
          setSelectedRow(null);
        }, 2000);
      },
      reject,
    });
  };



  const reject = () => {
    toast.current.show({
      severity: "info",
      summary: "Cancelado",
      detail: "El borrado ha sido cancelado",
      life: 3000,
    });
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const movilTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Móvil</span>
        {rowData.movilId}
      </Fragment>
    );
  };

  const baseTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Base</span>
        {rowData.base}
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Teléfono</span>
        {rowData.telefono}
      </Fragment>
    );
  };

  const almacenTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Maletín</span>
        {rowData.almacen}
      </Fragment>
    );
  };

  const medicoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Médico</span>
        {rowData.medico}
      </Fragment>
    );
  };

  const choferTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Chofer</span>
        {rowData.chofer}
      </Fragment>
    );
  };

  const enfermeroTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Enfermero</span>
        {rowData.enfermero}
      </Fragment>
    );
  };

  const activoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Activo</span>
        <Checkbox checked={rowData.activo}></Checkbox>
      </Fragment>
    );
  };

  const localizacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Localización</span>
        <Checkbox checked={rowData.localizacion}></Checkbox>
      </Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          icon="pi pi-trash"
          className="p-button-danger p-button-text"
          onClick={() => borrarProgramacion(rowData)}
          tooltip="Borrar Programación"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
      </Fragment>
    );
  };

  const cerradoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Cerrado</span>
        <Checkbox checked={rowData.cerrado}></Checkbox>
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, tripulacionesProgramacion);
        doc.save("tripulacionesProgramadas.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tripulacionesProgramacion);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "tripulacionesProgramadas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Programación de Tripulaciones"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Tripulación Programada"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => aceptarClick(e)}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={(e) => onHide()}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const modificaciones = (rowData) => {
    if (statusAcceso3 !== 200) {
      mensajeAlerta(
        "No tiene permiso para acceder a esta pantalla !.",
        "error"
      );
      return;
    }

    obtenerTripulacion({ idTripulacion: rowData.id });
    setVisibleModificaciones(true);
  };

  const renderHeaderModificar = () => {
    return (
      <div>
        <Tag value="Modificaciones del Servicio"></Tag>
      </div>
    );
  };

  const renderFooterModificar = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleModificar()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Volver"
          icon="pi pi-times"
          onClick={() => handleCloseModificar()}
          className="p-button-text"
        />
      </div>
    );
  };

  const handleCloseModificar = () => {
    setVisibleModificaciones(false);
  };

  const handleModificar = () => {
    //Validar
    if (tripulacionModificada.baseId === 0) {
      mensajeAlerta("La base es necesaria !.", "error");
      return;
    }

    if (tripulacionModificada.movilId === 0) {
      mensajeAlerta("El móvil es necesario !.", "error");
      return;
    }

    if (
      tripulacionModificada.medicoId === 0 &&
      tripulacionModificada.choferId === 0 &&
      tripulacionModificada.enfermeroId === 0
    ) {
      mensajeAlerta("Es necesario cargar al menos un tripulante !.", "error");
      return;
    }

    modificarTripulacionCerrada({
      tripulacionId: tripulacionModificada.id,
      tripulacionOriginal,
      tripulacionModificada,
      usuario: state.usuario.usuario,
      //coseguroAbonar,
      //servicioAnterior: servicioSeleccionado,
    });

    handleCloseModificar();
    exitClick();
  };

  //console.log(tripulacion)
  //console.log(almacenesArray)

  return (
    <Fragment>
      <Barra />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Toast ref={toast} position="top-right" />
          <Toast ref={toastWarm} position="top-center" />
          <ConfirmDialog />
          {tripulacionesProgramacion &&
          moviles &&
          medicos &&
          choferes &&
          enfermeros ? (
            <Fragment>
              <Grid item xs={12} sm={12} md={12} className="ptoolbar">
                <Fragment>
                  <Button
                    icon="pi pi-plus"
                    tooltip="Agregar"
                    className="p-button-rounded p-button-help"
                    style={{ marginLeft: "0em" }}
                    onClick={(e) => addClick()}
                  />

                  <Button
                    icon="pi pi-pencil"
                    tooltip="Editar"
                    className="p-button-rounded p-button-danger"
                    style={{ marginLeft: "1em" }}
                    onClick={(e) => editClick()}
                  />
                </Fragment>
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={tripulacionesProgramacion}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "xx-small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="movilId"
                        header="Móvil"
                        body={movilTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="base"
                        header="Base"
                        body={baseTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="medico"
                        header="Médico"
                        body={medicoTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="chofer"
                        header="Chofer"
                        body={choferTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="enfermero"
                        header="Enfermero"
                        body={enfermeroTemplate}
                        sortable
                      ></Column>
                      <Column
                        className="colBotones"
                        body={actionBodyTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3} style={{ marginTop: "1vw" }}>
                  {selectedRow ? (
                    <Grid item xs={12} md={2}>
                      <TextField
                        value={selectedRow ? selectedRow.id : null}
                        required
                        id="id"
                        label="ID"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="base"
                      fullWidth
                      options={bases}
                      disablePortal
                      defaultValue={
                        bases && tripulacion && tripulacion.baseId !== undefined
                          ? bases[
                              bases.findIndex(
                                (item) => item.id === tripulacion.baseId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Bases"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            baseId: value.id,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="movil"
                      fullWidth
                      options={moviles}
                      disablePortal
                      defaultValue={
                        movilesArray &&
                        selectedRow &&
                        selectedRow.movilId !== undefined
                          ? movilesArray[
                              movilesArray.findIndex(
                                (item) => item.id === selectedRow.movilId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Móviles"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            movilId: value.id,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="medico"
                      fullWidth
                      options={medicos}
                      disablePortal
                      defaultValue={
                        medicos &&
                        selectedRow &&
                        selectedRow.medicoId !== undefined
                          ? medicos[
                              medicos.findIndex(
                                (item) => item.id === selectedRow.medicoId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Médicos"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            medicoId: value.id,
                          });
                        } else {
                          guardarTripulacion({
                            ...tripulacion,
                            medicoId: 0,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="medicoIngreso"
                      label="Ingreso"
                      type="datetime-local"
                      variant="standard"
                      noOptionsText={"No hay opciones"}
                      defaultValue={
                        selectedRow &&
                        selectedRow.medicoIngreso !== "" &&
                        selectedRow.medicoIngreso !== null
                          ? selectedRow.medicoIngreso
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarTripulacion({
                          ...tripulacion,
                          medicoIngreso: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="medicoSalida"
                      label="Salida"
                      type="datetime-local"
                      variant="standard"
                      defaultValue={
                        selectedRow &&
                        selectedRow.medicoSalida !== null &&
                        selectedRow.medicoSalida !== ""
                          ? selectedRow.medicoSalida
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarTripulacion({
                          ...tripulacion,
                          medicoSalida: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} />

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="chofer"
                      fullWidth
                      options={choferes}
                      disablePortal
                      defaultValue={
                        choferes &&
                        selectedRow &&
                        selectedRow.choferId !== undefined
                          ? choferes[
                              choferes.findIndex(
                                (item) => item.id === selectedRow.choferId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choferes"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            choferId: value.id,
                          });
                        } else {
                          guardarTripulacion({
                            ...tripulacion,
                            choferId: 0,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="choferIngreso"
                      label="Ingreso"
                      type="datetime-local"
                      variant="standard"
                      disablePortal
                      noOptionsText={"No hay opciones"}
                      defaultValue={
                        selectedRow &&
                        selectedRow.choferIngreso !== "" &&
                        selectedRow.choferIngreso !== null
                          ? selectedRow.choferIngreso
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarTripulacion({
                          ...tripulacion,
                          choferIngreso: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="choferSalida"
                      label="Salida"
                      type="datetime-local"
                      variant="standard"
                      defaultValue={
                        selectedRow &&
                        selectedRow.choferSalida !== null &&
                        selectedRow.choferSalida !== ""
                          ? selectedRow.choferSalida
                          : null
                      }
                      //defaultValue="2017-05-24T10:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarTripulacion({
                          ...tripulacion,
                          choferSalida: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} />

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="enfermero"
                      fullWidth
                      options={enfermeros}
                      disablePortal
                      defaultValue={
                        enfermeros &&
                        selectedRow &&
                        selectedRow.enfermeroId !== undefined
                          ? enfermeros[
                              enfermeros.findIndex(
                                (item) => item.id === selectedRow.enfermeroId
                              )
                            ]
                          : null
                      }
                      noOptionsText={"No hay opciones"}
                      disabled={
                        tripulacion &&
                        tripulacion.enfermeroIngreso !== null &&
                        tripulacion.enfermeroIngreso !== ""
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Enfermeros"
                          variant="standard"
                        />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarTripulacion({
                            ...tripulacion,
                            enfermeroId: value.id,
                          });
                        } else {
                          guardarTripulacion({
                            ...tripulacion,
                            enfermeroId: 0,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="enfermeroIngreso"
                      label="Ingreso"
                      type="datetime-local"
                      variant="standard"
                      defaultValue={
                        selectedRow &&
                        selectedRow.enfermeroIngreso !== "" &&
                        selectedRow.enfermeroIngreso !== null
                          ? selectedRow.enfermeroIngreso
                          : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarTripulacion({
                          ...tripulacion,
                          enfermeroIngreso: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="enfermeroSalida"
                      label="Salida"
                      type="datetime-local"
                      variant="standard"
                      defaultValue={
                        selectedRow &&
                        selectedRow.enfermeroSalida !== "" &&
                        selectedRow.enfermeroSalida !== null
                          ? selectedRow.enfermeroSalida
                          : null
                      }
                      //defaultValue="2017-05-24T10:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        guardarTripulacion({
                          ...tripulacion,
                          enfermeroSalida: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} />
                </Grid>
              </Dialog>

              <Dialog
                header={renderHeaderModificar()}
                visible={visibleModificaciones}
                closable={false}
                footer={renderFooterModificar()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "90vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={1} style={{ padding: "2vw" }}>
                  {tripulacionModificada ? (
                    <Fragment>
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="base"
                          fullWidth
                          options={bases}
                          disablePortal
                          defaultValue={
                            bases &&
                            tripulacionModificada &&
                            tripulacionModificada.baseId !== undefined
                              ? bases[
                                  bases.findIndex(
                                    (item) =>
                                      item.id === tripulacionModificada.baseId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Bases"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                baseId: value.id,
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          id="movil"
                          fullWidth
                          options={moviles}
                          disablePortal
                          defaultValue={
                            moviles &&
                            tripulacionModificada.movilId !== undefined
                              ? moviles[
                                  moviles.findIndex(
                                    (item) =>
                                      item.id === tripulacionModificada.movilId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Móviles"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                movilId: value.id,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          id="telefono"
                          fullWidth
                          options={telefonos}
                          disablePortal
                          defaultValue={
                            telefonos &&
                            tripulacionModificada &&
                            tripulacionModificada.telefonoId !== undefined
                              ? telefonos[
                                  telefonos.findIndex(
                                    (item) =>
                                      item.id ===
                                      tripulacionModificada.telefonoId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Teléfonos"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                telefonoId: value.id,
                                localizacion: true,
                              });
                            } else {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                telefonoId: 0,
                                localizacion: false,
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="medico"
                          fullWidth
                          options={medicos}
                          disablePortal
                          defaultValue={
                            medicos &&
                            tripulacionModificada &&
                            tripulacionModificada.medicoId !== undefined
                              ? medicos[
                                  medicos.findIndex(
                                    (item) =>
                                      item.id === tripulacionModificada.medicoId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Médicos"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                medicoId: value.id,
                              });
                            } else {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                medicoId: 0,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="medicoIngreso"
                          label="Ingreso"
                          type="datetime-local"
                          variant="standard"
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.medicoId === "" ||
                              tripulacionModificada.medicoId === null ||
                              tripulacionModificada.medicoId === 0)
                              ? true
                              : false
                          }
                          noOptionsText={"No hay opciones"}
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.medicoIngreso !== "" &&
                            tripulacionModificada.medicoIngreso !== null
                              ? tripulacionModificada.medicoIngreso
                              : null
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              medicoIngreso: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="medicoSalida"
                          label="Salida"
                          type="datetime-local"
                          variant="standard"
                          error={
                            tripulacionModificada &&
                            tripulacionModificada.medicoSalida !== null &&
                            tripulacionModificada.medicoSalida !== "" &&
                            tripulacionModificada.medicoSalida <=
                              tripulacionModificada.medicoIngreso
                          }
                          helperText={
                            tripulacionModificada &&
                            tripulacionModificada.medicoSalida !== null &&
                            tripulacionModificada.medicoSalida !== "" &&
                            tripulacionModificada.medicoSalida <=
                              tripulacionModificada.medicoIngreso
                              ? "Error en la fecha"
                              : " "
                          }
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.medicoIngreso === null ||
                              tripulacionModificada.medicoIngreso === "")
                              ? true
                              : false
                          }
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.medicoSalida !== null &&
                            tripulacionModificada.medicoSalida !== ""
                              ? tripulacionModificada.medicoSalida
                              : null
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              medicoSalida: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2} />

                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="chofer"
                          fullWidth
                          options={choferes}
                          disablePortal
                          defaultValue={
                            choferes &&
                            tripulacionModificada &&
                            tripulacionModificada.choferId !== undefined
                              ? choferes[
                                  choferes.findIndex(
                                    (item) =>
                                      item.id === tripulacionModificada.choferId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choferes"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                choferId: value.id,
                              });
                            } else {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                choferId: 0,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="choferIngreso"
                          label="Ingreso"
                          type="datetime-local"
                          variant="standard"
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.choferId === "" ||
                              tripulacionModificada.choferId === null ||
                              tripulacionModificada.choferId === 0)
                              ? true
                              : false
                          }
                          disablePortal
                          noOptionsText={"No hay opciones"}
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.choferIngreso !== "" &&
                            tripulacionModificada.choferIngreso !== null
                              ? tripulacionModificada.choferIngreso
                              : null
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              choferIngreso: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <TextField
                          id="choferSalida"
                          label="Salida"
                          type="datetime-local"
                          variant="standard"
                          error={
                            tripulacionModificada &&
                            tripulacionModificada.choferSalida !== null &&
                            tripulacionModificada.choferSalida !== "" &&
                            tripulacionModificada.choferSalida <=
                              tripulacionModificada.choferIngreso
                          }
                          helperText={
                            tripulacionModificada &&
                            tripulacionModificada.choferSalida !== null &&
                            tripulacionModificada.choferSalida !== "" &&
                            tripulacionModificada.choferSalida <=
                              tripulacionModificada.choferIngreso
                              ? "Error en la fecha"
                              : " "
                          }
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.choferIngreso === null ||
                              tripulacionModificada.choferIngreso === "")
                              ? true
                              : false
                          }
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.choferSalida !== null &&
                            tripulacionModificada.choferSalida !== ""
                              ? tripulacionModificada.choferSalida
                              : null
                          }
                          //defaultValue="2017-05-24T10:30"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              choferSalida: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2} />

                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="enfermero"
                          fullWidth
                          options={enfermeros}
                          disablePortal
                          defaultValue={
                            enfermeros &&
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroId !== undefined
                              ? enfermeros[
                                  enfermeros.findIndex(
                                    (item) =>
                                      item.id ===
                                      tripulacionModificada.enfermeroId
                                  )
                                ]
                              : null
                          }
                          noOptionsText={"No hay opciones"}
                          getOptionLabel={(option) => option.nombre}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Enfermeros"
                              variant="standard"
                            />
                          )}
                          //onKeyDown={handleEnter}
                          onChange={(event, value) => {
                            if (value) {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                enfermeroId: value.id,
                              });
                            } else {
                              guardarTripulacionModificada({
                                ...tripulacionModificada,
                                enfermeroId: 0,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="enfermeroIngreso"
                          label="Ingreso"
                          type="datetime-local"
                          variant="standard"
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.enfermeroId === "" ||
                              tripulacionModificada.enfermeroId === null ||
                              tripulacionModificada.enfermeroId === 0)
                              ? true
                              : false
                          }
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroIngreso !== "" &&
                            tripulacionModificada.enfermeroIngreso !== null
                              ? tripulacionModificada.enfermeroIngreso
                              : null
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              enfermeroIngreso: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id="enfermeroSalida"
                          label="Salida"
                          type="datetime-local"
                          variant="standard"
                          error={
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroSalida !== null &&
                            tripulacionModificada.enfermeroSalida !== "" &&
                            tripulacionModificada.enfermeroSalida <=
                              tripulacionModificada.enfermeroIngreso
                          }
                          helperText={
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroSalida !== null &&
                            tripulacionModificada.enfermeroSalida !== "" &&
                            tripulacionModificada.enfermeroSalida <=
                              tripulacionModificada.enfermeroIngreso
                              ? "Error en la fecha"
                              : " "
                          }
                          disabled={
                            tripulacionModificada &&
                            (tripulacionModificada.enfermeroIngreso === null ||
                              tripulacionModificada.enfermeroIngreso === "")
                              ? true
                              : false
                          }
                          defaultValue={
                            tripulacionModificada &&
                            tripulacionModificada.enfermeroSalida !== "" &&
                            tripulacionModificada.enfermeroSalida !== null
                              ? tripulacionModificada.enfermeroSalida
                              : null
                          }
                          //defaultValue="2017-05-24T10:30"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarTripulacionModificada({
                              ...tripulacionModificada,
                              enfermeroSalida: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2} />
                    </Fragment>
                  ) : null}
                </Grid>
                <br />
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </Fragment>
      ) : null}
    </Fragment>
  );
}
