import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { NumericFormat } from 'react-number-format';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import throttle from "lodash/throttle";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import {
  locale,
  addLocale,
  updateLocaleOption,
  updateLocaleOptions,
  localeOption,
  localeOptions,
} from "primereact/api";

export default function TomaNaranja(props) {

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
  });

  locale("es");
  let minDate = new Date();

  const serviciosContext = useContext(ServiciosContext);
  const {
    afiliado,
    servicioSeleccionado,
    tomaNaranja,
    guardarTomaNaranja,
  } = serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, tiposMoviles, diagnosticos, obtenerTiposMoviles } =
    configuracionContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });


  useEffect(() => {
    //obtenerDiagnosticos({ todos: false });
    obtenerTiposMoviles();
  }, []);

  
  // Leer tecla y foco

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "llegada":
          campo = document.getElementById("duracion");
          campo.focus();
          break;
        case "duracion":
          campo = document.getElementById("cantidadMoviles");
          campo.focus();
          break;
        case "cantidadMoviles":
          campo = document.getElementById("dotacion");
          campo.focus();
          break;
        case "dotacion":
          campo = document.getElementById("tipoEvento");
          campo.focus();
          break;
        case "tipoEvento":
          campo = document.getElementById("responsable");
          campo.focus();
          break;
        case "responsable":
          campo = document.getElementById("contacto");
          campo.focus();
          break;
        case "contacto":
          campo = document.getElementById("pedido");
          campo.focus();
          break;
        case "pedido":
          campo = document.getElementById("telefonoNaranja");
          campo.focus();
          break;
        case "telefonoNaranja":
          campo = document.getElementById("cantidadPersonas");
          campo.focus();
          break;
        case "cantidadPersonas":
          campo = document.getElementById("descripcion");
          campo.focus();
          break;
        case "descripcion":
          campo = document.getElementById("google-map-naranja");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "descripcion":
          campo = document.getElementById("cantidadPersonas");
          campo.focus();
          break;
        case "cantidadPersonas":
          campo = document.getElementById("telefonoNaranja");
          campo.focus();
          break;
        case "telefonoNaranja":
          campo = document.getElementById("pedido");
          campo.focus();
          break;
        case "pedido":
          campo = document.getElementById("contacto");
          campo.focus();
          break;
        case "contacto":
          campo = document.getElementById("responsable");
          campo.focus();
          break;
        case "responsable":
          campo = document.getElementById("tipoEvento");
          campo.focus();
          break;
        case "tipoEvento":
          campo = document.getElementById("dotacion");
          campo.focus();
          break;
        case "dotacion":
          campo = document.getElementById("cantidadMoviles");
          campo.focus();
          break;
        case "cantidadMoviles":
          campo = document.getElementById("duracion");
          campo.focus();
          break;
        case "duracion":
          campo = document.getElementById("llegada");
          campo.focus();
          break;
      }
    }
  }
  
  //console.log(tiposMoviles);
  //console.log(value);

  return (
    <Fragment>
      <Grid container spacing={1} style={{ padding: "0" }}>
        <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
          <label htmlFor="multiple">Llegada</label>
          <Calendar
            id="llegada"
            dateFormat="dd/mm/yy"
            selectionMode="multiple"
            locale="es"
            minDate={minDate}
            value={tomaNaranja ? tomaNaranja.llegada : null}
            baseZIndex={5001}
            showTime
            required
            //onKeyDown={handleEnter}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "40vw",
              paddingBottom: "0",
              overflowY: "visible",
              marginLeft: "1em",
            }}
            showButtonBar={true}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                llegada: e.value,
              })
            }
          ></Calendar>
        </Grid>

        <Grid item xs={6} md={8}>
          <TextField
            id="duracion"
            label="Duración"
            fullWidth
            variant="standard"
            size="small"
            value={tomaNaranja ? tomaNaranja.duracion : null}
            required
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                duracion: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <NumericFormat
            {...props}
            id="cantidadMoviles"
            label="Cantidad de móviles"
            customInput={TextField}
            format="###"
            type="numeric"
            fullWidth
            variant="standard"
            size="small"
            value={tomaNaranja ? tomaNaranja.cantidadMoviles : null}
            required
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                cantidadMoviles: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="dotacion"
            label="Dotación"
            fullWidth
            variant="standard"
            size="small"
            value={tomaNaranja ? tomaNaranja.dotacion : null}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                dotacion: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <TextField
            id="tipoEvento"
            label="Tipo de evento"
            fullWidth
            variant="standard"
            size="small"
            value={tomaNaranja ? tomaNaranja.tipoEvento : null}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                tipoEvento: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <TextField
            id="responsable"
            label="Responsable"
            fullWidth
            variant="standard"
            size="small"
            value={tomaNaranja ? tomaNaranja.responsable : null}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                responsable: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="contacto"
            label="Contacto"
            fullWidth
            variant="standard"
            size="small"
            value={tomaNaranja ? tomaNaranja.contacto : null}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                contacto: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id="pedido"
            label="Pedido"
            type="date"
            fullWidth
            variant="standard"
            onKeyDown={handleEnter}
            value={tomaNaranja ? tomaNaranja.pedido : null}
            required
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              guardarTomaNaranja({
                ...tomaNaranja,
                pedido: e.target.value,
              });
            }}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <TextField
            id="telefonoNaranja"
            label="Teléfono"
            fullWidth
            variant="standard"
            size="small"
            value={tomaNaranja ? tomaNaranja.telefono : null}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                telefono: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <NumericFormat
            {...props}
            id="cantidadPersonas"
            label="Cantidad de personas"
            customInput={TextField}
            type="numeric"
            fullWidth
            variant="standard"
            size="small"
            value={tomaNaranja ? tomaNaranja.cantidadPersonas : null}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                cantidadPersonas: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            id="descripcion"
            label="Descripción"
            fullWidth
            variant="standard"
            size="small"
            value={tomaNaranja ? tomaNaranja.descripcion : null}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaNaranja({
                ...tomaNaranja,
                descripcion: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={12}></Grid>
      </Grid>
    </Fragment>
  );
}
