import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "../servicios/ConsultaServicio";
import NotasServicio from "../servicios/NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import hcOffcanvasNav from "hc-offcanvas-nav";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

export default function EstadisticaServicioMesaMes(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "Mes", dataKey: "mes" },
    { title: "ROJO", dataKey: "rojo" },
    { title: "AMARILLO", dataKey: "amarillo" },
    { title: "VERDE", dataKey: "verde" },
    { title: "FUCCIA", dataKey: "fuccia" },
    { title: "AZUL", dataKey: "azul" },
    { title: "CELESTE", dataKey: "celeste" },
    { title: "BLANCO", dataKey: "blanco" },
    { title: "NARANJA", dataKey: "naranja" },
    { title: "TOTAL", dataKey: "total" },
    { title: "% Total de Servicios", dataKey: "porcentaje" },
  ];

  const [visibleFechas, setVisibleFechas] = useState(true);
  const [anio, setAnio] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const toast = useRef(null);
  const [radio, setRadio] = useState("tomado");
  const [titularId, setTitularId] = useState(null);
  const [titularNombre, setTitularNombre] = useState(null);
  const [titularSeleccionado, setTitularSeleccionado] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    colores,
    moviles,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerColores,
    obtenerMoviles,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    serviciosFinalizados,
    serviciosFinalizadosExcel,
    obtenerServiciosFinalizados,
    obtenerServiciosAnual,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Listados" });
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const exitClick = (e) => {
    onHideFechas();
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(1, 2);
  };

  const startClick = (e) => {
    setTimeout(() => {
      navigate(`/estadisticaserviciosmesames`);
      setVisibleFechas(true);
    }, 2000);
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    if (!anio || anio === undefined || anio === null || anio.trim() === "") {
      mensajeAlerta("Estadística de Servicios", "Año es necesario !.", "error");
      return;
    }

    var elem;

    await obtenerServiciosAnual({
      anio,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHideFechas();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const exportExcel = () => {
    if (serviciosFinalizadosExcel && serviciosFinalizadosExcel.length > 0) {
      let totalRojo = 0;
      let totalAmarillo = 0;
      let totalVerde = 0;
      let totalFuccia = 0;
      let totalAzul = 0;
      let totalCeleste = 0;
      let totalBlanco = 0;
      let totalNaranja = 0;
      let totalGeneral = 0;
      for (var i = 0; i < serviciosFinalizadosExcel.length; i++) {
        totalRojo = totalRojo + serviciosFinalizadosExcel[i].rojo;
        totalAmarillo = totalAmarillo + serviciosFinalizadosExcel[i].amarillo;
        totalVerde = totalVerde + serviciosFinalizadosExcel[i].verde;
        totalFuccia = totalFuccia + serviciosFinalizadosExcel[i].fuccia;
        totalAzul = totalAzul + serviciosFinalizadosExcel[i].azul;
        totalCeleste = totalCeleste + serviciosFinalizadosExcel[i].celeste;
        totalBlanco = totalBlanco + serviciosFinalizadosExcel[i].blanco;
        totalNaranja = totalNaranja + serviciosFinalizadosExcel[i].naranja;
        serviciosFinalizadosExcel[i].total =
          serviciosFinalizadosExcel[i].rojo +
          serviciosFinalizadosExcel[i].amarillo +
          serviciosFinalizadosExcel[i].verde +
          serviciosFinalizadosExcel[i].fuccia +
          serviciosFinalizadosExcel[i].azul +
          serviciosFinalizadosExcel[i].celeste +
          serviciosFinalizadosExcel[i].blanco +
          serviciosFinalizadosExcel[i].naranja;
      }
      totalGeneral =
        totalRojo +
        totalAmarillo +
        totalVerde +
        totalFuccia +
        totalAzul +
        totalCeleste +
        totalBlanco +
        totalNaranja;

      for (var i = 0; i < serviciosFinalizadosExcel.length; i++) {
        serviciosFinalizadosExcel[i].porcentaje =
          (serviciosFinalizadosExcel[i].total * 100) / totalGeneral;
      }

      serviciosFinalizadosExcel.push({
        mes: "TOTAL",
        rojo: totalRojo,
        amarillo: totalAmarillo,
        verde: totalVerde,
        fuccia: totalFuccia,
        azul: totalAzul,
        celeste: totalCeleste,
        blanco: totalBlanco,
        naranja: totalNaranja,
        total: totalGeneral,
        porcentaje: 100,
      });

      let arrayEX = [];

      arrayEX.push({
        data: serviciosFinalizadosExcel,
      });

      let SETTINGS_FOR_EXPORT = {
        // Table settings
        fileName: "estadisticaAnual_" + anio,
        workSheets: [
          {
            sheetName: "estadisticaAnual_" + anio,
            startingRowNumber: 1,
            gapBetweenTwoTables: 2,
            tableSettings: {
              data: {
                importable: true,
                tableTitle: "Servicios Mes a Mes Año " + anio,
                headerDefinition: [
                  {
                    name: "Mes",
                    key: "mes",
                    width: 25,
                    style: { alignment: alignment.middleLeft },
                  },
                  {
                    name: "ROJO",
                    key: "rojo",
                    width: 18,
                  },
                  {
                    name: "AMARILLO",
                    key: "amarillo",
                    width: 18,
                  },
                  {
                    name: "VERDE",
                    key: "verde",
                    width: 18,
                  },
                  {
                    name: "FUCCIA",
                    key: "fuccia",
                    width: 18,
                  },
                  {
                    name: "AZUL",
                    key: "azul",
                    width: 18,
                  },
                  {
                    name: "CELESTE",
                    key: "celeste",
                    width: 18,
                  },
                  {
                    name: "BLANCO",
                    key: "blanco",
                    width: 18,
                  },
                  {
                    name: "NARANJA",
                    key: "naranja",
                    width: 18,
                  },
                  {
                    name: "Total",
                    key: "total",
                    width: 18,
                  },
                  {
                    name: "% Total de Servicios",
                    key: "porcentaje",
                    width: 18,
                    dataType: defaultDataType.number,
                  },
                ],
              },
            },
          },
        ],
      };

      const excelExport = new ExcelExport();
      excelExport.downloadExcel(SETTINGS_FOR_EXPORT, arrayEX);
    }
    startClick();
  };

  const exportPdf = () => {
    let totalRojo = 0;
    let totalAmarillo = 0;
    let totalVerde = 0;
    let totalFuccia = 0;
    let totalAzul = 0;
    let totalCeleste = 0;
    let totalBlanco = 0;
    let totalNaranja = 0;
    let totalGeneral = 0;
    for (var i = 0; i < serviciosFinalizadosExcel.length; i++) {
      totalRojo = totalRojo + serviciosFinalizadosExcel[i].rojo;
      totalAmarillo = totalAmarillo + serviciosFinalizadosExcel[i].amarillo;
      totalVerde = totalVerde + serviciosFinalizadosExcel[i].verde;
      totalFuccia = totalFuccia + serviciosFinalizadosExcel[i].fuccia;
      totalAzul = totalAzul + serviciosFinalizadosExcel[i].azul;
      totalCeleste = totalCeleste + serviciosFinalizadosExcel[i].celeste;
      totalBlanco = totalBlanco + serviciosFinalizadosExcel[i].blanco;
      totalNaranja = totalNaranja + serviciosFinalizadosExcel[i].naranja;
      serviciosFinalizadosExcel[i].total =
        serviciosFinalizadosExcel[i].rojo +
        serviciosFinalizadosExcel[i].amarillo +
        serviciosFinalizadosExcel[i].verde +
        serviciosFinalizadosExcel[i].fuccia +
        serviciosFinalizadosExcel[i].azul +
        serviciosFinalizadosExcel[i].celeste +
        serviciosFinalizadosExcel[i].blanco +
        serviciosFinalizadosExcel[i].naranja;
    }
    totalGeneral =
      totalRojo +
      totalAmarillo +
      totalVerde +
      totalFuccia +
      totalAzul +
      totalCeleste +
      totalBlanco +
      totalNaranja;

    for (var i = 0; i < serviciosFinalizadosExcel.length; i++) {
      serviciosFinalizadosExcel[i].porcentaje = parseFloat(
        (serviciosFinalizadosExcel[i].total * 100) / totalGeneral
      ).toFixed(2);
    }

    serviciosFinalizadosExcel.push({
      mes: "TOTAL",
      rojo: totalRojo,
      amarillo: totalAmarillo,
      verde: totalVerde,
      fuccia: totalFuccia,
      azul: totalAzul,
      celeste: totalCeleste,
      blanco: totalBlanco,
      naranja: totalNaranja,
      total: totalGeneral,
      porcentaje: 100.0,
    });

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Estadística de servicios mes a mes Año " + anio, 60, 8);
        doc.autoTable(exportColumns, serviciosFinalizadosExcel, {
          styles: {
            fontSize: 7,
          },
          columnStyles: {
            1: {
              halign: "right",
            },
            2: {
              halign: "right",
            },
            3: {
              halign: "right",
            },
            4: {
              halign: "right",
            },
            5: {
              halign: "right",
            },
            6: {
              halign: "right",
            },
            7: {
              halign: "right",
            },
            8: {
              halign: "right",
            },
            9: {
              halign: "right",
            },
            10: {
              halign: "right",
            },
          },
        });
        //doc.save("serviciosTopesSuperados.pdf");
        window.open(doc.output("bloburl"));
      });
    });
    startClick();
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Estadística de servicios mes a mes"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <button
          id="expButtonExcel"
          type="button"
          onClick={(e) => {
            exportExcel();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Excel
        </button>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>

        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={(e) => {
            aceptarFechas("excel");
            //exportExcel();
          }}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        >
          Excel
        </Button>
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarFechas("pdf");
          }}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        >
          PDF
        </Button>
        {/*
        <ButtonMui
          onClick={aceptarFechas}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        */}
        <ButtonMui
          onClick={exitClick}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(moviles);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "40vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="anio"
                  label="Año"
                  fullWidth
                  variant="standard"
                  type="number"
                  //onKeyDown={handleEnter}
                  value={anio ? anio : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setAnio(e.target.value);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              />
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
