import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { TabView, TabPanel } from "primereact/tabview";
import "../../../../assets/css/TabViewDemo.css";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { NumericFormat } from "react-number-format";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";

export default function Coseguros(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [coseguro, guardarCoseguro] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    coseguros,
    activeIndex,
    grupos,
    obtenerCoseguros,
    obtenerAcceso,
    guardarCoseguros,
    setActiveIndex,
    obtenerGrupos,
    eliminarCoseguro,
    actualizarCoseguro,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Coseguros" });
    }
    if (coseguros && coseguros.length === 0) {
      setActiveIndex(0);
      obtenerCoseguros({ grupo: 1 });
      obtenerGrupos();
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = (e) => {
    if (selectedRow) {
      guardarCoseguro(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un coseguro.",
        life: 3000,
      });
    }
  };

  const exitClick = (e) => {
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 2);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();
    //Validar

    if (coseguro.valorDiurnoPediatrico < 0) {
      mensajeAlerta(
        "valor Diurno Pediátrico no puede ser menor que cero !.",
        "error"
      );
      return;
    }

    if (coseguro.valorNocturnoPediatrico < 0) {
      mensajeAlerta(
        "valor Nocturno Pediátrico no puede ser menor que cero !.",
        "error"
      );
      return;
    }

    if (coseguro.valorDiurnoAdulto < 0) {
      mensajeAlerta(
        "valor Diurno Adulto no puede ser menor que cero !.",
        "error"
      );
      return;
    }

    if (coseguro.valorNocturnoAdulto < 0) {
      mensajeAlerta(
        "valor Nocturno Adulto no puede ser menor que cero !.",
        "error"
      );
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarCoseguro({ coseguro });
      //Actualizar array
      let index = coseguros.findIndex((item) => item.id === coseguro.id);
      let cos = [...coseguros];
      let item = cos[index];
      item.valorDiurnoPediatrico = coseguro.valorDiurnoPediatrico;
      item.valorNocturnoPediatrico = coseguro.valorNocturnoPediatrico;
      item.valorDiurnoAdulto = coseguro.valorDiurnoAdulto;
      item.valorNocturnoAdulto = coseguro.valorNocturnoAdulto;
      cos[index] = item;
      //console.log(cos)
      guardarCoseguros(cos);
    } else {
      navigate(`/coseguros`);
    }
    onHide();
    setSelectedRow(null);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const cargarGrupo = (dato) => {
    guardarCoseguros([]);
    setActiveIndex(dato);
    obtenerCoseguros({ grupo: Number(dato) + 1 });
  };

  const confirm = () => {
    if (!selectedRow) {
      toast.current.show({
        severity: "info",
        summary: "Eliminar",
        detail: "Debe seleccionar un coseguro.",
        life: 3000,
      });
      return;
    }

    const { id, planNombre, servicioNombre, afiliadoNombre } = selectedRow;

    //console.log(selectedRow)

    let data = planNombre !== undefined ? planNombre : afiliadoNombre;

    confirmDialog({
      message:
        "Confirma eliminar coseguro " + data + " " + servicioNombre + " ?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      accept: () => acceptFunc(id),
      reject: () => rejectFunc(),
    });
  };

  const acceptFunc = (id) => {
    eliminarCoseguro(id);
    obtenerCoseguros({ grupo: Number(activeIndex) + 1 });
    //exitClick()
  };

  const rejectFunc = () => {};

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const idAfiliadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID Afiliado</span>
        {rowData.afiliadoId}
      </Fragment>
    );
  };

  const planTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Plan</span>
        {rowData.planNombre}
      </Fragment>
    );
  };

  const afiliadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Plan</span>
        {rowData.afiliadoNombre}
      </Fragment>
    );
  };

  const servicioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Servicio</span>
        {rowData.servicioNombre}
      </Fragment>
    );
  };

  const valorDiurnoPediatricoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Valor Diurno Pediátrico</span>
        {parseFloat(rowData.valorDiurnoPediatrico).toFixed(2)}
      </Fragment>
    );
  };

  const valorNocturnoPediatricoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Valor Nocturno Pediátrico</span>
        {parseFloat(rowData.valorNocturnoPediatrico).toFixed(2)}
      </Fragment>
    );
  };

  const valorDiurnoAdultoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Valor Diurno Adulto</span>
        {parseFloat(rowData.valorDiurnoAdulto).toFixed(2)}
      </Fragment>
    );
  };

  const valorNocturnoAdultoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Valor Nocturno Adulto</span>
        {parseFloat(rowData.valorNocturnoAdulto).toFixed(2)}
      </Fragment>
    );
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Coseguros"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Coseguro"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(coseguros);
  //console.log(grupos);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      <ConfirmDialog />
      {statusAcceso === 0 ||
      statusAcceso === 400 ||
      !coseguros ||
      coseguros.length === 0 ? (
        <Spinner />
      ) : null}
      {statusAcceso === 200 && coseguros ? (
        <Fragment>
          <Grid id="dataTableBotones" item xs={12} sm={12} md={12}>
            <div className="card">
              <TabView
                className="tabview-custom"
                activeIndex={activeIndex}
                onTabChange={(e) => cargarGrupo(e.index)}
              >
                {grupos.map((item, i) =>
                  item.id === 1 ? (
                    <TabPanel header="Planes" leftIcon="pi pi-users">
                      <Button
                        icon="pi pi-pencil"
                        tooltip="Editar"
                        className="p-button-rounded p-button-info"
                        style={{ marginLeft: "1em" }}
                        onClick={editClick}
                      />
                      <Button
                        icon="pi pi-trash"
                        tooltip="Eliminar"
                        className="p-button-rounded p-button-danger"
                        style={{ marginLeft: "1em" }}
                        onClick={confirm}
                      />
                      <Button
                        icon="pi pi-times"
                        tooltip="Cerrar"
                        className="p-button-rounded p-button-warning"
                        style={{ marginLeft: "1em" }}
                        onClick={exitClick}
                      />

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ align: "center", margin: "1em" }}
                      />
                      <div className="datatable-responsive-demo">
                        <DataTable
                          value={coseguros}
                          selection={selectedRow}
                          onSelectionChange={(e) => setSelectedRow(e.value)}
                          selectionMode="single"
                          dataKey="id"
                          header={header}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            style={{ display: "none" }}
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="planNombre"
                            header="Plan"
                            body={planTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="servicioNombre"
                            header="Servicios"
                            body={servicioTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="valorDiurnoPediatrico"
                            header="Valor Diurno Pediátrico"
                            body={valorDiurnoPediatricoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="valorNocturnoPediatrico"
                            header="Valor Nocturno Pediátrico"
                            body={valorNocturnoPediatricoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="valorDiurnoAdulto"
                            header="Valor Diurno Adulto"
                            body={valorDiurnoAdultoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="valorNocturnoAdulto"
                            header="Valor Nocturno Adulto"
                            body={valorNocturnoAdultoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </TabPanel>
                  ) : (
                    <TabPanel
                      header={
                        item.nombre.charAt(0).toUpperCase() +
                        item.nombre.slice(1).toLowerCase()
                      }
                      leftIcon="pi pi-id-card"
                    >
                      <Button
                        icon="pi pi-pencil"
                        tooltip="Editar"
                        className="p-button-rounded p-button-info"
                        style={{ marginLeft: "1em" }}
                        onClick={editClick}
                      />
                      <Button
                        icon="pi pi-trash"
                        tooltip="Eliminar"
                        className="p-button-rounded p-button-danger"
                        style={{ marginLeft: "1em" }}
                        onClick={confirm}
                      />
                      <Button
                        icon="pi pi-times"
                        tooltip="Cerrar"
                        className="p-button-rounded p-button-warning"
                        style={{ marginLeft: "1em" }}
                        onClick={exitClick}
                      />
                      <div className="datatable-responsive-demo">
                        <DataTable
                          value={coseguros}
                          selection={selectedRow}
                          onSelectionChange={(e) => setSelectedRow(e.value)}
                          selectionMode="single"
                          dataKey="id"
                          header={header}
                          className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                          style={{ fontSize: "small" }}
                          globalFilter={globalFilter}
                          emptyMessage="No hay datos."
                          paginator
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                          rows={10}
                          rowsPerPageOptions={[10, 20, 50]}
                          //paginatorLeft={paginatorLeft}
                          //paginatorRight={paginatorRight}
                        >
                          <Column
                            field="id"
                            header="ID"
                            style={{ display: "none" }}
                            body={idTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="afiliadoId"
                            header="ID Afiliado"
                            body={idAfiliadoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="afiliadoNombre"
                            header="Afiliado"
                            body={afiliadoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="servicioNombre"
                            header="Servicios"
                            body={servicioTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="valorDiurnoPediatrico"
                            header="Valor Diurno Pediátrico"
                            body={valorDiurnoPediatricoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="valorNocturno"
                            header="Valor Nocturno Pediátrico"
                            body={valorNocturnoPediatricoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="valorDiurnoAdulto"
                            header="Valor Diurno Adulto"
                            body={valorDiurnoAdultoTemplate}
                            sortable
                          ></Column>
                          <Column
                            field="valorNocturnoAdulto"
                            header="Valor Nocturno Adulto"
                            body={valorNocturnoAdultoTemplate}
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                    </TabPanel>
                  )
                )}
              </TabView>
            </div>
          </Grid>
          <Dialog
            header={renderHeader()}
            visible={visible}
            closable={false}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {coseguro && coseguro.grupoId > 1 ? (
                  <TextField
                    value={coseguro.afiliadoId}
                    required
                    id="id"
                    label="ID Afiliado"
                    fullWidth
                    variant="standard"
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} md={12}>
                {coseguro && coseguro.grupoId === 1 ? (
                  <TextField
                    value={coseguro.planNombre}
                    required
                    id="plan"
                    label="Plan"
                    fullWidth
                    variant="standard"
                  />
                ) : null}
                {coseguro && coseguro.grupoId > 1 ? (
                  <TextField
                    value={coseguro.afiliadoNombre}
                    required
                    id="nombre"
                    label="Nombre"
                    fullWidth
                    variant="standard"
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  value={coseguro ? coseguro.servicioNombre : null}
                  required
                  id="servicio"
                  label="Servicio"
                  fullWidth
                  variant="standard"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <NumericFormat
                  {...props}
                  id="valorDiurnoPediatrico"
                  label="Valor Diurno Pediátrico"
                  customInput={TextField}
                  type="numeric"
                  value={
                    coseguro
                      ? parseFloat(coseguro.valorDiurnoPediatrico).toFixed(2)
                      : null
                  }
                  fullWidth
                  variant="standard"
                  size="small"
                  isNumericString
                  onChange={(e) =>
                    guardarCoseguro({
                      ...coseguro,
                      valorDiurnoPediatrico: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <NumericFormat
                  {...props}
                  id="valorNocturnoPediatrico"
                  label="Valor Nocturno Pediátrico"
                  customInput={TextField}
                  type="numeric"
                  value={
                    coseguro
                      ? parseFloat(coseguro.valorNocturnoPediatrico).toFixed(2)
                      : null
                  }
                  fullWidth
                  variant="standard"
                  size="small"
                  isNumericString
                  onChange={(e) =>
                    guardarCoseguro({
                      ...coseguro,
                      valorNocturnoPediatrico: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <NumericFormat
                  {...props}
                  id="valorDiurnoAdulto"
                  label="Valor Diurno Adulto"
                  customInput={TextField}
                  type="numeric"
                  value={
                    coseguro
                      ? parseFloat(coseguro.valorDiurnoAdulto).toFixed(2)
                      : null
                  }
                  fullWidth
                  variant="standard"
                  size="small"
                  isNumericString
                  onChange={(e) =>
                    guardarCoseguro({
                      ...coseguro,
                      valorDiurnoAdulto: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <NumericFormat
                  {...props}
                  id="valorNocturnoAdulto"
                  label="Valor Nocturno Adulto"
                  customInput={TextField}
                  type="numeric"
                  value={
                    coseguro
                      ? parseFloat(coseguro.valorNocturnoAdulto).toFixed(2)
                      : null
                  }
                  fullWidth
                  variant="standard"
                  size="small"
                  isNumericString
                  onChange={(e) =>
                    guardarCoseguro({
                      ...coseguro,
                      valorNocturnoAdulto: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
