import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from "primereact/checkbox";
import Autocomplete from '@mui/material/Autocomplete';
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from '@mui/material/Fab';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from '@mui/material/Button';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";

export default function AlmacenesMoviles(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Móvil", dataKey: "movil" },
    { title: "Almacen", dataKey: "almacen" },
  ];
  const [almacenMovil, guardarAlmacenMovil] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate();

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    almacenesMoviles,
    moviles,
    almacenes,
    obtenerAlmacenesMoviles,
    obtenerAcceso,
    eliminarAlmacenMovil,
    actualizarAlmacenMovil,
    obtenerMoviles,
    obtenerAlmacenes,
    agregarAlmacenMovil,
  } = configuracionContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({
        usuarioId: state.usuario.id,
        layout: "AlmacenesMoviles",
      });
    }
    obtenerAlmacenesMoviles();
    obtenerMoviles({ propios: true });
    obtenerAlmacenes();
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);



  const deleteClick = () => {
    if (selectedRow) {
      confirmDialog({
        message:
          "Esta seguro de eliminar el almacen del " + selectedRow.movil + " ? ",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si",
        acceptIcon: "pi pi-check",
        rejectIcon: "pi pi-times",
        accept,
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Eliminar",
        detail: "Debe seleccionar el almacen de móvil.",
        life: 3000,
      });
    }
  };

  const accept = () => {
    eliminarAlmacenMovil({ selectedRow });
    setTimeout(() => {
      obtenerAlmacenesMoviles();
    }, 2000);
  };

  const addClick = (e) => {
    //navigate(`/almacenmovil/0`);
    setVisible(true);
    setSelectedRow(null);
  };

  const exitClick = (e) => {
   navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(3, 1);
  };

  const onHide = (e) => {
    setVisible(false);
  };

  const aceptarClick = (e) => {
    e.preventDefault();

    //Validar
    if (
      !almacenMovil ||
      almacenMovil.movilId === undefined ||
      almacenMovil.movilId === null ||
      almacenMovil.movilId <= 0
    ) {
      mensajeAlerta("El móvil es necesario !.", "error");
      return;
    }

    if (
      !almacenMovil ||
      almacenMovil.almacenId === undefined ||
      almacenMovil.almacenId === null ||
      almacenMovil.almacenId <= 0
    ) {
      mensajeAlerta("El almacén es necesario !.", "error");
      return;
    }

    // Llamar al context
    if (selectedRow) {
      actualizarAlmacenMovil({ almacenMovil });
    } else {
      agregarAlmacenMovil({ almacenMovil });
    }
    onHide();
    setSelectedRow(null);
    setTimeout(() => {
      obtenerAlmacenesMoviles();
    }, 2000);
    //exitClick();
  };

  function filtarMoviles(elemento) {
    for (var i = 0; i < almacenesMoviles.length; i++) {
      if (almacenesMoviles[i].movilId === elemento.id) {
        return false;
      }
    }
    return true;
  }

  function filtarAlmacenes(elemento) {
    console.log('si')
    return
    for (var i = 0; i < almacenesMoviles.length; i++) {
      if (almacenesMoviles[i].almacenId === elemento.id) {
        return false;
      }
    }
    return true;
  }

  const accesoDenegado = () => {
    mensajeAlerta("Accesos", "No tiene permiso para acceder a esta pantalla !.", "error");
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const movilTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Móvil</span>
        {rowData.movil}
      </Fragment>
    );
  };

  const almacenTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Almacen</span>
        {rowData.almacen}
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(12);
        doc.text("Listado de Almacenes de Móviles", 60, 8);
        doc.autoTable(exportColumns, almacenesMoviles);
        //doc.save("almacenesmoviles.pdf");
        window.open(doc.output('bloburl'))
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(almacenesMoviles);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "almacenesmoviles");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Almacenes de móviles"></Tag>
      </div>
    </Fragment>
  );

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Almacen de Móvil"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarClick}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHide}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(almacenes ? almacenes.length: null);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          {almacenesMoviles  ? (
            <Fragment>
              <Grid
                id="dataTableBotones"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <Button
                  icon="pi pi-plus"
                  tooltip="Agregar"
                  className="p-button-rounded p-button-help"
                  style={{ marginLeft: "0em" }}
                  onClick={addClick}
                />
                <Button
                  icon="pi pi-trash"
                  tooltip="Eliminar"
                  className="p-button-rounded p-button-danger"
                  style={{ marginLeft: "1em" }}
                  onClick={deleteClick}
                />
                <Button
                  icon="pi pi-times"
                  tooltip="Cerrar"
                  className="p-button-rounded p-button-primary"
                  style={{ marginLeft: "1em" }}
                  onClick={exitClick}
                />
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Tooltip
                      target=".export-buttons>button"
                      position="bottom"
                    />
                    <DataTable
                      value={almacenesMoviles}
                      selection={selectedRow}
                      onSelectionChange={(e) => setSelectedRow(e.value)}
                      selectionMode="single"
                      dataKey="id"
                      header={header}
                      className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                      style={{ fontSize: "small" }}
                      globalFilter={globalFilter}
                      emptyMessage="No hay datos."
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      //paginatorLeft={paginatorLeft}
                      //paginatorRight={paginatorRight}
                    >
                      <Column
                        field="id"
                        header="ID"
                        body={idTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="movil"
                        header="Movil"
                        body={movilTemplate}
                        sortable
                      ></Column>
                      <Column
                        field="almacen"
                        header="Almacen"
                        body={almacenTemplate}
                        sortable
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Grid>

              <Dialog
                header={renderHeader()}
                visible={visible}
                closable={false}
                footer={renderFooter()}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", paddingBottom: "0" }}
                position="center"
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="moviles"
                      fullWidth
                      options={moviles && moviles.length>0 ? moviles.filter(filtarMoviles): []}
                      disablePortal
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField {...params} label="Móviles" />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarAlmacenMovil({
                            ...almacenMovil,
                            movilId: value.id,
                          });
                        } else {
                          guardarAlmacenMovil({
                            ...almacenMovil,
                            movilId: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="almacenes"
                      fullWidth
                      options={almacenes && almacenes.length>0 ? almacenes.filter(filtarAlmacenes): []}
                      disablePortal
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField {...params} label="Almacenes" />
                      )}
                      //onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarAlmacenMovil({
                            ...almacenMovil,
                            almacenId: value.id,
                          });
                        } else {
                          guardarAlmacenMovil({
                            ...almacenMovil,
                            almacenId: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Fragment>
          ) : (
            <Spinner />
          )}
          <Grid item xs={12} sm={12}></Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
