import React, { Component, Fragment } from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
//import { makeStyles } from 'tss-react/mui';
import { styled } from "@mui/material/styles";
import logo3 from "../../assets/images/ambulanceapp.png";
import Barra from "./Barra";

/*
const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    height: "30px",
    width: "100%",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      align: "center",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "25vw",

    },
    [theme.breakpoints.up("sm")]: {
      align: "center",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "1.5vw",

    },
  },

}));
*/

export default function Logo() {
  //const classes = useStyles();

  return (
    <Fragment>
      <Barra />
      <div class="container"> 
      <img
        src={logo3}
        alt="Logo"
        width="40%"
        display="block"
        margin="auto"
        className="logo"

        //style={{ marginLeft: "2em", marginTop: "-0.3em" }}
      />
      </div>
    </Fragment>
  );
}
