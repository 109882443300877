import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "../servicios/ConsultaServicio";
import NotasServicio from "../servicios/NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import hcOffcanvasNav from "hc-offcanvas-nav";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { addLocale } from "primereact/api";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

export default function EstadisticaServiciosSemanales(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "Nombre Titular", dataKey: "nombre" },
    { title: "    (01 al 07)", dataKey: "semana1" },
    { title: "    (08 al 14)", dataKey: "semana2" },
    { title: "    (15 al 21)", dataKey: "semana3" },
    { title: "    (22 al 28)", dataKey: "semana4" },
    { title: "    (29 a fin)", dataKey: "semana5" },
    { title: "         Total", dataKey: "totalPeriodo" },
  ];

  const [visibleFechas, setVisibleFechas] = useState(true);
  const [periodo, setPeriodo] = useState(null);
  const [traslados, setTraslados] = useState(false);
  const [color, setColor] = useState(null);
  const toast = useRef(null);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    configuracion,
    statusAcceso,
    colores,
    obtenerAcceso,
    obtenerColores,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    serviciosFinalizados,
    serviciosFinalizadosExcel,
    obtenerServiciosFinalizados,
    obtenerServiciosSemanales,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Listados" });
    }
  }, [state.id]);

  useEffect(() => {
    obtenerColores();
  }, []);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = month === 0 ? 11 : month - 1;
  let prevYear = prevMonth === 11 ? year - 1 : year;
  let nextMonth = month === 11 ? 0 : month + 1;
  let nextYear = nextMonth === 0 ? year + 1 : year;

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Claro",
  });

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const exitClick = (e) => {
    onHideFechas();
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(1, 2);
  };

  const startClick = (e) => {
    setTimeout(() => {
      navigate(`/estadisticaserviciossemanales`);
      setVisibleFechas(true);
    }, 2000);
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const aceptarFechas = async (exportType) => {
    //e.preventDefault();
    //Validar

    if (
      !periodo ||
      periodo === undefined ||
      periodo === null ||
      periodo === ""
    ) {
      mensajeAlerta(
        "Estadística de Servicios",
        "Período es necesario !.",
        "error"
      );
      return;
    }

    if (
      !color ||
      color === undefined ||
      color === null ||
      color === "" ||
      color.length === 0
    ) {
      mensajeAlerta(
        "Estadística de Servicios",
        "Debe seleccionar un color !.",
        "error"
      );
      return;
    }

    var elem;

    await obtenerServiciosSemanales({
      periodo,
      color,
    }).then((data) => {
      if (exportType === "excel") {
        elem = document.getElementById("expButtonExcel");
        elem.click();
      }
      if (exportType === "pdf") {
        elem = document.getElementById("expButtonPdf");
        elem.click();
      }
    });
    onHideFechas();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const exportPdf = () => {
    let totalSemana1 = 0;
    let totalSemana2 = 0;
    let totalSemana3 = 0;
    let totalSemana4 = 0;
    let totalSemana5 = 0;
    let totalGeneral = 0;
    for (var i = 0; i < serviciosFinalizadosExcel.length; i++) {
      totalSemana1 = totalSemana1 + serviciosFinalizadosExcel[i].semana1;
      totalSemana2 = totalSemana2 + serviciosFinalizadosExcel[i].semana2;
      totalSemana3 = totalSemana3 + serviciosFinalizadosExcel[i].semana3;
      totalSemana4 = totalSemana4 + serviciosFinalizadosExcel[i].semana4;
      totalSemana5 = totalSemana5 + serviciosFinalizadosExcel[i].semana5;
      totalGeneral = totalGeneral + serviciosFinalizadosExcel[i].totalPeriodo;
    }

    serviciosFinalizadosExcel.push({
      nombre: "TOTAL",
      semana1: totalSemana1,
      semana2: totalSemana2,
      semana3: totalSemana3,
      semana4: totalSemana4,
      semana5: totalSemana5,
      totalPeriodo: totalGeneral,
    });

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        let peri = new Date(periodo);
        peri = peri.toISOString();
        peri = peri.substring(5, 7) + "/" + peri.substring(0, 4);

        let leyenda = "";
        for (var i = 0; i < color.length; i++) {
          leyenda = leyenda + color[i].nombre + "-";
        }

        doc.setFontSize(10);
        doc.text(
          "Estadística de servicios mensual por semanas período " + peri,
          50,
          8
        );
        doc.setFontSize(8);
        doc.text("Servicios de 08:00 a 08:00 hs. Colores  " + leyenda, 15, 13);

        doc.setFontSize(10);
        doc.autoTable(exportColumns, serviciosFinalizadosExcel, {
          styles: {
            fontSize: 7,
          },
          columnStyles: {
            1: {
              halign: "right",
            },
            2: {
              halign: "right",
            },
            3: {
              halign: "right",
            },
            4: {
              halign: "right",
            },
            5: {
              halign: "right",
            },
            6: {
              halign: "right",
            },
            7: {
              halign: "right",
            },
            8: {
              halign: "right",
            },
            9: {
              halign: "right",
            },
            10: {
              halign: "right",
            },
          },
        });
        //doc.save("serviciosTopesSuperados.pdf");
        window.open(doc.output("bloburl"));
      });
    });
    startClick();
  };

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Estadística de servicios mensual por semanas"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <button
          id="expButtonPdf"
          type="button"
          onClick={(e) => {
            exportPdf();
          }}
          style={{ visibility: "hidden" }}
        >
          Export Pdf
        </button>

        {/*  
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={(e) => {
            aceptarFechas("pdf");
          }}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        >
          ACEPTAR
        </Button>
        */}

        <ButtonMui
          onClick={(e) => {
            aceptarFechas("pdf");
          }}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>

        <ButtonMui
          onClick={exitClick}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  //console.log(moviles);
  //console.log(serviciosFinalizadosExcel);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "40vw", paddingBottom: "0" }}
            position="top"
          >
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={6}>
                <label htmlFor="monthpicker">Período</label>
                <Calendar
                  id="monthpicker"
                  value={periodo}
                  onChange={(e) => setPeriodo(e.value)}
                  view="month"
                  dateFormat="mm/yy"
                  locale="es"
                  style={{marginLeft: "1vw"}}
                  yearNavigator
                  yearRange="2022:2030"
                />{" "}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 0, marginTop: "-1vw" }}
              >
                <Autocomplete
                  id="colores"
                  multiple={true}
                  fullWidth
                  options={colores}
                  noOptionsText={"No hay opciones"}
                  style={{ marginLeft: ".8vw" }}
                  disableCloseOnSelect
                  disablePortal
                  getOptionLabel={(option) => option.nombre}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nombre}
                      </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Colores" variant="standard" />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      setColor(value);
                    } else {
                      setColor(null);
                    }
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              />
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
