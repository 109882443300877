import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { NumericFormat } from "react-number-format";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Calendar } from "primereact/calendar";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import {
  locale,
  addLocale,
  updateLocaleOption,
  updateLocaleOptions,
  localeOption,
  localeOptions,
} from "primereact/api";
//import { selectedRowsCountSelector } from "@material-ui/data-grid";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../../assets/css/DialogDemo.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
  latLngBound,
} from "react-leaflet";
import "./Map.css";

export default function TomaAzul(props) {
  //const classes = useStyles();

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
  });

  locale("es");
  let minDate = new Date();

  const serviciosContext = useContext(ServiciosContext);
  const {
    afiliado,
    servicioSeleccionado,
    tomaAzul,
    direcciones,
    guardarTomaAzul,
    obtenerDirecciones,
    guardarDirecciones,
  } = serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, tiposMoviles, diagnosticos, obtenerTiposMoviles } =
    configuracionContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    //obtenerDiagnosticos({ todos: false });
    obtenerTiposMoviles();
    guardarDirecciones([]);
  }, []);

  //Dialog

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Leer tecla y foco

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "llegada":
          campo = document.getElementById("tiposMoviles");
          campo.focus();
          break;
        case "tiposMoviles":
          campo = document.getElementById("prestador");
          campo.focus();
          break;
        case "prestador":
          campo = document.getElementById("espera");
          campo.focus();
          break;
        case "espera":
          campo = document.getElementById("retorno");
          campo.focus();
          break;
        case "retorno":
          campo = document.getElementById("provinciaAzul");
          campo.focus();
          break;
        case "provinciaAzul":
          campo = document.getElementById("destinoAzul");
          campo.focus();
          break;
        case "destinoAzul":
          campo = document.getElementById("destinoGeoAzul");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "destinoGeoAzul":
          campo = document.getElementById("destinoAzul");
          campo.focus();
          break;
        case "destinoAzul":
          campo = document.getElementById("provinciaAzul");
          campo.focus();
          break;
        case "provinciaAzul":
          campo = document.getElementById("retorno");
          campo.focus();
          break;

        case "retorno":
          campo = document.getElementById("espera");
          campo.focus();
          break;
        case "espera":
          campo = document.getElementById("tiposMoviles");
          campo.focus();
          break;
        case "tiposMoviles":
          campo = document.getElementById("llegada");
          campo.focus();
          break;
      }
    }
  }

  const renderHeaderMapa = () => {
    return (
      <div>
        <Tag value="Ubicación en el Mapa"></Tag>
      </div>
    );
  };

  const renderFooterMapa = () => {
    return (
      <div>
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleClose()}
          className="p-button-text"
        />
      </div>
    );
  };

  function PopupExample(e) {
    if (
      tomaAzul.lat === undefined ||
      tomaAzul.lat === null ||
      tomaAzul.lon === undefined ||
      tomaAzul.lon === null
    ) {
      return;
    }

    let position = [];
    position.push(tomaAzul.lat);
    position.push(tomaAzul.lon);

    return (
      <MapContainer
        center={position}
        zoom={15}
        style={{ height: "60vh", width: "60vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}></Marker>
      </MapContainer>
    );
  }

  function clearLocalidad() {
    guardarTomaAzul({
      ...tomaAzul,
      localidad: "",
    });
  }

  const handleInputBlur = (event) => {
    clearLocalidad();
    obtenerDirecciones({
      domicilio: tomaAzul.destino !== undefined ? tomaAzul.destino.toLowerCase() : "",
      provincia: tomaAzul.provincia !== undefined ? tomaAzul.provincia.toLowerCase() : "",
    });
  };

  //console.log(direcciones);

  return (
    <Fragment>
      <Grid container spacing={1} style={{ padding: "0" }}>
        <Grid item xs={12} md={12} style={{ marginTop: "1em" }}>
          <label htmlFor="multiple">Llegada</label>
          <Calendar
            id="llegada"
            dateFormat="dd/mm/yy"
            selectionMode="multiple"
            locale="es"
            minDate={minDate}
            value={tomaAzul ? tomaAzul.llegada : null}
            baseZIndex={5001}
            showTime
            required
            //onKeyDown={handleEnter}
            //breakpoints={{ "960px": "45vw" }}
            style={{
              width: "40vw",
              paddingBottom: "0",
              overflowY: "visible",
              marginLeft: "1em",
            }}
            panelStyle={{ marginLeft: "50vw" }}
            showButtonBar={true}
            onChange={(e) =>
              guardarTomaAzul({
                ...tomaAzul,
                llegada: e.value,
              })
            }
          ></Calendar>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginTop: ".5em", marginBottom: "1em" }}
        >
          <Autocomplete
            id="tiposMoviles"
            fullWidth
            disablePortal
            required
            options={tiposMoviles}
            onKeyDown={handleEnter}
            //inputValue={tiposMoviles[1].nombre}
            defaultValue={
              tomaAzul && tomaAzul.tipoId !== undefined
                ? tiposMoviles[
                    tiposMoviles.findIndex(
                      (tipo) => tipo.id === tomaAzul.tipoId
                    )
                  ]
                : null
            }
            //defaultValue={tiposMoviles[1]}
            getOptionLabel={(option) => option.nombre}
            renderInput={(params) => (
              <TextField {...params} label="Tipo de móvil" variant="standard" />
            )}
            //onKeyDown={handleEnter}
            onChange={(event, value) => {
              if (value) {
                guardarTomaAzul({
                  ...tomaAzul,
                  tipoId: value.id,
                });
              } else {
                guardarTomaAzul({
                  ...tomaAzul,
                  tipoId: null,
                });
              }
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ marginTop: ".8vw" }}>
          <TextField
            id="prestador"
            label="Prestador a Asignar"
            fullWidth
            variant="standard"
            size="small"
            value={tomaAzul ? tomaAzul.prestador : null}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaAzul({
                ...tomaAzul,
                prestador: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <NumericFormat
            {...props}
            id="espera"
            label="Espera en minutos"
            customInput={TextField}
            format="###"
            type="numeric"
            fullWidth
            variant="standard"
            size="small"
            value={tomaAzul ? tomaAzul.espera : 0}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaAzul({
                ...tomaAzul,
                espera: e.target.value,
              })
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          style={{ paddingLeft: 0, marginTop: "0.8em" }}
        >
          <FormControlLabel
            value="yes"
            control={
              <Checkbox
                id="retorno"
                color="primary"
                checked={tomaAzul ? tomaAzul.retorno : false}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarTomaAzul({
                    ...tomaAzul,
                    retorno: e.target.checked,
                  })
                }
              />
            }
            label="Retorno"
            labelPlacement="start"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id="provinciaAzul"
            label="Provincia"
            value={tomaAzul ? tomaAzul.provincia : null}
            required
            fullWidth
            variant="standard"
            size="small"
            //className={classes.textField}
            onKeyDown={handleEnter}
            onChange={(e) =>
              guardarTomaAzul({
                ...tomaAzul,
                provincia: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            id="destinoAzul"
            label="Destino"
            value={tomaAzul ? tomaAzul.destino : null}
            fullWidth
            variant="standard"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            //className={classes.textField}
            onKeyDown={handleEnter}
            onBlur={(e) => handleInputBlur(e)}
            onChange={(e) =>
              guardarTomaAzul({
                ...tomaAzul,
                destino: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={11}>
          <Autocomplete
            id="destinoGeoAzul"
            fullWidth
            disablePortal
            noOptionsText={"No hay opciones"}
            options={direcciones}
            getOptionLabel={(option) => option.label}
            openOnFocus={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Destino Geolocalizado"
                variant="standard"
              />
            )}
            onKeyDown={handleEnter}
            onChange={(event, value) => {
              if (value) {
                guardarTomaAzul({
                  ...tomaAzul,
                  destinoGeo: value.label,
                  lat: value.lat,
                  lon: value.lon,
                });
              } else {
                guardarTomaAzul({
                  ...tomaAzul,
                  destinoGeo: null,
                  lat: null,
                  lon: null,
                });
              }
            }}
          />
        </Grid>

        <Grid item xs={4} md={1}>
          <IconButton
            type="submit"
            //className={classes.iconButton}
            aria-label="search"
            onClick={(e) => handleClickOpen()}
          >
            <SearchIcon />
          </IconButton>
        </Grid>

        <div className="dialog-demo">
          <Dialog
            header={renderHeaderMapa()}
            visible={open}
            footer={renderFooterMapa()}
            closable={false}
            style={{ zIndex: 5000 }}
          >
            {PopupExample()}
          </Dialog>
        </div>
      </Grid>
    </Fragment>
  );
}
