import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from '@mui/material/Autocomplete';
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { NumericFormat } from 'react-number-format';
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";


export default function CierreFuccia(props) {
  const serviciosContext = useContext(ServiciosContext);
  const { afiliado, servicioSeleccionado, cierreFuccia, guardarCierreFuccia } =
    serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, almacenMovil, diagnosticos, obtenerDiagnosticos } =
    configuracionContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    obtenerDiagnosticos({ todos: false });
  }, []);

  //const theme = useTheme();
  //const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Leer tecla y foco

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "nsj":
          campo = document.getElementById("js");
          campo.focus();
          break;
        case "js":
          campo = document.getElementById("j");
          campo.focus();
          break;
        case "j":
          campo = document.getElementById("ct");
          campo.focus();
          break;
        case "ct":
          campo = document.getElementById("int");
          campo.focus();
          break;
        case "int":
          campo = document.getElementById("trabaja");
          campo.focus();
          break;
        case "trabaja":
          campo = document.getElementById("fechaTrabaja");
          campo.focus();
          break;
        case "fechaTrabaja":
          campo = document.getElementById("citado");
          campo.focus();
          break;
        case "citado":
          campo = document.getElementById("fechaCitado");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "fechaCitado":
          campo = document.getElementById("citado");
          campo.focus();
          break;
        case "citado":
          campo = document.getElementById("fechaTrabaja");
          campo.focus();
          break;
        case "fechaTrabaja":
          campo = document.getElementById("trabaja");
          campo.focus();
          break;
        case "trabaja":
          campo = document.getElementById("int");
          campo.focus();
          break;
        case "int":
          campo = document.getElementById("ct");
          campo.focus();
          break;
        case "ct":
          campo = document.getElementById("j");
          campo.focus();
          break;
        case "j":
          campo = document.getElementById("js");
          campo.focus();
          break;
        case "js":
          campo = document.getElementById("nsj");
          campo.focus();
          break;
      }
    }
  }

  //console.log(cierreFuccia)

  return (
    <Fragment>
      <Grid container spacing={0} style={{ padding: "0" }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{ paddingLeft: 0, marginTop: "0.8em" }}
        >
          <FormControlLabel
            value="yes"
            control={
              <Checkbox
                id="nsj"
                color="primary"
                checked={cierreFuccia ? cierreFuccia.nsj : false}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarCierreFuccia({
                    ...cierreFuccia,
                    nsj: e.target.checked,
                  })
                }
              />
            }
            label="No se Justifica"
            labelPlacement="start"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ paddingLeft: 0, marginTop: "0.8em" }}
        >
          <FormControlLabel
            value="yes"
            control={
              <Checkbox
                id="j"
                color="primary"
                checked={cierreFuccia ? cierreFuccia.j : false}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarCierreFuccia({
                    ...cierreFuccia,
                    j: e.target.checked,
                  })
                }
              />
            }
            label="Justifica"
            labelPlacement="start"
          />
        </Grid>


        <Grid
          item
          xs={12}
          md={6}
          style={{ paddingLeft: 0, marginTop: "0.8em" }}
        >
          <FormControlLabel
            value="yes"
            control={
              <Checkbox
                id="nsj"
                color="primary"
                checked={cierreFuccia ? cierreFuccia.js : false}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarCierreFuccia({
                    ...cierreFuccia,
                    js: e.target.checked,
                  })
                }
              />
            }
            label="Justificación en Suspenso"
            labelPlacement="start"
          />
        </Grid>



        <Grid
          item
          xs={12}
          md={6}
          style={{ paddingLeft: 0, marginTop: "0.8em" }}
        >
          <FormControlLabel
            value="yes"
            control={
              <Checkbox
                id="int"
                color="primary"
                checked={cierreFuccia ? cierreFuccia.int : false}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarCierreFuccia({
                    ...cierreFuccia,
                    int: e.target.checked,
                  })
                }
              />
            }
            label="Internado"
            labelPlacement="start"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          style={{ paddingLeft: 0, marginTop: "1em" }}
        />

        <Grid
          item
          xs={12}
          md={6}
          style={{ paddingLeft: 0, marginTop: "0.8em" }}
        >
          <FormControlLabel
            value="yes"
            control={
              <Checkbox
                id="trabaja"
                color="primary"
                checked={cierreFuccia ? cierreFuccia.trabaja : false}
                onKeyDown={handleEnter}
                onChange={(e) => 
                  guardarCierreFuccia({
                    ...cierreFuccia,
                    trabaja: e.target.checked,
                    ...(e.target.checked === false ? { fechaTrabaja: "" } :  {}),
                  })
                }
              />
            }
            label="Trabaja"
            labelPlacement="start"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="fechaTrabaja"
            label="Fecha Trabaja"
            type="date"
            fullWidth
            variant="standard"
            disabled={cierreFuccia && (cierreFuccia.trabaja === null || cierreFuccia.trabaja === false)}
            onKeyDown={handleEnter}
            value={cierreFuccia ? cierreFuccia.fechaTrabaja : null}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              guardarCierreFuccia({
                ...cierreFuccia,
                fechaTrabaja: e.target.value,
              });
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ paddingLeft: 0, marginTop: "0.8em" }}
        >
          <FormControlLabel
            value="yes"
            control={
              <Checkbox
                id="citado"
                color="primary"
                checked={cierreFuccia ? cierreFuccia.citado : false}
                onKeyDown={handleEnter}
                onChange={(e) =>
                  guardarCierreFuccia({
                    ...cierreFuccia,
                    citado: e.target.checked,
                    ...(e.target.checked === false ? { fechaCitado: "" } :  {}),
                  })
                }
              />
            }
            label="Citado"
            labelPlacement="start"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="fechaCitado"
            label="Fecha Citado"
            type="date"
            fullWidth
            variant="standard"
            disabled={cierreFuccia && (cierreFuccia.citado === null || cierreFuccia.citado === false)}
            onKeyDown={handleEnter}
            value={cierreFuccia ? cierreFuccia.fechaCitado : null}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              guardarCierreFuccia({
                ...cierreFuccia,
                fechaCitado: e.target.value,
              });
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
